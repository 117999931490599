import axios from "axios";
import { useCallback } from "react";
import { eventsUrl } from "../helpers/apiUrls";
import { useAuthProvider } from "../contexts/AuthProvider";


const useEvent = () => {
	const {token} = useAuthProvider();


	const getAll = useCallback(async ({limit, upComing}) => {
		const result = await axios.get(eventsUrl, {
			params: {
				limit,
				upComing
			}
		});

		return result.data.events;
	},  []);

	const getById = useCallback(async ({id}) => {
		const result = await axios.get(`${eventsUrl}/${id}`);
		return result.data.event;
	}, []);

	const getBannerById = useCallback(async ({id}) => {
		const result = await axios.get(`${eventsUrl}/${id}/banner`,{ 
			responseType: "blob" 
		});

		return result.data;
	}, []);

	const create = useCallback(async ({titel, teaser, beschrijving, start, eind}) => {
		const response = await axios({
			method: "POST",
			url: `${eventsUrl}`,
			headers: {
				Authorization: `Bearer ${token}`
			},
      
			data: {naam: titel, teaser, beschrijving, start, eind}
		}, [ token ]);
  
		return response.data;
	}, [token]);

	const uploadBanner = useCallback(async (eventid, formData) => {
		const result = await axios.post(
			`${eventsUrl}/${eventid}/banner`,
			formData,  
			{
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "multipart/form-data"
				},
			}
		);

		return result;
	}, [token]);

	return {
		getAll,
		getById,
		getBannerById,
		create,
		uploadBanner
	};

};

export default useEvent;