export default function Lied16(){
  return <>
    <div>
      <h5>16. Lush Life</h5>
      <div className="mt-3 mb-4"></div>
      <p><strong>I live my day as if it was the last<br/>
Live my day as if there was no past<br/>
Doin' it all night, all summer<br/>
Doin' it the way I wanna<br/>
Yeah, I'ma dance my heart out 'til the dawn<br/>
But I won't be done when morning comes<br/>
Doin' it all night, all summer<br/>
Gonna spend it like no other</strong><br/><br/>
It was a crush<br/>
But I couldn't, couldn't get enough<br/>
It was a rush<br/>
But I gave it up<br/>
It was a crush<br/>
Now I might have went and said too much<br/>
But that's all it was<br/>
So I gave it up<br/><br/>
<strong>
I live my day as if it was the last<br/>
Live my day as if there was no past<br/>
Doin' it all night, all summer<br/>
Doin' it the way I wanna<br/>
Yeah, I'ma dance my heart out 'til the dawn<br/>
But I won't be done when morning comes<br/>
Doin' it all night, all summer<br/>
Gonna spend it like no other<br/><br/>
</strong>
It was a crush<br/>
I kept saying I'ma stay in touch<br/>
But that thing went bust<br/>
So I gave it up, ooh<br/>
No tricks, no bluff<br/>
I'm just better off without them cuffs<br/>
Yeah, the sun won't set on us<br/>
Ooh-ooh-ooh-ooh, yeah, yeah<br/><br/>
Went low, went high<br/>
Still waters run dry<br/>
Gotta get back in the groove<br/>
I ain't ever worried<br/>
Went low, went high<br/>
What matters is now<br/>
Getting right back in the mood<br/><br/>

<strong>I live my day as if it was the last<br/>
Live my day as if there was no past<br/>
Doin' it all night, all summer<br/>
Doin' it the way I wanna<br/>
Yeah, I'ma dance my heart out 'til the dawn<br/>
But I won't be done when morning comes<br/>
Doin' it all night, all summer<br/>
Gonna spend it like no other<br/><br/></strong>

<strong>I live my day as if it was the last<br/>
Live my day as if there was no past<br/>
Doin' it all night, all summer<br/>
Doin' it the way I wanna<br/>
Yeah, I'ma dance my heart out 'til the dawn<br/>
But I won't be done when morning comes<br/>
Doin' it all night, all summer<br/>
Gonna spend it like no other<br/><br/></strong>

Now I've found another crush<br/>
The lush life giving me a rush<br/>
Had one chance to make me blush<br/>
Second time is one too late<br/>
Now I've found another crush<br/>
The lush life giving me a rush<br/>
Had one chance to make me blush<br/>
Second time is one too late<br/><br/>
Ooh, ooh, ooh, ooh<br/><br/></p>
    </div>
  </>
}