import { useCallback } from "react";
import { useForm } from "react-hook-form";
import useImages from "../../api/images";

const validationRules = {
  nieuweMap: {
    required: "Gelieve map een naam te geven.",
    minLength: { value: 3, message: "Minimum lengte is 3."},
    pattern: {
      value: /^[A-Z a-z0-9_./-]+$/, // Toegestane tekens zijn letters, cijfers, '_', '/', '.', en '-'
      message: "Ongeldige tekens in de map. Gebruik geen '_', '/', '.' of '-' in de naam"
    }
  }
}

export default function FolderForm({takid, save}){

  const {register, handleSubmit, reset, formState: { errors }} = useForm();

  const onSubmit = (data) => {
    const { nieuweMap } = data;
     
    save({
      nieuweMap
    });
    reset();
  }

  return <>
    <div className="container my-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card card-rounded w-100 shadow border-0" >
          <h4>Maak een nieuw mapje!</h4>
          <label htmlFor="nieuweMap">Naam</label>
          <label className="text-sm mb-2"><small>Geef je nieuw mapje een naam zoals 'kamp' of 'Jumanjivergadering'</small></label>
          <div className="input-group mb-3">
            <input
              {...register("nieuweMap", validationRules.nieuweMap)}
              defaultValue=""
              id="nieuweMap"
              type="text"
              className="form-control"
              placeholder="Naam"
            />
            <div className="input-group-append">
              <button class="btn btn-outline-primary rounded-0 rounded-end" type="submit">Voeg toe!</button>
            </div>
          </div>
          {errors.nieuweMap && <p className="form-text text-danger">{errors.nieuweMap.message}</p> }
        </div>
      </form>
    </div>
  </>
}