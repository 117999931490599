import banner from "./images/privacy.jpeg";
export default function Privacy(){

  return <>
    <div className="container mb-5 mt-0">
      <img src={banner} className="info-banner shadow" />
      <h1>Privacybeleid VZW</h1>
      <p>
        VZW Brigands - De Kleine Prins, gevestigd aan Vroonstallestraat 8, 9032 Gent, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
      <h3>
        Contactgegevens:</h3>
      <p>
        Kolegemstraat 203,<br></br>9032 Gent<br></br>+3292366515 Geert Van de Veire is de Functionaris Gegevensbescherming van VZW Brigands - De Kleine Prins. Hij is te bereiken via vzw.scoutswondelgem@gmail.com</p>
      <h3>
        Persoonsgegevens die wij verwerken</h3>
      <p>
        VZW Brigands - De Kleine Prins verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:&nbsp;</p>
      <ul>
        <li>
          Voor- en achternaam</li>
        <li>
          Adresgegevens</li>
        <li>
          Telefoonnummer</li>
        <li>
          E-mailadres</li>
      </ul>
      <h3>
        Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h3>
      <p>
        VZW Brigands - De Kleine Prins verwerkt jouw persoonsgegevens voor de volgende doelen:&nbsp;</p>
      <ul>
        <li>
          Verzenden info rond onze activiteiten</li>
      </ul>
      <h3>
        Delen van persoonsgegevens met derden&nbsp;</h3>
      <p>
        VZW Brigands - De Kleine Prins verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting.</p>
      <h3>
        Gegevens inszien, aanpassen of verwijderen</h3>
      <p>
        Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door VZW Brigands - De Kleine Prins en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar vzw.scoutswondelgem@gmail.com. Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.&nbsp;</p>
    </div>
  </>
}