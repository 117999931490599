const formatPhoneNumber = (phoneNumber) => {
	// Verwijder eventuele niet-numerieke tekens uit de telefoonnummerreeks
	const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

	// Controleer of het numerieke telefoonnummer de juiste lengte heeft (10 cijfers)
	if (numericPhoneNumber.length === 10) {
		// Neem de verschillende delen van het telefoonnummer
		const prefix = numericPhoneNumber.substring(0, 4);
		const part1 = numericPhoneNumber.substring(4, 6);
		const part2 = numericPhoneNumber.substring(6, 8);
		const part3 = numericPhoneNumber.substring(8, 10);

		// Vorm het telefoonnummer om naar de gewenste notatie
		const formattedPhoneNumber = `${prefix}/${part1}.${part2}.${part3}`;
		return formattedPhoneNumber;
	}

	// Retourneer de oorspronkelijke tekenreeks als het telefoonnummer niet de juiste lengte heeft
	return null;
};

module.exports = {
	formatPhoneNumber
};