import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import useImages from '../../api/images';
import ImagePreview from './ImagePreview';
import { BsDownload, BsX } from 'react-icons/bs';

const Lightbox = ({ takid, mapid, images }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const imageAPI = useImages();

  const openLightbox = (image) => {
     
    setSelectedImage(image);
    setShowModal(true);
  };

  const closeLightbox = () => {
    setShowModal(false);
  };

  const downloadImage = async () => {
    const result = await imageAPI.getImage({ takid, mapid, fileName: selectedImage.name });
    const fileBlob = new Blob([result], { type: "image/png" });
    const url = URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = selectedImage.name;
    link.click();
  };

  return (
    <div className="image-grid">
      {images.map((image, index) => { 
        console.log(image)
        return <ImagePreview image={image} index={index} handleSelecteerImage={openLightbox}/>
      })}

      <Modal show={showModal} onHide={closeLightbox} centered>
        <Modal.Body>
          <img
            src={selectedImage.url}
            alt="Lightbox"
            className="lightbox-image"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="download-button" onClick={downloadImage}>
            <BsDownload /> Download
          </button>
          <button className="close-button" onClick={closeLightbox}>
            <BsX></BsX> Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Lightbox;