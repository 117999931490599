export default function Lied1(){
  return <>
    <div>
      <h5>6. Hoeken van de kamer - Jan Smit</h5>
      <div className="mt-3 mb-4"></div>
      <p>Ik kan me niet meer beheersen nu ik zo naar je kijk<br/>
En zit te bedenken dat ik dronken ben of lijk<br/>
'K zit in een andere wereld<br/>
Ik zie er echt niet meer uit en ik aanshouw hoe jij danst met iedereen<br/>
Met m'n vrienden gaat het niet beter ik wil gaan het liefst meteen<br/>
Kleuren in je haar, maar ik ga niet naar huis zonder jou<br/><br/>
<strong>Oh, wat zal 'k is aan je laten zien<br/>
De hoeken van de kamer<br/>
Waar jij me laat zweven<br/>
Zal je alles geven<br/>
En lijkt het je in bed misschien<br/>
Toch ietsje aangenamer<br/>
Vannacht nog of morgenvroeg<br/>
Van jou krijg ik nooit genoeg</strong><br/><br/>
We slenteren over straat ik lach om hoe je vertelt wat ie zei<br/>
Ik trek je tegen me aan en voel me trots jij bent van mij<br/>
Schat heb je misschien de sleutel<br/>
Gooi je kleren op de grond net als ik<br/><br/>
<strong>Oh, wat zal 'k is aan je laten zien<br/>
De hoeken van de kamer<br/>
Waar jij me laat zweven<br/>
Zal je alles geven<br/>
En lijkt het je in bed misschien<br/>
Toch ietsje aangenamer<br/>
Vannacht nog of morgenvroeg<br/>
Van jou krijg ik nooit genoeg<br/><br/></strong>
Niemand die zo mooi is als jij<br/>
Niemand die me beter begrijpt<br/>
Niemand die zo graag een bierglas achterover slaat<br/>
Maar jij bent daartoe in staat<br/>
Dus<br/><br/>
<strong>
Oh, wat zal 'k is aan je laten zien<br/>
De hoeken van de kamer<br/>
Waar jij me laat zweven<br/>
Zal je alles geven<br/>
En lijkt het je in bed misschien<br/>
Toch ietsje aangenamer<br/>
Vannacht nog of morgenvroeg<br/>
Bij jou thuis of in de kroeg<br/>
Van jou krijg ik nooit genoeg</strong></p>
    </div>
  </>
}