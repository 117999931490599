import { useCallback, useEffect, useState } from "react";
import useVerhuur from "../../api/verhuur"
import { formatDate } from "../../helpers/formatDate";
import { BsDashCircle, BsPlusCircle, BsTrash } from "react-icons/bs";
import PeriodeForm from "./PeriodeForm";

function PeriodeRij({
  id, van, tot, status, beschikbaarheden,
  handleDelete
}){  
  const verhuurAPI = useVerhuur();

  const verwijderPeriode = useCallback(async ()=> {
    handleDelete(id)
  }, [])

  const handleChangeBeschikbaarheid = useCallback(async (event) => {
    try{
      await verhuurAPI.updatePeriode(id, {
        status: event.target.value
      })
    }catch(error){
      // TODO error handling
       
    }
  },[id, verhuurAPI])

  return <>
    <tr key={`${id}`}>
      <td className="d-none d-xl-table-cell">
        {formatDate(van)}
      </td>
      <td className="d-none d-xl-table-cell">
        {formatDate(tot)}
      </td>
      <td className="d-table-cell d-xl-none text-center">
        {formatDate(van).substring(0,5)}<br/>-<br/>{formatDate(tot).substring(0,5)}
      </td>
      <td className="align-middle">
        <select className="form-select" onChange={handleChangeBeschikbaarheid}>
          {beschikbaarheden.map(b => {
            return <option key={`${id}_${b}`} value={b} selected={status===b}>{b}</option>
          })}
        </select>
      </td>
      <td className="align-middle">
        <BsTrash color="red" className="clickable" onClick={verwijderPeriode}></BsTrash>
      </td>
    </tr>
  </>
}

export default function PeriodeLijst(){

  const verhuurAPI = useVerhuur();
  const [periodes, setPeriodes] = useState([])
  const [beschikbaarheden, setBeschikbaarheden] = useState([]);
  const [visible, setVisible] = useState(false);
  
  const fetchPeriodes = useCallback(async () => {
    const result = await verhuurAPI.getPeriodes();
    setPeriodes(result.periodes)
  }, [])

  const verwijderPeriode = useCallback(async (id) => {
    await verhuurAPI.verwijderPeriode(id);
    fetchPeriodes();
  }, [])

  useEffect(()=> {
    fetchPeriodes()
  }, [])

  useEffect(()=> {
    const fetchBeschikbaarheden = async () => {
      const result = await verhuurAPI.getBeschikbaarheden();
      setBeschikbaarheden(result)
    }
    fetchBeschikbaarheden()
  },[])

  const addPeriode = useCallback(async ({start, eind}) => {
    await verhuurAPI.createPeriode({start, eind});
    fetchPeriodes()
  }, [])

  return <>
    <h6>Verhuurperiodes {!visible 
      ? <BsPlusCircle className="clickable" onClick={() => {setVisible(true)}} color='green'/>
      : <BsDashCircle color="red" className="clickable" onClick={() => setVisible(false)} />}
    </h6>
    {visible && 
    <PeriodeForm save={addPeriode} />}
    <table className="table table-striped">
      <thead className="thead-light">
        <tr>
          <th className="d-none d-xl-table-cell">Van</th>
          <th className="d-none d-xl-table-cell">Tot</th>
          <th className="d-table-cell d-xl-none">Periode</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {periodes.map(periode => {
          return <PeriodeRij 
            id={periode.id}
            van={periode.start}
            tot={periode.eind}
            status={periode.status}
            beschikbaarheden={beschikbaarheden}
            handleDelete={verwijderPeriode} />
        })}
      </tbody>
    </table>
  </>
}