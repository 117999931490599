import { useCallback, useEffect, useState } from "react"

import UserPreview from "./UserPreview";
import Loader from "../loader/Loader";
import axios from "axios";
import Error from "../error/Error";
import useUser from "../../api/user";
import { Link } from "react-router-dom";
import { usersUrl } from "../../helpers/apiUrls";
import UserTable from "./UserTable";
import Limit from "../filtering/limit";
import Search from "../filtering/search";
import Page from "../filtering/page";

export default function UserList(){

  const [ users, setUsers ] = useState();
  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState();
  const [pages, setPages ] = useState();
  const [pageNumber, setPageNumber]   = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const userAPI = useUser();

  const fetchUsers = async () => {
    try{
      const result = await userAPI.getAll({
        pageNumber, 
        limit,
        searchTerm
      });
      setUsers(result.users)
      setPages(Math.ceil(result.aantal/limit))
      setLoading(false)
    }catch(error){
      setError(error)
    }
  }

  const handleChangeLimit = useCallback(async (newLimit) => {
    setLimit(newLimit)
    setPageNumber(1)
  },[])

  const handleChangePage = useCallback(async (newPage) => {
    setPageNumber(newPage)
  }, [])

  const verwijderGebruiker = useCallback(async ({id}) => {
    try{
      await userAPI.deleteUser({id});
      await fetchUsers()
    }catch(error){

    }
  }, [users])

  const handleSearch = useCallback((term) => {
    setSearchTerm(term)
  })

  useEffect(()=> {
    fetchUsers()
  }, [limit, pageNumber, searchTerm])

  return <>
    <div className="container pt-2 pb-5">
      <h2>Gebruikers</h2>
      {error ? <Error error={error} /> : <>
        {loading ? <Loader></Loader>
        : <>
          <div className="row align-items-end">
            <div className="col-12 col-md-6">
              <Search search={handleSearch} placeholder="Naam of e-mail"></Search>
            </div>
            <div className="col-4 col-md-2 pt-3 pt-md-0">
              <Limit onChangeLimit={handleChangeLimit}></Limit>
            </div>
            <div className="col-4 col-md-2 pt-3 pt-md-0">
              <Page amount={pages} onChangePage={handleChangePage}></Page>
            </div>
            <div className="col-4 col-md-2 pt-3 pt-md-0">
              <button className="btn btn-primary w-100">
                <Link to="add" className="no-link" >Voeg toe</Link>
              </button>
            </div>
          </div>
          <hr/>
          <UserTable 
            users={users}
            verwijderGebruiker={verwijderGebruiker}
          />
            { /*users.map(user => {
              return <>
              <div key={user.id} className="col-12 col-sm-6 col-lg-3 p-2 fade-in" >
                <UserPreview
                  id={user.id}
                  voornaam={user.voornaam}
                  familienaam={user.familienaam}
                  email={user.email} phone={user.gsmnummer} 
                  editable={true}
                  verified={user.verified}
                  verwijderGebruiker={verwijderGebruiker}
              />
              </div>
              </>
            })*/}
        </>}
      </>}
    </div>
  </>
}