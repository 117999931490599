import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import UploadComponentSingle from "../upload/FileUploadSingle";
import UploadComponentMultiple from "../upload/FileUploadMultiple";
import useTak from "../../api/tak";

const validationRules = {
  naam: {
    required: "Gelieve de tak een naam te geven.",
    minLength: { value: 3, message: "Minimum lengte is 3"}
  },
  email:{
    required: "Gelieve een email op te geven.",
  },
  beschrijving: {
    
  },
  rekeningnummer: {
    validate: 
    (value) => {
      if(!validateBelgianIBAN(formatBelgianAccountNumber(value))){
        return "Gelieve een geldig rekeningnummer op te geven."
      }
      return null
    }
  },
  startLeeftijd: {
      min: {value: 0, message: "Leeftijd moet groter zijn dan 0."}
  },
  eindLeeftijd: {
    validate: (value, allValues) => {
      const startLeeftijd = allValues.startLeeftijd;
      if (value < startLeeftijd) {
        return "Eindleeftijd moet groter dan of gelijk aan de startleeftijd zijn.";
      }
      return null;
    }
  }
}

function formatBelgianAccountNumber(accountNumber) {
  // Verwijder eventuele spaties, streepjes, punten en "BE" uit het rekeningnummer
  const cleanedAccountNumber = accountNumber.replace(/\D/g, '');
   

  // Controleer of het rekeningnummer geldig is
  const regex = /^[0-9]{14}$/;
  if (!regex.test(cleanedAccountNumber)) {
    return "Ongeldig"
  }

  // Voeg het landcode en het formaat toe aan het rekeningnummer
  const formattedAccountNumber = cleanedAccountNumber.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, "BE$1 $2 $3 $4");

  return formattedAccountNumber;
}

function validateBelgianIBAN(iban) {
  // Verwijder spaties uit het IBAN-nummer
  const cleanedIBAN = iban.replace(/\s/g, '');

  // Controleer de lengte van het IBAN-nummer
  if (cleanedIBAN.length !== 16) {
    return false;
  }

  // Verplaats de eerste 4 tekens naar het einde van het IBAN-nummer
  const reorderedIBAN = cleanedIBAN.slice(4) + cleanedIBAN.slice(0, 4);

  // Converteer letters naar cijfers
  const numericIBAN = reorderedIBAN.replace(/[A-Z]/g, (match) => {
    return (match.charCodeAt(0) - 55).toString();
  });

  // Bereken de modulo 97 van het numerieke IBAN-nummer
  let modulo = 0;
  for (let i = 0; i < numericIBAN.length; i++) {
    const digit = parseInt(numericIBAN.charAt(i), 10);
    modulo = (modulo * 10 + digit) % 97;
  }

  // Controleer of de modulo 97 gelijk is aan 1
  return modulo === 1;
}

export default function TakkenForm({onSaveTak}) {
  const [ selectedFile, setSelectedFile] = useState();
  const [ loadedLogo, setLoadedLogo] = useState(false);
  const [ carouselFiles, setCarouselFiles ] = useState();
  const [ loadedCarousel, setLoadedCarousel] = useState(false);

  const takkenAPI = useTak();


  const {register, handleSubmit, reset, formState: { errors }} = useForm();

  const chooseFile = useCallback((file) => {
    setSelectedFile(file)
    setLoadedLogo(true);
  }, []);

  const chooseCarouselFiles = (files) => {
     
    setCarouselFiles(files);
    setLoadedCarousel(true);
  }

  const save = useCallback(async ({naam, beschrijving, email, rekeningnummer, startLeeftijd, eindLeeftijd,}) => {
    try{ 
      const response = await takkenAPI.create({naam, beschrijving, email, rekeningnummer, startLeeftijd, eindLeeftijd,})
      
       
      if (selectedFile) {
        let formData = new FormData();
        formData.append('image', selectedFile);
        await takkenAPI.uploadTakLogo(response.naam, formData)
      }

      if(carouselFiles){
        let formData = new FormData();
        carouselFiles.forEach((file, index) => { 
          formData.append(`${index}`, file);
        }); 
        await  takkenAPI.uploadTakCarousel(response.naam, formData)
      }
    }catch(error){
       console.log(error)
    }
  }, [selectedFile, carouselFiles, takkenAPI])

  const onSubmit = (data) => {
    const { naam, email, beschrijving, rekeningnummer, startLeeftijd, eindLeeftijd} = data;
     
    save({
      naam,
      email,
      beschrijving,
      rekeningnummer: formatBelgianAccountNumber(rekeningnummer),
      startLeeftijd,
      eindLeeftijd
    });
    reset();
  }
  return <>
    <div className="container my-4">
      <h2>Maak een nieuwe tak!</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-4">
          <div className="col-12 col-md-6 p-2 h-100">
            <h5>Info</h5>
            <div className="card card-rounded h-100 shadow border-0" >
              <div className="form-group mb-3">
                <label htmlFor="naam">Naam</label>
                <input
                  {...register("naam", validationRules.naam)}
                  defaultValue=""
                  id="naam"
                  type="text"
                  className="form-control"
                  placeholder="Naam"
                />
                {errors.naam && <p className="form-text text-danger mb-0">{errors.naam.message}</p> }
              </div>
              <div className="w-100 mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  {...register("email", validationRules.email)}
                  defaultValue=""
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
                {errors.email && <p className="form-text text-danger mb-0">{errors.email.message}</p> }
              </div> 
              <div className="w-100 mb-3">
                <label htmlFor="beschrijving" className="form-label">Beschrijving</label>
                <input
                  {...register("beschrijving", validationRules.beschrijving)}
                  defaultValue=""
                  id="beschrijving"
                  type="text"
                  className="form-control"
                  placeholder="Beschrijving"
                />
                {errors.beschrijving && <p className="form-text text-danger mb-0">{errors.beschrijving.message}</p> }
              </div>
              <div className="w-100 mb-3">
                <label htmlFor="rekeningnummer" className="form-label">Rekeningnummer</label>
                <input
                  {...register("rekeningnummer", validationRules.rekeningnummer)}
                  defaultValue=""
                  id="rekeningnummer"
                  type="text"
                  className="form-control"
                  placeholder="Rekeningnummer"
                />
                {errors.rekeningnummer && <p className="form-text text-danger mb-0">{errors.rekeningnummer.message}</p> }
              </div>
              <div class="row">
                <div class="col">
                  <div className="mb-3">
                    <label htmlFor="startLeeftijd" className="form-label">Leeftijd van</label>
                    <input
                      {...register("startLeeftijd", validationRules.startLeeftijd)}
                      defaultValue=""
                      id="startLeeftijd"
                      type="number"
                      className="form-control"
                      placeholder="Van"
                      
                    />
                    {errors.startLeeftijd && <p className="form-text text-danger mb-0">{errors.startLeeftijd.message}</p>}
                  </div>
                </div>
                <div class="col">
                  <div className="mb-3">
                    <label htmlFor="eindLeeftijd" className="form-label">tot</label>
                    <input
                      {...register("eindLeeftijd", validationRules.eindLeeftijd)}
                      defaultValue=""
                      id="eindLeeftijd"
                      type="number"
                      className="form-control"
                      placeholder="Tot"
                      
                    />
                    {errors.eindLeeftijd && <p className="form-text text-danger mb-0">{errors.eindLeeftijd}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
              <h5>Tak logo</h5>
              <UploadComponentSingle onSaveAfbeelding={chooseFile} loadedLogo={loadedLogo}></UploadComponentSingle>

              <h5 className="mt-4">Carousel foto's</h5>
              <UploadComponentMultiple onSaveAfbeelding={chooseCarouselFiles} loadedLogo={loadedCarousel} limit={3}></UploadComponentMultiple>
            </div>
          </div>
        <div className="clearfix">
              <button
                type="submit"
                className="btn btn-primary w-100"
              >
                Voeg toe!
              </button>
            </div>
      </form>
    </div>
  </>
}