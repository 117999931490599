import { useEffect, useState } from "react"
import "./Event.css"
import useEvent from "../../api/event";
import { useParams } from "react-router-dom";
import Loader from "../loader/Loader";
import { format } from 'date-fns';

export default function EventDetail(){
  
  const [ event, setEvent ] = useState();
  const [ loading, setLoading ] = useState(true);
  const [ banner, setBanner ] = useState();

  const {id} = useParams();

  const eventAPI = useEvent()
  
  useEffect(()=> {
    const fetchEvent = async () => {
      const result = await eventAPI.getById({id})
       
      setEvent(result)
      setLoading(false)
    }

    fetchEvent();
  }, [loading])

  useEffect(()=> {
    const fetchBanner = async () => {
      const result = await eventAPI.getBannerById({id})
       
      const fileBlob = new Blob([result], {type: "image/*"});
      let url = URL.createObjectURL(fileBlob);
      setBanner(url)
    }
    fetchBanner()
  }, [])

  const addToCalendar = () => {
    const calendarLink = `webcal://add?eventname=${event.title}&location=Vroonstallestraat%208%209032%20Wondelgem&details=${event.teaser}&dates=${new Date(event.start).toISOString()}/${new Date(event.eind).toISOString()}`;
    window.location.href = calendarLink;
  };
  
  return <>
  {loading ? <Loader></Loader> : 
    <div className="event-container container mt-0">
      <div 
        className="event-banner"
        style={{
          "background-image": `url(${banner})`,
          "background-size": "100px",
          "background-repeat": "repeat"
        }}>
      </div>
      <div className="event-content card shadow border-0 h-100">
        <h1>{event.naam}</h1>
        <h6>Van: {new Date(event.start).toLocaleString('nl-BE', { timeZone: 'GMT',day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: undefined })}</h6>
        <h6>Tot: {new Date(event.eind).toLocaleString('nl-BE', { timeZone: 'GMT',day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: undefined })}</h6>
        <p className="mb-2">
          {/* Aanvullende inhoud */}
        </p>
        <div>
          <div dangerouslySetInnerHTML={{ __html: event.beschrijving }}></div>
          {/*<btn className="btn btn-yellow text-white my-2" onClick={addToCalendar}>Voeg toe aan mijn agenda</btn>*/}
        </div>
      </div>
      <div className="event-banner-date">
        <div className="cal-item-date center">
          <h5 className="text-center">{new Date(event.start).toLocaleDateString('en-US', { timeZone: 'Europe/Brussels', month: 'short' }).toUpperCase()}</h5>
          <h4 className="text-center">{new Date(event.start).toLocaleString('en-US', { timeZone: 'Europe/Brussels', day: 'numeric' })}</h4>
        </div>
      </div>
    </div>
  }
  </>
}