import { useCallback } from "react"

export default function Page({
  amount,
  onChangePage
}){

  const handleChangePage = useCallback((event) => {
    const selectedValue = event.target.value;
    onChangePage(selectedValue)
  })

  // Maak een array met de juiste lengte
  const pages = Array.from({ length: amount }, (_, i) => i + 1);

  return <>
    <label className="ms-2 mb-1">Pagina</label>
    <select className="form-select" onChange={handleChangePage}>
      {pages.map(page => {
        return <option value={page}>{page}</option>
      })}
    </select>
  </>
}