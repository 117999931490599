// Logout.js (bijvoorbeeld)
import { useCallback, useEffect } from 'react';
import { useAuthProvider } from '../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { BsBoxArrowRight } from 'react-icons/bs';

const Logout = () => {

  const {setCurrentUser, setToken, currentUser } = useAuthProvider()
  const navigate = useNavigate();

  const handleClick = useCallback(async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setCurrentUser(undefined)
    setToken(undefined)
  }, [setToken, setCurrentUser])

  useEffect(() => {
    if(!currentUser){
      navigate("/", {replace: true})
    }
  })

 return <>
  <button className="btn btn-sm btn-outline-danger mb-4 btn-pill d-flex align-items-center justify-content-center w-100" onClick={handleClick}>
    <BsBoxArrowRight className="icon me-2" /> Uitloggen
  </button>
 </>
};

export default Logout;