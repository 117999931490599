import { useNavigate } from "react-router-dom";
import { useAuthProvider } from "../../contexts/AuthProvider";
import ForbiddenComponent from "../Errors/Forbidden";

export default function RequireAuth({ children, roles }) {
  const { currentUser } = useAuthProvider();
  const navigate = useNavigate();

  if (!currentUser) {
    navigate("/auth", { replace: true });
    return null; // Return null when currentUser is not available
  }

  if (!roles || !roles.length) {
    return children; // No specific roles required, so allow access
  }

  if (!currentUser.roles.some((userRole) => roles.includes(userRole))) {
    return <ForbiddenComponent />;
  } else {
    return children;
  }
}