import { FaArrowRight } from 'react-icons/fa';
import './TakPreview.css';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../helpers/capitalize';
import { useEffect, useState } from 'react';
import { takkenUrl } from '../../helpers/apiUrls';
import axios from 'axios';

const TakPreview = ({tak}) => {

  const [ image, setImage ] = useState("");
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const fetchFiles = async () => {
      if(loading){
        try {
          const response = await axios.get(`${takkenUrl}/${tak.naam}/images`, { responseType: 'blob' });
          const fileBlob = new Blob([response.data], { type: 'application/octet-stream' });
          const url = URL.createObjectURL(fileBlob);
          setImage(url);
          setLoading(false)
        } catch (error) {
          console.error(error);
        }
      }
    };
  
    fetchFiles();
  }, [loading]);
  return (<>{!loading &&
    <div key={tak.id} className="col-md-6 mb-4 col-lg-3">
      <div className=''>
        <Link to={`/takken/${tak.naam}`} className="text-decoration-none">
          <div className="card h-100 border-0 shadow">
            <img src={image} className="group-logo align-self-center" alt={tak.naam} />
            <div className="card-body text-center">
              <h5 className="group-title">{capitalizeFirstLetter(tak.naam)}</h5>
            </div>
          </div>
        </Link>
      </div>
    </div>
  }</>);
};


export default TakPreview;