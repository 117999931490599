import { useCallback, useEffect, useState } from "react";
import useGazette from "../../api/gazette";
import { BsXCircleFill } from "react-icons/bs";

export default function GazettePreview({id, height, deletable, deleteGazette}){

  const gazetteAPI = useGazette();

  const [ image, setImage ] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPreview = async () => {
      const result = await gazetteAPI.getPreviewById(id);
      const fileBlob = new Blob([result], { type: "image/*" });
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setLoading(false)
      };
      reader.readAsDataURL(fileBlob);
    };
  
    getPreview();
  }, [id]);

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      const result = await gazetteAPI.getById(id);
      const fileBlob = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileBlob);
      console.log(fileURL)

      const a = document.createElement("a");
      a.href = fileURL;
      a.download = `gazette_${id}.pdf`;
      a.click();
    } finally {
      setLoading(false);
    }
  }, [gazetteAPI, id]);

  const handleDelete = useCallback(async () => {
    deleteGazette(id)
  })

  return (
    <>
    {!loading && <>
    <div className="gazette-card fade-in">
      {deletable && <BsXCircleFill color="red" size={20} className="gazette-delete clickable" onClick={handleDelete}/>}
      <div className="text-center border-0 card shadow-sm px-0 overlay-container m-2">
          {image && (
            <>
              <img
                alt={`Gazette ${id}`}
                src={image}
                className="voorpagina clickable rounded-top"
                onClick={handleClick}
                style={{height: `${height}px`}}
              />
              {loading && <div className="gazette-loader" ></div>} {/* Gebruik de loader hier */}
            </>
          )}
          <h5># {id}</h5>
        </div>
    </div>
    </>}
    </>
  );
}