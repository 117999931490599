export default function Lied1({songNr}){
  return <>
    <div>
    <h5>1. Porselein - Yasmine</h5>

      <div className="mt-3 mb-4"></div>
      <p>In het diepste van mijn ziel<br/>
        Ben ik eenzaam en fragiel<br/>
        Maar ik staar de wereld aan<br/>
        Als een winnaar<br/>
        En het leven lacht me toe<br/>
        Bij om het even wat ik doe<br/>
        Elke keer zal ik er staan<br/>
        Als een winnaar<br/>
        Maar soms voel ik me zo klein<br/>
        Wil ik iemand anders zijn<br/>
        Diep van binnen kwijn ik weg van de pijn<br/><br/>
        <strong>Porselein<br/>
        Behandel mij heel zacht<br/>
        Porselein<br/>
        Verbrijzeld door je lach<br/>
        Porselein<br/>
        Poreus en onderhuids<br/>
        Als een winnaar<br/>
        Haal jij me onderuit<br/>
        </strong><br/>
        Mijn gedachten slaan op hol<br/>
        Ik verval weer in mijn rol<br/>
        En zal mijn eigen gang weer gaan<br/>
        Als een winnaar<br/>
        Ik kan vechten als een vrouw<br/>
        Zoals ik strijden zal om jou<br/>
        En ruim de vijand van de baan<br/>
        Als een winnaar<br/>
        Maar vanbinnen ben ik broos<br/>
        Heel geremd en hulpeloos<br/>
        In mijn hart ben ik vernederd en boos<br/><br/>
        <strong>Porselein<br/>
        Behandel mij heel zacht<br/>
        Porselein<br/>
        Verbrijzeld door je lach<br/>
        Porselein<br/>
        Poreus en onderhuids<br/>
        Als een winnaar<br/>
        Haal jij me onderuit<br/><br/>
        </strong>
        Ik weet het wel<br/>
        Niemand die me vragen stelt<br/>
        En niemand die mijn naam vergeet oooh<br/>
        Ik weet het wel<br/>
        Niemand die me wakker belt<br/>
        En nooit eens iemand die mijn dromen leest<br/><br/>
        Niemand die me stoort<br/>
        Niemand die me hoort<br/>
        Niemand die me kust als ik wakker word<br/>
        Niemand die me mist<br/>
        Niemand die me haat<br/>
        Niemand die de deur voor me openlaat<br/>
        Niemand die me kent<br/>
        Niemand die me streelt<br/>
        Niemand die me ooit eens een moment verveelt<br/>
        Niemand voor mezelf<br/>
        Niemand om te zijn<br/>
        Wie is net als ik van porselein<br/><br/>
        Porselein<br/>
        Niemand die me stoort<br/>
        Niemand die me hoort<br/>
        Niemand die me kust als ik wakker word<br/>
        Porselein<br/>
        Niemand die me mist<br/>
        Niemand die me haat<br/>
        Niemand die de deur voor me openlaat<br/>
        Porselein<br/>
        Niemand die me kent<br/>
        Niemand die me streelt<br/>
        Niemand die me ooit eens een moment verveelt<br/>
        Porselein<br/>
        Niemand voor mezelf<br/>
        Niemand om te zijn<br/>
        Wie is net als ik van <br/><br/><strong>Porselein<br/>
        Behandel mij heel zacht<br/>
        Porselein<br/>
        Verbrijzeld door je lach<br/>
        Porselein<br/>
        Poreus en onderhuids<br/>
        Als een winnaar<br/>
        Haal jij me onderuit<br/>
        </strong></p>
    </div>
  </>
}