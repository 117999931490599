export default function Lied13(){
  return <>
    <div>
      <h5>13. 't Smidje - Laïs</h5>
      <div className="mt-3 mb-4"></div>
      <p>Wie wil horen een historie<br/>
Al van ene jonge smid<br/>
Die verbrand had zijn memorie<br/>
Daaglijks bij het vuur verhit<br/><br/>
<strong>
Was ik nog, nog met mijnen hamer<br/>
Was ik nog met geweld op mijn aambeld<br/><br/>
</strong>
'k Geef den bras van al dat smeden<br/>
Ik ga naar de Franse zwier<br/>
'k Wil mij tot den trouw begeven<br/>
Nooit een schoner vrouw gezien<br/><br/>
<strong>
Was ik nog, nog met mijnen hamer<br/>
Was ik nog met geweld op mijn aambeld<br/><br/></strong>
't Is de schoonste van de vrouwen<br/>
Maar nooit was er zo'n serpent<br/>
Nooit kan zij haar bakkes houden<br/>
Nooit is zij eens wel content<br/><br/>
<strong>
Was ik nog, nog met mijnen hamer<br/>
Was ik nog met geweld op mijn aambeld<br/><br/></strong>
Nooit mag ik een pintje drinken<br/>
Nooit mag ik eens vrolijk zijn<br/>
Nooit kan ik iemand beschinken<br/>
Met een glaasje bier of wijn<br/><br/>
<strong>
Was ik nog, nog met mijnen hamer<br/>
Was ik nog met geweld op mijn aambeld</strong>
<br/><br/>
'k Geef den bras van al dat trouwen<br/>
Werd ik maar eens weduwnaar<br/>
'k Zou mij in een hoeksken houden<br/>
En mij stellen uit gevaar<br/><br/>
<strong>
Was ik nog, nog met mijnen hamer<br/>
Was ik nog met geweld op mijn aambeld<br/>
Was ik nog, nog met mijnen hamer<br/>
Was ik nog met geweld op mijn aambeld</strong></p>
    </div>
  </>
}