import axios from "axios";
import { useCallback } from "react";
import { useAuthProvider } from "../contexts/AuthProvider";
import { logsUrl } from "../helpers/apiUrls";


const useLogs = () => {

	const {token} = useAuthProvider();

	const getAll = useCallback(async ({ tag, limit, pageNumber }) => {
		try {
			const response = await axios.get(logsUrl, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					tag,
					limit,
					pageNumber
				},
			});
            
			return response.data;
		} catch (error) {
			console.error(error);
			throw error; // Om foutafhandeling mogelijk te maken
		}
	}, [token]);

	const getAllTags = useCallback(async () => {
		const response = await axios.get(`${logsUrl}/tags`,{
			headers: {
				Authorization: `Bearer ${token}`,
			}
		});
		return response.data.tags;
	}, [token]);

	const createLog = useCallback(async ({tag}) => {
		await axios({
			url: logsUrl,
			method: "POST",
			data: {
				tag
			}
		});
	},[]);

	const getViewsPerMonth = useCallback(async () => {
		await axios({
			method: "GET",
			url: `${logsUrl}/views`
		});
	}, []);
  

	return {
		getAll,
		getAllTags,
		createLog,
		getViewsPerMonth
	};
};

export default useLogs;
