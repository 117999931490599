import axios from "axios";
import { useCallback } from "react";
import { useAuthProvider } from "../contexts/AuthProvider";
import { takkenUrl } from "../helpers/apiUrls";


const useTak = () => {

	const {token} = useAuthProvider();

	const getAll = useCallback(async () => {
    
		const {
			data,
		} = await axios.get(takkenUrl);
		return data.takken;
	}, []);

	const getById = useCallback(async (id) => {
		const response = await axios.get(`${takkenUrl}/${id}`);
		return response.data;
	}, []);

	const create = useCallback(async ({
		naam,
		beschrijving,
		email,
		kleur,
		rekeningnummer,
		startLeeftijd,
		eindLeeftijd
	}) => {
		const response = await axios.post(`${takkenUrl}`, {
			data: {
				naam,
				beschrijving,
				email,
				kleur,
				rekeningnummer,
				startLeeftijd,
				eindLeeftijd
			}
		});
		return response.data;
	}, []);

	const uploadTakLogo = useCallback(async (naam, formData) => {

		return await axios.post(
			`${takkenUrl}/${naam}/images`,
			formData, {
				headers: { 
					"Content-Type": "multipart/form-data" 
				},
			});
	}, []);

	const uploadTakCarousel = useCallback(async (naam, formData) => {
		return await axios.post(
			`${process.env.REACT_APP_API_URL}/takken/${naam}/images/carousel`,
			formData, {
				headers: { 
					"Content-Type": "multipart/form-data" 
				},
			});
	}, []);

	const uploadMaandbrief = useCallback(async ({taknaam, formData}) => {
		const result = await axios.post(
			`${takkenUrl}/${taknaam}/maandbrieven`,
			formData, {
				headers: { 
					Authorization: `Bearer ${token}`,
					"Content-Type": "multipart/form-data" 
				},
			});
		return result;
	}, [token]);

	const deleteMaandbrief = useCallback(async (taknaam, bestandsnaam) => {
		const result = await axios({
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			url: `${takkenUrl}/${taknaam}/maandbrieven/${bestandsnaam}`
		});
		return result;
	}, [token]);

	const getMaandbrieven  = useCallback(async ({naam}) => {
		const result = await axios({
			method: "GET",
			url: `${takkenUrl}/${naam}/maandbrieven`
		});
		return result.data
	}, [])

	return {
		getAll,
		getById,
		create,
		uploadTakLogo,
		uploadTakCarousel,
		deleteMaandbrief,
		uploadMaandbrief,
		getMaandbrieven
	};
};

export default useTak;
