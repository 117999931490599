import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import Loader from '../loader/Loader';
import "./Upload.css"

const UploadComponentSingle = ({onSaveAfbeelding, loadedLogo}) => {

  const [ selected, setSelected ] = useState(false);
  const [paths, setPaths] = useState([]);

  const handleDrop = useCallback((acceptedFiles) => {
     
    setPaths(acceptedFiles.map(file => URL.createObjectURL(file)));
    onSaveAfbeelding(acceptedFiles[0]);
    setSelected(true)
  }, [setPaths]);

  return (
    <div className='card card-rounded min-height-250 shadow border border-0 w-100'>
      <div className='row'>
        <div className={`${selected ? "col-12 col-lg-6" : "col-12"}`}>
          <div className='drop-zone-container text-center h-100 d-flex flex-column align-items-center justify-content-center'>
            <div className='drop-zone-image mb-2'></div>
            <Dropzone onDrop={handleDrop} 
              accept={{
              'image/*': ['.jpeg', '.jpg', '.png'],
              }} 
              multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <div className='' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drop jouw afbeelding hier of <span className='text-primary clickable'>zoek</span></p>
                        <p className='text-muted'><small>Ondersteunt .png .jpeg .jpg</small></p>
                      </div>
                    )}
            </Dropzone>
          </div>
        </div>
        <div className='col-12 col-lg-6 pt-2'>
          <div className='w-100 d-flex align-items-center justify-content-center'>
            {paths.map(path => {
              return <>
                <img 
                  key={path} 
                  src={path}
                  className="upload-1-preview" 
                  alt="..." />
              </>
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadComponentSingle;
