
import './Dashboard.css'


export default function Dashboard({data}){

  return <>
    <div className='d-flex justify-content-between px-2'>
      <div className='text-center'>
        <p className='dashboard-number mb-0'>{data?.totalViews > 1000 ? `${(data.totalViews / 1000).toFixed(2)}K` :  data?.totalViews}</p>
        <p className='dashboard-text'><small>BEZOEKERS</small></p>
      </div>
      <div className='vertical-line'></div>
      <div className='text-center'>
        <p className='dashboard-number mb-0'>{data?.uploadedPhotosThisMonth}</p>
        <p className='dashboard-text'><small>FOTO'S</small></p>
      </div>
      <div className='vertical-line'></div>
      <div className='text-center'>
        <p className='dashboard-number mb-0'>{data?.aanvragenVerhuurThisMonth}</p>
        <p className='dashboard-text'><small>AANVRAGEN</small></p>
      </div>
    </div>
  </>
}