import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { Carousel } from "react-bootstrap";
import image1 from './images/verhuur_1.jpg'
import image2 from './images/verhuur_2.jpg'
import image3 from './images/verhuur_3.jpg'
import image4 from './images/verhuur_4.jpg'
import image5 from './images/verhuur_5.jpg'
import image6 from './images/verhuur_6.jpg'
import image7 from './images/verhuur_7.jpg'
import image8 from './images/verhuur_8.jpg'
import image9 from './images/verhuur_9.jpg'
import image10 from './images/verhuur_10.jpg'
import image11 from './images/verhuur_11.jpg'
import image12 from './images/verhuur_12.jpg'
import image13 from './images/verhuur_13.jpg'
import { BsDownload, BsFillFileTextFill } from "react-icons/bs";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumbers";
import useVerhuur from "../../api/verhuur";
import { formatDate } from "../../helpers/formatDate";
import Kalender from "./Kalender";
import ConfirmationModal from "../modal/ConfirmModal";



const validationRules = {
  voornaam: {
    required: "Verplicht",
    minLength: { value: 2, message: "Minimum lengte is 2"}
  },
  familienaam: {
    required: "Verplicht",
    minLength: { value: 2, message: "Minimum lengte is 2"}
  },
  email:{
    required: "Verplicht"
  },
  gsmnummer: {
    required: "Verplicht",
    minLength: {value: 9, message: "Ongeldig nummer"}
  },
  groep: {
    required: "Verplicht",
  },
  straat: {
    required: "Verplicht"
  },
  huisnummer:{
    required: "Verplicht",
    min: 0,
    max: 9999
  },
  postcode:{
    required: "Verplicht",
    length: {
      value: 4,
      message: "Ongeldige postcode."
    }
  },
  gemeente: {
    required: "Verplicht",
  }, 
  akkoordLeeftijd: {
    required: "Gelieve akkoord te gaan!"
  },
  akkoordContracten: {
    required: "Gelieve akkoord te gaan!"
  },
  periode: {
    required: "Verplicht"
  }
}

const errorLabelStyle = {
  color: "red",
  borderColor: "red"
};

export default function VerhuurForm(){

  const {setValue, register, handleSubmit, reset, formState: { errors, isValid }} = useForm();

  const [periodes, setPeriodes] = useState([]);
  const [aantalPeriodes, setAantalPeriodes] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleApiSuccess = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const verhuurAPI = useVerhuur();

  useEffect(() => {
    const fetchPeriodes = async () => {
      const result = await verhuurAPI.getPeriodes();
      setPeriodes(result.periodes);
       
      setAantalPeriodes(result.aantalBeschikbaar);
    }
    fetchPeriodes();
  }, [])

  const onSubmit = useCallback(async (data) => {
    setLoading(true)
    const { voornaam, familienaam, email, gsmnummer, straat, huisnummer, postcode, gemeente, groep, periode, akkoordContracten, akkoordLeeftijd} = data;
     

    try {
      await verhuurAPI.aanvraagIndienen({
        voornaam,
        familienaam,
        email,
        gsmnummer: formatPhoneNumber(gsmnummer),
        straat,
        huisnummer,
        postcode,
        gemeente,
        groep,
        akkoordContracten,
        akkoordLeeftijd,
        periode
      });
      
      setSuccess(true); // Code is gelukt
      setShowModal(true);
      reset();
    } catch (error) {
      // Behandel eventuele fouten hier
    }

  })

  const downloadBestand = useCallback(async (bestand) => {
    try {
      setLoading(true);
      const result = await verhuurAPI.getDocument(bestand);
       
      const fileBlob = new Blob([result], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileBlob);

      const a = document.createElement("a");
      a.href = fileURL;
      a.download = `${bestand}.pdf`;
      a.click();
    } finally {
      setLoading(false);
    }
  }, [])

  return <>
    <div className="container py-4">
      <ConfirmationModal
        show={showModal}
        onClose={handleCloseModal}
        title="Aanvraag ingediend!"
        body="We hebben jouw aanvraag goed ontvangen. U zal binnenkort een antwoord ontvangen!"
      />
      <div className="row">
        <div className="col-12 col-xl-8">
          <h2>Verhuur</h2>
            <Carousel className="my-2">
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image1} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image2} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image3} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image4} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image5} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image6} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image7} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image8} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image9} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image10} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image11} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image12} alt="verhuur" />
              </Carousel.Item>
              <Carousel.Item>
                <img className='rounded w-100 carousel-image' src={image13} alt="verhuur" />
              </Carousel.Item>
            </Carousel>

          <div className="row">
            <div className="col-12 col-lg-6">
              <p>
                Wil je ons lokaal huren dan kan je dit aanvragen via onderstaand formulier. 
                We hebben {aantalPeriodes  === 0 ? "helaas geen periodes meer" : <>{aantalPeriodes  === 1 ? "nog 1 beschikbare periode" : `nog ${aantalPeriodes} beschikbare periodes`}</>} wanneer ons lokaal verhuurd kan worden dit schooljaar.
                Gelieve op de verhuurkalender te kijken of de gewenste periode vrij is. <strong>Wij ontvangen enkel aanvragen voor de beschikbare periodes. 
                We verhuren enkel aan groepen met leden tot en met 12 jaar. </strong>
              </p>
              <p>
                Hieronder vind je het huurcontract en de afsprakennota die nageleefd dient te worden. 
              </p>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="card border-0 shadow p-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <BsFillFileTextFill />
                        <h6 className="ms-2 my-2">Huurcontract</h6>
                      </div>
                      <button className="btn" onClick={()=> downloadBestand("huurcontract")}><BsDownload/></button>
                    </div>
                    
                  </div>
                </div>
                <div className="col-12 col-md-6">
                <div className="card border-0 shadow p-2 mt-3 mt-md-0">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <BsFillFileTextFill />
                        <h6 className="ms-2 my-2">Afsprakennota</h6>
                      </div>
                      <button className="btn" onClick={()=> downloadBestand("afsprakennota")}><BsDownload/></button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="mt-3 mt-md-0">
                <Kalender availablePeriods={periodes} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-xl-4 mt-4 mt-xl-0">
          <div className="card border-0 shadow p-4">
          <h3>Gegevens</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row" >
              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="voornaam" className={`${errors.voornaam && "text-danger"}`}>Voornaam</label>
                  <input
                    {...register("voornaam", validationRules.voornaam)}
                    defaultValue=""
                    id="voornaam"
                    type="text"
                    className={`form-control ${errors.voornaam && "border border-danger"}`}
                    placeholder="Voornaam"
                  />
                  {errors.voornaam 
                    && errors.voornaam.message != "Verplicht"
                    && <p className="form-text text-danger mb-0">{errors.voornaam.message}</p> }
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="familienaam" className={`${errors.familienaam && "text-danger"}`}>Familienaam</label>
                  <input
                    {...register("familienaam", validationRules.familienaam)}
                    defaultValue=""
                    id="familienaam"
                    type="text"
                    className={`form-control ${errors.familienaam && "border border-danger"}`}
                    placeholder="Familienaam"
                  />
                  {errors.familienaam 
                    && errors.familienaam.message != "Verplicht"
                    && <p className="form-text text-danger mb-0">{errors.familienaam.message}</p> }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-3">
                <label htmlFor="email" className={`${errors.email && "text-danger"}`}>Email</label>
                <input
                  {...register("email", validationRules.email)}
                  defaultValue=""
                  id="email"
                  type="email"
                  className={`form-control ${errors.email && "border border-danger"}`}
                  placeholder="Email"
                />
                {errors.email 
                  && errors.email.message != "Verplicht"
                  && <p className="form-text text-danger mb-0">{errors.email.message}</p> }
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-3">
                <label htmlFor="gsmnummer" className={`${errors.gsmnummer && "text-danger"}`}>GSM</label>
                <input
                  {...register("gsmnummer", validationRules.gsmnummer)}
                  defaultValue=""
                  id="gsmnummer"
                  type="text"
                  className={`form-control ${errors.gsmnummer && "border border-danger"}`}
                  placeholder="GSM"
                />
                {errors.gsmnummer 
                  && errors.gsmnummer.message != "Verplicht"
                  && <p className="form-text text-danger mb-0">{errors.gsmnummer.message}</p> }
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-3">
                <label htmlFor="groep"  className={`${errors.groep && "text-danger"}`}>Jeugdbeweging</label>
                <input
                  {...register("groep", validationRules.groep)}
                  defaultValue=""
                  id="groep"
                  type="text"
                  className={`form-control ${errors.groep && "border border-danger"}`}
                  placeholder="Jeugdbeweging"
                />
                {errors.groep 
                  && errors.groep.message != "Verplicht"
                  && <p className="form-text text-danger mb-0">{errors.groep.message}</p> }
              </div>
            </div>
            <div className="row" >
              <div className="col-12 col-md-8">
                <div className="form-group mb-3">
                  <label htmlFor="straat"  className={`${errors.straat && "text-danger"}`}>Straat</label>
                  <input
                    {...register("straat", validationRules.straat)}
                    defaultValue=""
                    id="straat"
                    type="text"
                    className={`form-control ${errors.straat && "border border-danger"}`}
                    placeholder="Straat"
                  />
                  {errors.straat 
                    && errors.straat.message != "Verplicht"
                    && <p className="form-text text-danger mb-0">{errors.straat.message}</p> }
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group mb-3">
                  <label htmlFor="huisnummer" className={`${errors.huisnummer && "text-danger"}`}>Huisnr.</label>
                  <input
                    {...register("huisnummer", validationRules.huisnummer)}
                    defaultValue=""
                    id="huisnummer"
                    type="text"
                    className={`form-control ${errors.huisnummer && "border border-danger"}`}
                    placeholder="XX"
                  />
                  {errors.huisnummer 
                    && errors.huisnummer.message != "Verplicht"
                    && <p className="form-text text-danger mb-0">{errors.huisnummer.message}</p> }
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group mb-3">
                  <label htmlFor="postcode" className={`${errors.postcode && "text-danger"}`}>Postcode</label>
                  <input
                    {...register("postcode", validationRules.postcode)}
                    defaultValue=""
                    id="postcode"
                    type="text"
                    className={`form-control ${errors.postcode && "border border-danger"}`}
                    placeholder="XXXX"
                  />
                  {errors.postcode 
                    && errors.postcode.message != "Verplicht"
                    && <p className="form-text text-danger mb-0">{errors.postcode.message}</p> }
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="form-group mb-3">
                  <label htmlFor="gemeente" className={`${errors.gemeente && "text-danger"}`}>Gemeente</label>
                  <input
                    {...register("gemeente", validationRules.gemeente)}
                    defaultValue=""
                    id="gemeente"
                    type="text"
                    className={`form-control ${errors.gemeente && "border border-danger"}`}
                    placeholder="Gemeente"
                  />
                  {errors.gemeente 
                    && errors.gemeente.message != "Verplicht"
                    && <p className="form-text text-danger mb-0">{errors.gemeente.message}</p> }
                </div>
              </div>

              <div className="col-12">
                <div className="form-group mb-3">
                <label htmlFor="periode"  className={`${errors.periode && "text-danger"}`}>Periode</label>
                <select
                  {...register("periode", validationRules.periode)}
                  id="periode"
                  type="text"
                  className={`form-control ${errors.periode? "border border-danger text-danger": ""}`}
                  placeholder="periode"

                > 
                  <option className="text-dark" value="">-Kies een periode-</option>
                    {periodes.map(periode => {
                      if(periode.status !== "Verhuurd"){
                        return (
                          <option
                            key={periode.id}
                            value={periode.id}
                            className="text-dark"
$                          >
                            {formatDate(periode.start)} t.e.m. {formatDate(periode.eind)}
                          </option>
                        );
                      }
                  })}
                </select>
                {errors.periode 
                  && errors.periode.message != "Verplicht"
                  && <p className="form-text text-danger">{errors.periode.message}</p> }
                </div>
              </div>
              <p className="mb-0">Ik ga akkoord...</p>
              <div className="col-12">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id={`akkoordLeeftijd`}
                    {...register(`akkoordLeeftijd` , { required: true })}
                  />
                  <label
                    htmlFor={`akkoordLeeftijd`}
                    className={`ms-2 ${errors.akkoordLeeftijd ? "text-danger" : ""}`}
                  >
                    dat de leden 12 jaar of jonger zijn.
                  </label>
                </div>
              </div>
              <div className="col-12 mt-2">
                <div className="form-check">
                  <input
                    type="checkbox"
                    id={`akkoordContracten`}
                    {...register(`akkoordContracten` , { required: true })}
                    className={`${errors.akkoordContracten ? "border bg-danger" : ""}`}s
                  />
                  <label 
                    htmlFor={`akkoordContracten`}
                    className={`ms-2 ${errors.akkoordContracten ? "text-danger" : ""}`}>met het huurcontract en de afsprakennota.</label>
                </div>
              </div>
            </div>

            
            <div className="clearfix mt-2">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={!isValid}
                  >
                    Aanvragen!
                  </button>
                </div>
          </form>
          </div>
        </div>
      </div>  
    </div>  
  </>
}