import { useCallback, useEffect, useState } from "react";
import { BsEnvelope, BsFillPatchCheckFill, BsPencil, BsPerson, BsTelephone, BsTrash, BsUnlock } from "react-icons/bs";
import DeleteModal from "../modal/DeleteModal";
import { Link } from "react-router-dom";
import axios from "axios";
import { usersUrl } from "../../helpers/apiUrls";
import useAuth from "../../api/auth";
import ConfirmationModal from "../modal/ConfirmModal";

export default function UserPreview({
  id,
  voornaam,
  familienaam,
  email,
  phone, 
  editable,
  verified,
  verwijderGebruiker
}) {

  const [photo, setPhoto] = useState("");
  const authAPI = useAuth();

  useEffect(() => {
    const fetchImage = async () => {
      try{
        const response = await axios.get(`${usersUrl}/${id}/images/`, { responseType: 'blob' });
        const fileBlob = new Blob([response.data], { type: 'image/png' });
        const url = URL.createObjectURL(fileBlob);
        setPhoto(url)
      }catch(error){
      }
    }
    fetchImage()
  }, [id])

  const handleVerwijderGebruiker = useCallback(() => {
     
    verwijderGebruiker({id})
  }, [id, verwijderGebruiker]);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowModal = () => {
    setShowDeleteModal(true)
  }

  const handleResetPassword = useCallback(async () => {
    await authAPI.requestPasswordMail({email})
    setShowModal(true)
  }, [authAPI, email])

  return (<>
      <ConfirmationModal
        show={showModal}
        onClose={handleCloseModal}
        title="Mail verstuurd"
        body={`Er werd een mail verstuurd naar ${email} om het wachtwoord van dit account te herstellen`}
      />
      <div key={id} className='card shadow hover-larger border-0 m-2 h-100 row'>
        <div className='card-body px-4 pb-0'>
          <div className='d-flex justify-content-center w-100'>
            {photo ? (
              <img className='leiding-image rounded-circle' src={`${photo}`} alt="Leiding"/>
              ) : 
              <div className='leiding-image rounded-circle d-flex justify-content-center align-items-center bg-primary'>
                <BsPerson size={60} color="white"></BsPerson>
              </div>}
          </div>
          <h4 className='d-flex justify-content-center align-items-top mt-2 mb-0'>
            <span>
              {voornaam} {familienaam}
            </span>
            {editable && verified && (
              <BsFillPatchCheckFill size={12} color="green" className="ms-1" />
            )}
          </h4>
          
          {!editable && <>
            <div className='d-flex align-items-center mb-0'>
            <BsEnvelope color={"#395bf1"} />
            <p className='ms-2 mb-0 ellipses'><small><a className="no-link" href={`mailto:${email}`}>{email}</a></small></p>
          </div>
          <div className='d-flex align-items-center'>
            <BsTelephone color={"#395bf1"}/>
            <p className='ms-2 mb-0'><small>{phone}</small></p>
          </div>
          </>}
          
        </div>
        {editable && <>
        <div className="card-footer">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <Link to={`${id}/update`} className="mt-0 no-link">
                <BsPencil color="green" className="clickable me-2" />
              </Link>
            </div>
            <div className="col d-flex justify-content-center">
              <BsUnlock className="clickable mt-1" onClick={handleResetPassword}/> 
            </div>
            <div className="col d-flex justify-content-center">
              <BsTrash color="red" className="clickable mt-1" onClick={handleShowModal} />
            </div>
          </div>          
        </div></>}
      </div>
      <DeleteModal 
        naam={voornaam}
        type="gebruiker"
        show={showDeleteModal}
        onClose={handleCloseDeleteModal}
        verwijderFunctie={handleVerwijderGebruiker}
      />

      </>
  );
};