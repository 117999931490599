import axios from "axios";
import { useCallback } from "react";
import { useAuthProvider } from "../contexts/AuthProvider";
import { analyticsUrl } from "../helpers/apiUrls";

const useAnalytics = () => {

	const getOverview = useCallback(async () => {
    
		const {
			data,
		} = await axios({
			method: "GET",
			url: `${analyticsUrl}/overview`
		})

    return data
	}, []);

	return {
		getOverview,
	};
};

export default useAnalytics;
