export default function Lied1(){
  return <>
    <div>
      <h5>17. Als ze lacht - Yevgueni</h5>
      <div className="mt-3 mb-4"></div>
      <p>Ze is niet altijd even vrolijk<br/>
En dat ligt ook wel eens aan mij<br/>
En een beetje aan de weerman<br/>
Maar die maakt eigenlijk niemand blij<br/>
En al zijn miezerige buien<br/>
Daar heeft zij geen boodschap aan<br/>
Zij wil alle dagen zon<br/>
En als het moet eens een orkaan<br/>
Maar net als hem blijf ik proberen<br/>
Elke dag een flauwe mop<br/>
Plots is daar dan toch die glimlach<br/>
En dan klaart alles, dan klaart alles hier weer op<br/><br/>
<strong>Want als ze lacht<br/>
Breekt de hele hemel open<br/>
Echt ik waan me in de tropen<br/>
'T is echt machtig als ze lacht<br/>
Als ze lacht<br/>
Baad ik uren in de zon<br/>
Ik wou dat ik dat voor haar kon<br/>
Wat zij voor mij doet<br/>
Als ze lacht</strong><br/><br/>
Ben ook niet altijd even vrolijk<br/>
Maar dat ligt echt wel niet aan mij<br/>
Want zelfs met Martine om 7<br/>
Is er maar zelden goed nieuws bij<br/>
Of toch, een nieuwe prins geboren<br/>
Zelfs nog beter een dolfijn<br/>
Maar dan een stuk of honderd plaatsen waar<br/>
Ze weer aan 't vechten zijn<br/>
Er is nochtans niet zoveel nodig<br/>
Misschien meer vrouwen aan de macht<br/>
Dan zeg ik, schat, jij zou dat kunnen<br/>
En echt ik meen, echt ik meen het maar zij lacht<br/><br/>
<strong>En als ze lacht<br/>
Is het even wereldvrede<br/>
Is de laatste strijd gestreden<br/>
'T is echt machtig als ze lacht<br/>
Als ze lacht<br/>
Baad ik uren in de zon<br/>
Ik wou dat ik dat voor haar kon<br/>
Wat zij voor mij doet<br/>
Als ze lacht<br/><br/></strong>
Ja ik wou echt....<br/>
Dat ik dat voor haar kon<br/><br/>
Padadapapa badappapapa, padadapapa badappapapa paaa,<br/>
Padadapapa badappapapa, padadapapa badappapapa paaa,<br/>
<strong>Maar als ze lacht<br/>
Is het even wereldvrede<br/>
Is de laatste strijd gestreden<br/>
'T is echt machtig als ze lacht<br/>
Als ze lacht<br/>
Baad ik uren in de zon<br/>
Ik wou dat ik dat voor haar kon<br/>
Wat zij voor mij doet<br/>
als ze lacht.</strong></p>
    </div>
  </>
}