import logo from './logo_scoutsgidsenvl_groen.png';
import hopper from './hopper-logo-vertical.svg';


export default function SGV(){
  return <>
    <div class="container">
  <h1>Scouts &amp; Gidsen Vlaanderen</h1>
  <div class="text-justify">
    <h3>Scouts &amp; Gidsen Vlaanderen</h3><img src={logo} style={{"float": "left", "height": "200px", "margin-right": "10px"}}/>
    <p>
      Scouts & Gidsen Vlaanderen is een jeugdbeweging voor alle kinderen en jongeren vanaf 6 jaar. 
      Scouting speelt zich af in de vrije tijd en draagt -door een specifieke methode - bij tot de 
      ontspanning en opvoeding van kinderen en jongeren. Week na week, jaar-in, jaar-uit.
    </p>
    <p>
      Speels en met een groot hart voor avontuur geeft onze scouts- en gidsengroep jongens en meisjes 
      een plek waar ze samen kunnen opgroeien en zich ontplooien. Een leeftijdstak biedt jongens en meisjes 
      ruimte om zich te ontwikkelen tot sportieve, creatieve en sociaal bewogen mensen. Een plaats waar 
      ze kansen krijgen om zich een eigen visie en levenshouding te vormen, hiervoor leren opkomen en rekening 
      houden met anderen.
    </p>
    <p>
      Jonge mensen hebben deze jeugdbeweging zelf in handen. Jongeren creëren het spel van scouting in 
      samenspraak met mekaar. Vanuit hun ervaring en realiteit leren kinderen en jongeren zien en voelen 
      wat rechtvaardigheid en geluk kunnen betekenen. Wouters en woudlopers merken aan den lijve dat valsspelen
      de pret bederft. Gidsen en verkenners voelen de tijdloze zaligheid van een vluchtig kamp. Scouts & Gidsen
      Vlaanderen wil scouts en gidsen hiervoor gevoelig maken en hen laten ondervinden welke rol zij kunnen spelen 
      ten opzichte van zichzelf en van anderen.
    </p>
    <p>
      Scouting wil niemand forceren om grenzen van eigen mogelijkheden te overtreffen of om tegen 
      eigen inzichten in te handelen. Scouting is niet dwingend. Wel wil scouting kinderen en jongeren
      prikkelen en uitnodigen om hun eigen mogelijkheden te verkennen, talenten aan te scherpen.
    </p>
    <h3>De Hopper</h3><img src={hopper} style={{"float": "right", "height": "200px", "margin-right": "10px"}}/>
    <p>De Hopper is de winkel van het Vlaams Verbond van Scouts en Meisjesgidsen&nbsp; (Scouts &amp; Gidsen Vlaanderen vzw). Iedereen kan hier terecht voor kampmateriaal, publicaties over scouting, bewegingskledij, enzovoort. We richten ons tot alle kinderen en jongeren die actief op pad willen gaan en hiervoor geschikt materiaal zoeken. Hopper wordt gerund door en voor scouting.</p><br/>
    <p>Alle opbrengsten gaan naar onze jeugdbeweging. En dat is niet het enige verschil met andere winkels die kampeeruitrusting verkopen.</p><br/>
    <p>De Hopper maakt als dienst deel uit van de scouts- en gidsenbeweging:&nbsp; service gaat bij ons voor commercieel zakendoen. Het gerief dat wij aanbieden wordt prijs- en kwaliteitsbewust uitgezocht voor het spel van scouting. Zo zijn veiligheid en milieuvriendelijkheid bij ons bijzondere aandachtspunten. We vinden het eveneens belangrijk om geen handel te voeren met landen waar kinderarbeid bestaat en kopen onze producten elders, ook al is het dan duurder.</p><br/>
    <p>We bieden alleen dingen aan die gekozen zijn door de nationale scouts- en gidsenleiding van Scouts &amp; Gidsen vlaanderen vzw; geen verouderde en vaak kostelijke attributen en geen materiaal dat om pedagogische redenen uit scouting wordt geweerd zoals bijvoorbeeld dolken. Zo is in de Hopper enkel het offici&euml;le scouts- en gidsenuniform verkrijgbaar: sober, stijlvol, degelijk en betaalbaar. Dit offici&euml;le uniform is ook uitsluitend in de scoutsshops of bij erkende verdelers te koop.</p><br/>
    <p><strong>Adres</strong><br/>Antwerpsesteenweg 42, 9040 Gent (St Amandsberg)
    <br/>
    <strong>Tel</strong><br/>09 223 84 42<br/>
    <strong>Webiste</strong><br/><a href="https://www.hopper.be/">https://www.hopper.be/</a><br/>
    <strong>Openingsuren</strong>
      <ul>
        <li>woensdag - vrijdag: 14u - 18u</li>
        <li>zaterdag: 10u - 12u en 12u30 - 18u</li>
      </ul>
    </p><br/>
    <p>(Tip: Als je aankopen doet in de scoutsshop, vergeet dan zeker je lidkaart niet voor een extra korting) </p>
  </div>
</div>
  </>
}