import React from 'react';
import { BsPeopleFill, BsClockHistory } from 'react-icons/bs';
import './Infobox.css';
import waffer from "./waffers.png"

export default function Infobox() {
  return (
    <div className="container dashboard fade-in">
      <h2 className="text-center">Wist je dat...</h2>
      <div className="row">
        <div className="col-md-4 mt-2">
          <div className="dashboard-card card-blue h-100">
            <BsClockHistory className="dashboard-icon" />
            <h4>1957</h4>
            <p>Onze scouts opgericht werd in 1957?</p>
          </div>
        </div>
        <div className="col-md-4 mt-2">
          <div className="dashboard-card card-green h-100">
            <BsPeopleFill color='#8bc34a' className="dashboard-icon" />
            <h4>226</h4>
            <p>Er 226 leden in de scouts zijn ingeschreven?</p>
          </div>
        </div>
        <div className="col-md-4 mt-2">
          <div className="dashboard-card card-yellow h-100">
            <img src={waffer} className="dashboard-icon" />
            <h4>11.772</h4>
            <p>We 11.772 wafels bakten op onze wafelbak van 2023? Een record!</p>
          </div>
        </div>
      </div>
    </div>
  )
}