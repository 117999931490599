import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import useImages from "../../api/images";
import Loader from '../loader/Loader';
import Folder from "./Folder";
import { Link } from "react-router-dom";
import FolderForm from "./FolderForm";
import { capitalizeFirstLetter } from "../../helpers/capitalize";
import { useAuthProvider } from "../../contexts/AuthProvider";

export default function MediaViewer() {
  
  const [ folders, setFolders ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const {currentUser} = useAuthProvider();

  const {takid, albumid } = useParams();
  const imageAPI = useImages();

  const save = useCallback( async ({nieuweMap}) => {
    const result = await imageAPI.uploadFolder({takid, nieuweMap});
     
    setFolders(result);
  })
  
  useEffect(() => {
    const fetchMappen = async () => {
      try {
        const result = await imageAPI.getFolders({
          takid,
        })
        setFolders(result);
        setLoading(false)
      } catch (error) {
        
      }
    }
    fetchMappen()
  }, [loading, takid, albumid])

  return <>
    <div className="container py-4">
      <h1>Foto's</h1>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className={`breadcrumb-item ${takid ? null : "active"}`}>{takid ? <Link to="/images">Foto's</Link> : "Foto's"}</li>
          {takid ? <li className="breadcrumb-item active">{capitalizeFirstLetter(takid)}</li> : null}
        </ol>
      </nav>
      {takid ? (
        currentUser?.roles.includes("Leiding") ? (
          <FolderForm takid={takid} save={save} />
        ) : null
      ) : currentUser?.roles.includes("Admin") ? (
        <FolderForm takid={null} save={save} />
      ) : null}

      {loading ? <Loader></Loader> : <>
        {folders.length <= 0 ? 
        <div className="alert alert-info" data-cy="transactions_error">
          <p className="alert-heading">Er zijn nog geen afbeeldingen voor deze map.</p>
        </div>
        :
          <div className="row">
          {folders.map(folder => {
            return <Folder key={folder} naam={folder} />
          })}
        </div>}
      </>}
    </div>
  </>

}