import React, { useCallback, useState } from 'react';
import Dropzone from 'react-dropzone';
import Loader from '../loader/Loader';
import "./Upload.css"


const UploadComponentMultiple = ({onSaveAfbeelding, loadedLogo, limit}) => {
  const [ selected, setSelected ] = useState(false);
  const [paths, setPaths] = useState([]);

  const handleDrop = useCallback((acceptedFiles) => {
     
    setPaths(acceptedFiles.map(file => URL.createObjectURL(file)));
    onSaveAfbeelding(acceptedFiles);
    setSelected(true)
  }, [setPaths]);

  return (
    <div className='card card-rounded min-height-250 shadow border border-0 w-100'>
      <div className='row'>
      <div className={`${selected ? "col-12 col-lg-6" : "col-12"}`}>
          <div className='drop-zone-container text-center h-100 d-flex flex-column align-items-center justify-content-center'>
            <div className='drop-zone-image mb-2'></div>
            <Dropzone onDrop={handleDrop} accept="image/jpg, image/png, image/jpeg" multiple={true} maxFiles={limit ? limit : null}>
                    {({ getRootProps, getInputProps }) => (
                      <div className='' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drop jouw afbeeldingen hier of <span className='text-primary clickable'>zoek</span></p>
                        <p className='text-muted'><small>Ondersteunt .png .jpeg .jpg </small></p>
                        <p className='text-muted'><small>{limit? `Maximaal ${limit} foto's!` : ""}</small></p>
                      </div>
                    )}
            </Dropzone>
          </div>
        </div>
        
        <div className='col-12 col-lg-6'>

          {paths.slice(0,3).map(path => {
            return <>
            <div class="mt-2">
                  <img 
                    key={path} 
                    src={path}
                    class="upload-preview w-100" 
                    alt="..." />
            </div>
            </>
          })}
          {paths.length > 3 ? <div class="mt-2">
            <div className="alert alert-info" data-cy="transactions_error">
              <h4 className="alert-heading">+{paths.length - 3}</h4>
            </div>
            </div> : null}
        </div>
      </div>
    </div>
  );
};

export default UploadComponentMultiple;
