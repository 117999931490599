import React from 'react';

const ConfirmationModal = ({ show, onClose, title, body }) => {
  return (
    <div
      className={`modal fade${show ? ' show' : ''}`}
      style={{ display: show ? 'block' : 'none' }}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="confirmationModal"
      aria-hidden={!show}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="confirmationModal">
              {title}
            </h5>
            <button type="button" className="btn close" data-dismiss="modal" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {body}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={onClose}>
              Sluiten
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
