import { BsPerson, BsPlus } from "react-icons/bs";

export default function ProfileImage({image, isAdmin}){
  return <>
    <div className='d-flex justify-content-center'>
      <div className='profile-image-container shadow-lg'>
        <div className="profile-image">
          {image ? 
            <div className="user-photo-container">
              <img className="user-photo rounded-cirlce d-flex shadow" src={image} alt="Profile" />
            </div>
            :
            <div className='profile-image rounded-circle d-flex justify-content-center align-items-center bg-primary'>
              <BsPerson size={60} color="white"></BsPerson>
            </div>
          }
        </div>
        {isAdmin &&
        <div className="camera-icon clickable shadow">
          <BsPlus size={15} color='white' className='m-1'/>
        </div>
        }
        
      </div>
    </div>
  </>
}