export default function Lied1(){
  return <>
    <div>
      <h5>8. Wonderwall - Oasis</h5>
      <div className="mt-3 mb-4"></div>
      <p>Today is gonna be the day that they're gonna throw it back to you<br/>
And by now, you should've somehow realised what you gotta do<br/>
I don't believe that anybody feels the way I do about you now<br/><br/>
And backbeat, the word is on the street that the fire in your heart is out<br/>
I'm sure you've heard it all before, but you never really had a doubt<br/>
I don't believe that anybody feels the way I do about you now<br/><br/>
And all the roads we have to walk are winding<br/>
And all the lights that lead us there are blinding<br/>
There are many things that I would like to say to you, but I don't know how<br/><br/>
<strong>Because maybe<br/>
You're gonna be the one that saves me<br/>
And after all<br/>
You're my wonderwall<br/><br/></strong>
Today was gonna be the day, but they'll never throw it back to you<br/>
And by now, you should've somehow realised what you're not to do<br/>
I don't believe that anybody feels the way I do about you now<br/><br/>
And all the roads that lead you there were winding<br/>
And all the lights that light the way are blinding<br/>
There are many things that I would like to say to you, but I don't know how<br/>
<strong>I said maybe<br/>
You're gonna be the one that saves me<br/>
And after all<br/>
You're my wonderwall<br/><br/>
I said maybe (I said maybe)<br/>
You're gonna be the one that saves me<br/>
And after all<br/>
You're my wonderwall</strong>
</p>
    </div>
  </>
}