const formatDate = (rawDate) => {
	const options = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	};

	const date = new Date(rawDate);
	const formattedDate = date.toLocaleDateString(undefined, options);

	// Vervang standaard scheidingsteken door "/"
	const formattedDateWithSeparator = formattedDate.replace(/\D/g, "/");

	return formattedDateWithSeparator;
};

module.exports = {
	formatDate
};