const vertaalDagen = (dagen) => {
  if(!dagen){
    return []
  }
  const vertaling = {
    "Monday": "Maandag",
    "Tuesday": "Dinsdag",
    "Wednesday": "Woensdag",
    "Thursday": "Donderdag",
    "Friday": "Vrijdag",
    "Saturday": "Zaterdag",
    "Sunday": "Zondag"
  };

  dagen.forEach(dag => {
    if (dag.dayname in vertaling) {
        dag.dayname = vertaling[dag.dayname];
    }
  });

  return dagen;
}

function sorteerDagen(dagen) {

  if(!dagen){
    return []
  }
  const volgorde = {
      "Maandag": 1,
      "Dinsdag": 2,
      "Woensdag": 3,
      "Donderdag": 4,
      "Vrijdag": 5,
      "Zaterdag": 6,
      "Zondag": 7
  };

  dagen.sort((a, b) => volgorde[a.dayname] - volgorde[b.dayname]);

  return dagen;
}

module.exports = {
  vertaalDagen,
  sorteerDagen
}