import React, { useCallback, useEffect, useState } from 'react';
import LogTag from './LogTag';
import useLogs from '../../api/logs';
import Limit from '../filtering/limit';
import Page from '../filtering/page';

export default function LogTable() {
  const [logs, setLogs]   = useState([]);
  const [tags, setTags]   = useState([]);
  const [tag, setTag]     = useState("");
  const [pages, setPages] = useState([])
  const [page, setPage]   = useState(1);
  const [limit, setLimit] = useState(10);

  const logAPI = useLogs();

  const fetchLogs = async ({tag}) => {
    const logs = await logAPI.getAll({
      tag,
      limit,
      pageNumber: page
    });
    console.log(logs)
    setLogs(logs.logs)
    setPages(Math.ceil(logs.count/limit))
  }

  useEffect(()=> {
    fetchLogs({tag, limit})
  }, [tag, limit, page])

  const handleChangeTag = useCallback(async (event) => {
    const selectedValue = event.target.value;
    setTag(selectedValue)
  }, [])

  const handleChangeLimit = useCallback(async (limit) => {
    setLimit(limit)
  },[])

  const handleChangePage = useCallback(async (page) => {
    setPage(page)
  }, [])

  useEffect(()=> {
    const fetchTags = async () => {
      const tags = await logAPI.getAllTags()
      setTags(tags)
    }
    fetchTags()
  }, [])

  return (<>
    <div className='container'>
    <h2>Logs</h2>
    <hr/>
    <div className='row'>
      <div className='col-12 col-md-8'>
        <label>Tag</label>
        <select className="form-select" onChange={handleChangeTag}>
          <option value="">Alle tags</option>
          {tags.map(tag => {
            return <>
            <option value={tag.name}>
              <LogTag tag={tag.name} color={tag.color}></LogTag>
            </option>
            </>
          })}
        </select>
      </div>
      <div className='col-6 col-md-2'>
        <Limit onChangeLimit={handleChangeLimit}/>
      </div>
      <div className='col-6 col-md-2'>
        <Page amount={pages} onChangePage={handleChangePage}></Page>
      </div>
    </div>
    <hr/>
    <table className="table table-striped">
      <thead>
        <tr>
          <th className='d-none d-lg-table-cell'>ID</th>
          <th>Timestamp</th>
          <th>Tag</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log) => (
          <tr key={log.id}>
            <td className='d-none d-lg-table-cell'>{log.id}</td>
            <td>{new Date(log.timestamp).toLocaleString('nl-BE', { timeZone: 'GMT' })}</td>
            <td>
              <LogTag tag={log.tag} color={log.color}></LogTag>
            </td>
            <td>{log.voornaam} {log.familienaam}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
    </>
  );
}
