import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthProvider } from '../../contexts/AuthProvider';
import axios from 'axios';
import './Login.css'
import useAuth from '../../api/auth';

const baseUrl = process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_API_DEV_URL}/auth` : `${process.env.REACT_APP_API_URL}/auth`;

export default function Login() {
  const { setToken, setCurrentUser, currentUser } = useAuthProvider();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [ errors, setErrors ] = useState([])
  const authAPI = useAuth();

  const handleLogin = async (event) => {
    event.preventDefault()
    // Implement your login logic here
    // Call an API to verify credentials and get a token
    try{
      setErrors([])
      const response = await axios.post(`${baseUrl}/login`, { email, password });
      const {token} = await authAPI.getToken({email, password})
      setToken(token);
      setCurrentUser(response.data.user)
    }catch(error){
      setErrors([...errors, error])
    }
  }

  useEffect(() => {
    if(currentUser){
      navigate("/profiel");
    }
  }, [currentUser, navigate])

  return (
    <div className='login-body'>
    <div class="container d-flex justify-content-center align-items-center mt-0">
    <div class="login-container card shadow border-0 w-md-50 p-4">
      <div className='login-form'>
        
        <div className='w-100 d-flex justify-content-center'>
          <div className='login-logo align-self-center' />
        </div>
        <h1 class="login-title text-center">Login</h1>
        <p>Leiding bij Scouts Wondelgem? Log dan hier in!</p>
        <form onSubmit={handleLogin}>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div class="form-group mt-4">
            <label for="password">Wachtwoord</label>
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Wachtwoord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {errors?.length > 0 && <><p className='text-danger'>Inloggegevens zijn niet correct.</p></> }
          <button class="btn btn-primary btn-block mt-4" type="submit">Login</button>
          <p class="help-link">Wachtwoord vergeten? <Link to="wachtwoord/vergeten" >Reset het hier.</Link></p>
        </form>
      </div>
    </div>
  </div>
  </div>
  );
}