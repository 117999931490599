export default function Lied1({songNr}){
  console.log(songNr)
  return <>
    <div>
      <h5>3. Oya lele - K3</h5>
      <div className="mt-3 mb-4"></div>
      <p><strong>Oya lele ik voel me plots weer zo oya lele<br/>
Doe het nog een keer zo oya lele<br/>
Zing met ons mee<br/>
Oh ja<br/>
Oh jee</strong><br/><br/>
De zomer komt eraan<br/>
‘t maakt niet uit wat je doet<br/>
jong en oud dansen door de straat<br/>
De zomer komt eraan<br/>
Iedereen voelt zich goed<br/>
als de zon uit de kleren gaat<br/>
Oh jeee Oya lele<br/>
Ik geef je een stiekeme zoen<br/>
Oh jeee Oya lele<br/>
En niemand die merkt wat we doen<br/>
<strong>Oya lele ik voel me plots weer zo oya lele<br/>
Doe het nog een keer zo oya lele<br/>
Zing met ons mee<br/>
Oh ja<br/>
Oh jee</strong><br/><br/>
Leven is een feest<br/>
Elke dag weer een nieuw avontuur als je bij me bent<br/>
‘t leven is een feest<br/>
en mijn hart staat in vuur en vlam als je mij verwent<br/>
Oh jeee oya lele<br/>
Ik geef je een stiekeme zoen<br/>
Oh jeee oya lele<br/>
En niemand die merkt wat we doen<br/>
<strong>Oya lele ik voel me plots weer zo oya lele<br/>
Doe het nog een keer zo oya lele<br/>
Zing met ons mee<br/>
Oh ja<br/>
Oh jee</strong><br/><br/>
<strong>Oya lele ik voel me plots weer zo oya lele<br/>
Doe het nog een keer zo oya lele<br/>
Zing met ons mee<br/>
Oh ja<br/>
Oh jee</strong><br/><br/>
</p>
    </div>
  </>
}