import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [currentUser, setCurrentUser] = useState(JSON.parse( localStorage.getItem('user')) || null);
  const [loadingUser, setLoadingUser] = useState(true); // Start met true om de gebruiker te laden

  useEffect(() => {
    // Functie om op te slaan in lokale opslag
    const saveToLocalStorage = () => {
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(currentUser));
    };

    if (token && currentUser) {
       
      saveToLocalStorage();
    } else {
      // Als er geen token of gebruiker is, verwijder dan de gegevens uit de lokale opslag
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }

    setLoadingUser(false);
  }, [token, currentUser]);

  // Bijwerken van de gebruiker bij vernieuwen van de pagina
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
       
      setCurrentUser(JSON.parse(storedUser));
    }
    setLoadingUser(false);
  }, []);

  const contextValue = {
    token,
    setToken,
    loadingUser,
    setLoadingUser,
    currentUser,
    setCurrentUser,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuthProvider = () => useContext(AuthContext);