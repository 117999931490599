import Lied1 from "./Lied1";
import Lied2 from "./Lied2";
import Lied3 from "./Lied3";
import Lied4 from "./Lied4";
import Lied5 from "./Lied5";
import Lied6 from "./Lied6";
import Lied7 from "./Lied7";
import Lied8 from "./Lied8";
import Lied9 from "./Lied9";
import Lied10 from "./Lied10";
import Lied11 from "./Lied11";
import Lied12 from "./Lied12";
import Lied13 from "./Lied13";
import Lied14 from "./Lied14";
import Lied15 from "./Lied15";
import Lied16 from "./Lied16";
import Lied17 from "./Lied17";
import Lied18 from "./Lied18";
import Lied19 from "./Lied19";
import Lied20 from "./Lied20";
import Lied21 from "./Lied21";
import { useEffect, useState } from "react";
import { useCallback } from "react";

const AANTAL_LIEDJES = 21

export default function Zangstonde(){

  const [songNr, setSongNr] = useState(1)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [songNr]);

  const pageDown = useCallback(() => {
    setSongNr((prevSongNr) => (prevSongNr > 1 ? prevSongNr - 1 : 1));
  }, []);

  const pageUp = useCallback(() => {
    setSongNr((prevSongNr) => (prevSongNr < AANTAL_LIEDJES ? prevSongNr + 1 : AANTAL_LIEDJES));
  }, []);

  const renderLied = () => {
    switch (songNr) {
      case 1:
        return <Lied1 />;
      case 2:
        return <Lied2 />;
      case 3:
        return <Lied3 />;
      case 4:
        return <Lied4 />;
      case 5:
        return <Lied5 />;
      case 6:
        return <Lied6 />;
      case 7:
        return <Lied7 />;
      case 8:
        return <Lied8 />;
      case 9:
        return <Lied9 />;
      case 10:
        return <Lied10 />;
      case 11:
        return <Lied11 />;
      case 12:
        return <Lied12 />;
      case 13:
        return <Lied13 />;
      case 14:
        return <Lied14 />;
      case 15:
        return <Lied15 />;
      case 16:
        return <Lied16 />;
      case 17:
        return <Lied17 />;
      case 18:
        return <Lied18 />;
      case 19:
        return <Lied19 />;
      case 20:
        return <Lied20 />;
      case 21:
        return <Lied21 />;
      default:
        return <p>Geen lied beschikbaar</p>;
    }
  };

  return <>
    <div className="container mt-4">
      <h1>Zangstonde</h1>
      <p>
        Trek je stem open en zing mee met onze favoriete kampvuurliedjes! Hieronder vind je alle
        liedjes in de juiste volgorde, zodat je zonder zorgen kunt meezingen. Gebruik deze pagina om
        lekker mee te zingen en vergeet niet: zingen is het beste samen!
      </p>
      <p>Veel zangplezier!</p>
      <hr />
      {renderLied()}
      <div className="d-flex justify-content-between mt-2 mb-3">
        <button className="btn btn-primary text-white w-100 m-2" onClick={pageDown} disabled={songNr <= 1}>
          &lt; Vorige
        </button>
        <button className="btn btn-primary text-white w-100 m-2" onClick={pageUp} disabled={songNr >= AANTAL_LIEDJES}>
          Volgende &gt;
        </button>
      </div>
    </div>
  </>
}