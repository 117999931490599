import { useCallback, useState } from "react"

export default function DeleteModal({naam, show, onClose, type, verwijderFunctie}) {

  const [hidden, setHidden ] = useState(true)

  const handleClick = useCallback(() => {
     
    verwijderFunctie()
    onClose()
  })
  
  return <>
    <div
          className={`modal fade${show ? ' show' : ''}`}
          style={{ display: show ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="confirmationModal"
          aria-hidden={!show}
        >      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">Verwijder {naam}</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <p>Weet je zeker dat je deze {type} wilt verwijderen?</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Annuleer</button>
            <button type="button" className="btn btn-danger" onClick={handleClick}>Verwijder</button>
          </div>
        </div>
      </div>
    </div>
  </>
}