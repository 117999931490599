export default function Lied1(){
  return <>
    <div>
      <h5>2. Erop of Eronder - Pommelien thijs</h5>
      <div className="mt-3 mb-4"></div>
      <p>Ik las het deze week op het internet, niet dat ik het wou <br/>
Want normaal geloof ik niks van het internet, maar ik dacht aan jou<br/>
Vijf signalen om te achterhalen of het halve liefde is<br/>
Nu kan ik niet ontkennen dat ik twijfels heb als je naast me ligt<br/>
Want elke keer als ik je vraag wat je wil van mij<br/>
Zeg je niks meer, je laat het open<br/><br/>
<strong>
Het is erop of eronder, nu of nooit<br/>
We hebben zo veel tijd al weggegooid<br/>
Is het beter hier of beter dat je gaat?<br/>
't Is erop of eronder, de hoogste tijd<br/>
Wie ben ik voor jou? Wie ben jij voor mij?<br/>
Is het beter hier of beter dat je gaat?<br/><br/>
</strong>
Alles wat ik had, heb ik ingezet, omdat ik het wou<br/>
Maar volgens dat artikel op het internet is het nu aan jou<br/>
Met andere woorden: ik weet wat ik hoorde, en jij weet wat je zei<br/>
Dus kom nu niet vertellen dat je twijfels hebt als je denkt aan mij<br/>
Want elke keer als ik je vraag wat je wil van mij<br/>
Zeg je niks meer, je laat het open, wo-ow<br/><br/>

<strong>
Het is erop of eronder, nu of nooit<br/>
We hebben zo veel tijd al weggegooid<br/>
Is het beter hier of beter dat je gaat?<br/>
't Is erop of eronder, de hoogste tijd<br/>
Wie ben ik voor jou? Wie ben jij voor mij?<br/>
Is het beter hier of beter dat je gaat?<br/><br/>
</strong>
Geef mij alles of niks, alles of niks, a-alles of niks<br/>
Geef mij alles of niks, alles of niks, oh<br/>
Geef mij alles of niks, alles of niks, a-alles of niks<br/>
Geef mij alles of niks, mm-hm<br/><br/>
<strong>
Het is erop of eronder, nu of nooit<br/>
We hebben zo veel tijd al weggegooid<br/>
Is het beter hier of beter dat je gaat?<br/>
't Is erop of eronder, de hoogste tijd<br/>
Wie ben ik voor jou? Wie ben jij voor mij?<br/>
Is het beter hier of beter dat je gaat?</strong></p>
    </div>
  </>
}