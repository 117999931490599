import { useForm } from "react-hook-form";
import { compareAsc } from "date-fns";

const validationRules = {
  start: {
    required: "Gelieve een startdatum te geven.",
  },
  eind: {
    validate: (value, allValues) => {
      if (!value) {
        return "Gelieve een einddatum op te geven";
      }

      const start = allValues.start;
       
       

      if (compareAsc(new Date(start), new Date(value)) > 0) {
         
        return "Einde moet na het begin zijn";
      }

       
      return null;
    },
  },
};
export default function PeriodeForm({save}) {

  const {register, handleSubmit, formState: { errors }} = useForm();

  const onSubmit = (data) => {
    const {start, eind} = data;
    save({start, eind})
  }

return <>
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className="row">
      <div className="col">
        <div class="form-group">
          <label for="startDate">Van:</label>
          <input type="date" class="form-control" id="startDate" aria-describedby="startDatumPeriode" 
            {...register("start", validationRules.start)}
          />
        </div>
      </div>
      <div className="col">
        <div class="form-group">
          <label for="endDate">Tot:</label>
          <input type="date" class="form-control" id="endDate" aria-describedby="eindDatumPeriode" 
             {...register("eind", validationRules.eind)}
          />
        </div>    
      </div>
      <div className="col d-flex align-items-end">
        <button disabled={Object.keys(errors)?.length > 0} className="btn btn-primary" type="submit">Maak beschikbaar!</button>
      </div>
    </div>
   {errors.eind && <p className="form-text text-danger mb-0">{errors.eind.message}</p> }
  </form>
  </>
}