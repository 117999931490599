import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import '/node_modules/react-quill/dist/quill.snow.css'; // Importeer de CSS voor de stijling van de editor
import './Event.css'
import useEvent from "../../api/event";
import UploadComponentSingle from "../upload/FileUploadSingle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { eventsUrl } from "../../helpers/apiUrls";
import { compareAsc } from "date-fns";


export default function EventForm(){
  const validationRules = {
    titel: {
      required: "Gelieve de tak een naam te geven.",
      minLength: { value: 3, message: "Minimum lengte is 3"}
    },
    teaser: {
      required: "Gelieve een teaser te geven.",
      maxLenght: {value: 300, message: "Maximumlengte is 300 tekens."}
    },
    beschrijving: {
      required: "Gelieve een beschrijving te geven.",
      maxLenght: {value: 10000, message: "Maximumlengte is 10000 tekens."}
    },
    startDatum: {
      required: "Gelieve een startdatum op te geven"
    },
    startUur: {
      required: "Gelieve een startuur op te geven"
    },
    eindDatum: {
      validate: (value, allValues) => {
        if(!value){
          return "Gelieve een einddatum op te geven"
        }
        const start = allValues.startDatum;
        if (compareAsc(new Date(start), new Date(value)) > 0) {
          return "Einde moet na het begin zijn";
        }
        return null;
      }
    },
    eindUur: {
      validate: (value, allValues) => {
        if(!value){
          return "Gelieve een einduur op te geven"
        }
        const startDatum = allValues.startDatum;
        const eindDatum = allValues.eindDatum;
        // als de start en einddatum gelijk zijn, check dan of einduur na beginuur valt
        if (compareAsc(new Date(startDatum), new Date(eindDatum))  === 0) {
          const startUur = allValues.startUur;
          if(startUur > value){
            return "Einde moet na het begin zijn";
          }
        }
        return null;
      }
    },
    afbeelding: {
      required: "Gelieve een afbeelding te selecteren."
    }
  }

  const [editorHtml, setEditorHtml] = useState('');

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const [ loadedLogo, setLoadedLogo] = useState(false);
  const [ selectedFile, setSelectedFile] = useState();

  const eventAPI = useEvent();
  const navigate = useNavigate();

  const {register, handleSubmit, reset, formState: { errors }} = useForm();

  const save = useCallback(async ({
    titel,
    teaser,
    beschrijving,
    start,
    eind,
    afbeelding}) => {
      const response = await eventAPI.create({
        titel,
        teaser,
        beschrijving,
        start,
        eind,
        afbeelding})
       

      if (selectedFile) {

         
        let formData = new FormData();
        formData.append('image', selectedFile);

        let result = await eventAPI.uploadBanner(response.id, formData)
        
        
         
      }
      navigate(`/evenementen`, {replace: true})
  });

  const handleFileSelect = useCallback(async (file) => {
     
    setSelectedFile(file)
    setLoadedLogo(true);
  }, []);

  const onSubmit = (data) => {
    const { titel, teaser, startDatum, startUur, eindDatum, eindUur, afbeelding} = data;
     
    save({
      titel,
      teaser,
      beschrijving: editorHtml,
      start: `${startDatum}T${startUur}`,
      eind: `${eindDatum}T${eindUur}`,
      afbeelding
    });
    reset();
  }

  return <>
    <div className="container">
      <h2>Maak een evenement</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
      <div className="col-12 col-md">
          <div className="form-group mb-3">
            <label htmlFor="titel">Titel</label>
            <input
              {...register("titel", validationRules.titel)}
              defaultValue=""
              id="titel"
              type="text"
              className="form-control"
              placeholder="Titel"
            />
            {errors.titel && <p className="form-text text-danger mb-0">{errors.titel.message}</p> }
          </div>
          <div className="form-group mb-3">
            <label htmlFor="titel">Teaser</label>
            <input
              {...register("teaser", validationRules.teaser)}
              defaultValue=""
              id="teaser"
              type="text"
              className="form-control"
              placeholder="Teaser"
            />
            {errors.teaser && <p className="form-text text-danger mb-0">{errors.teaser.message}</p> }
          </div>
          <div className="form-group mb-4">
            <label htmlFor="titel">Beschrijving</label>
            <ReactQuill style={{height: "150px"}} theme="snow" value={editorHtml} onChange={handleChange} />
          </div>
          <div className="form-group mt-5 mb-3 row">
            <div className="col">
              <label htmlFor="startDatum">Start Datum</label>
              <input
                {...register("startDatum", validationRules.startDatum)}
                defaultValue=""
                id="startDatum"
                type="date" // Gebruik 'date' als je een datum wilt kiezen
                className="form-control"
              />
              {errors.startDatum && <p className="form-text text-danger mb-0">{errors.startDatum.message}</p>}
            </div>
            <div className="col">
              <label htmlFor="startUur">Start Uur</label>
              <input
                {...register("startUur", validationRules.startUur)}
                defaultValue=""
                id="startUur"
                type="time"
                className="form-control"
              />
              {errors.startUur && <p className="form-text text-danger mb-0">{errors.startUur.message}</p>}
            </div>
          </div>
          <div className="form-group mb-3 row">
            <div className="col">
              <label htmlFor="eindDatum">Eind Datum</label>
              <input
                {...register("eindDatum", validationRules.eindDatum)}
                defaultValue=""
                id="eindDatum"
                type="date" // Gebruik 'date' als je een datum wilt kiezen
                className="form-control"
              />
              {errors.eindDatum && <p className="form-text text-danger mb-0">{errors.eindDatum.message}</p>}
            </div>
            <div className="col">
              <label htmlFor="eindUur">Eind Uur</label>
              <input
                {...register("eindUur", validationRules.eindUur)}
                defaultValue=""
                id="eindUur"
                type="time"
                className="form-control"
              />
              {errors.eindUur && <p className="form-text text-danger mb-0">{errors.eindUur.message}</p>}
            </div>
          </div>
        </div>
        <div className="col-12 col-md">
          <h4>Banner</h4>
          <UploadComponentSingle onSaveAfbeelding={handleFileSelect} loadedLogo={loadedLogo}></UploadComponentSingle>
        </div>
      </div>
       
        <div className="clearfix">
          <button
            type="submit"
            className="btn btn-primary w-100"
          >
            Voeg toe!
          </button>
        </div>
      </form>
    </div>
  </>

}