import React from 'react';
import { BsExclamationTriangle } from 'react-icons/bs';

const ForbiddenComponent = () => {
  return (
    <div className='container text-center' >
      <BsExclamationTriangle color="#5bc0de" size={50} />
      <h1>Toegang geweigerd</h1>
      <p>Enkel voor internet-scouts met een kampvuurvergunning.</p>
      <p>Marshmallows en digitale survivalvaardigheden zijn vereist!</p>
    </div>
  );
};

export default ForbiddenComponent;