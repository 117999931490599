export default function Inschrijven() {
  return <>
    <div className="container">
      <h2>Inschrijvingen scoutsjaar 2024-2025</h2>
      <p>Leuk dat u uw kind wil inschrijven in onze scouts. De inschrijvingen voor het scoutsjaar 2024- 2025 starten op <bold>5 augustus 2024 om 9u00.</bold> Dit kan u doen via deze link. We werken volgens het principe first come, first serve.</p>
      <p>U kan uw kind inschrijven vanaf het geboortejaar 2018 of ouder. De inschrijving via de link garandeert geen plekje. Eind augustus/begin september wordt er een bevestiging gestuurd via mail.</p>
      <p>Inschrijven kan u doen <a href="https://forms.gle/Vp4Auqibtm3sod9x9">hier</a>.</p>

      <h4>Broertjes en zusjes van bestaande leden en/of kinderen van oud-leiding </h4>
      <p>Joepie! Jullie kunnen krijgen voorrang. Voor zijn de inschrijvingen nu al geopend. Schrijf jullie snel in via <a href="https://docs.google.com/forms/d/e/1FAIpQLSesJz328IM0tOnatqxCgnOovEXlZZDvRUQYzSmwZ3xSHVWrIw/closedform ">deze link</a>.</p>
    </div>
  </>
}