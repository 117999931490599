import { Link, useNavigate } from 'react-router-dom';
import { useAuthProvider } from '../../contexts/AuthProvider';
import Logout from '../authentication/Logout';
import { BsEnvelope, BsPeople, BsCamera, BsCalendar, BsTelephone, BsDatabaseLock, BsLock, BsCalendarPlus, BsFiletypePdf, BsFillPatchCheckFill, BsPlusCircle, BsPerson, BsClipboard, BsChat, BsSearch, BsPhone, BsDashCircle, BsPlus } from 'react-icons/bs';
import "./Profile.css";
import { SlBadge } from "react-icons/sl";
import useAnalytics from '../../api/analytics';
import { FaArrowRight } from 'react-icons/fa';
import { useCallback, useEffect, useState } from 'react';
import useUser from '../../api/user';
import useAuth from '../../api/auth';
import ConfirmModal from '../modal/ConfirmModal';
import MaandbriefForm from '../tak/MaandbriefForm';
import ConfirmationModal from '../modal/ConfirmModal';
import PeriodeLijst from '../beschikbaarheden/PeriodeLijst';
import Dashboard from './Dashboard';
import MaandbrievenLijst from '../tak/MaandbrievenLijst';
import SideBar from './SideBar';
import GazetteForm from '../gazette/GazetteForm';
import useGazette from '../../api/gazette';
import ProfileImage from './ProfileImage';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { sorteerDagen, vertaalDagen } from '../../helpers/dagen';
import GazettePreview from '../gazette/GazettePreview';


export default function Profile() {
  const { token, currentUser, loadingUser } = useAuthProvider();
  const navigate = useNavigate();

  const [showUploadBrief, setShowUploadBrief] = useState(false)

  const userAPI = useUser();
  const authAPI = useAuth();
  const gazetteAPI = useGazette()

  const [ image, setImage ] = useState(null);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [gazetten, setGazetten] = useState([])

  useEffect(() => {
    const fetchImage = async () => {
      try{
        const response = await userAPI.getProfilePic(currentUser.id);
        const fileBlob = new Blob([response], {type: "image/*"});
        let url = URL.createObjectURL(fileBlob);
         
        setImage(url)
      }catch(error){
         
      }
    }
    fetchImage()
  }, [setImage])

  const handleClickWijzigWachtoord = useCallback(async () => {
    await authAPI.requestPasswordMail({
      email: currentUser.email
    })
    setShowResetPasswordModal(true)
  });

  const fetchGazetten = async () => {
    let result = await gazetteAPI.getAll();
    
    setGazetten(result.map(gazette => { return gazette.split(".")[0]}));
  }
  
  useEffect(()=> {
    fetchGazetten();
  }, [])


  const handleCloseResetPasswordModal = useCallback(() => {
    setShowResetPasswordModal(false)
  }, [])

  const [data, setData] = useState();

  const analyticsAPI = useAnalytics();

  useEffect(()=>{
    const fetchAnalytics = async () => {
      const result = await analyticsAPI.getOverview();
      console.log(result)
      setData(result)
    };

    fetchAnalytics();
  }, [])

  const [columnWidths, setColumnWidths] = useState({ info: 4, vzw: 4, leiding: 4, redacteur: 4, admin: 4 });

  useEffect(() => {
    // Functie om de breedte van de kolommen te bepalen
    const calculateColumnWidths = () => {
      let newWidths = { info: 4, vzw: 4, leiding: 4, redacteur: 4 };

      // Controleer de rollen van de huidige gebruiker
      if (currentUser && currentUser.roles) {
        const totalRoles = currentUser.roles.length;

        // Pas de breedte van de kolommen aan op basis van de rollen
        newWidths.info = 4; // Info kolom heeft altijd breedte 4
        newWidths.vzw = totalRoles === 1 && currentUser.roles.includes('VZW') ? 8 : 4;
        newWidths.leiding = totalRoles === 1 && currentUser.roles.includes('Leiding') ? 8 : 4;
        newWidths.redacteur = totalRoles === 1 && currentUser.roles.includes('Redacteur') ? 8 : 4;
        newWidths.admin = totalRoles === 1 && currentUser.roles.includes('Admin') ? 8 : 4;
      }

      return newWidths;
    };

    // Stel de breedtes in
    setColumnWidths(calculateColumnWidths());
  }, [currentUser]);

  const deleteGazette = useCallback(async (id)=> {
    console.log(id)
    await gazetteAPI.deleteGazette({id});
    fetchGazetten()
  })

  if (token === undefined) {
    navigate('/login', { replace: true });
  }

  if (loadingUser) {
    return <p>Loading...</p>;
  }

  if (!currentUser) {
    return (
      <>
        <p>User not found.</p>
        <Logout />
      </>
    );
  }

  return <>
    <ProfileImage image={image} isAdmin={currentUser?.roles.includes("Admin")}/>
    <div className='profile-bg w-100'>
      <div className='d-flex justify-content-center'>
        <div className='profile-arch'>
        </div>
      </div>
    </div>
    <div className='profile-content mt-0 w-100 px-4'>
      <h2 className='text-center mb-0'>{currentUser.voornaam} {currentUser.familienaam}</h2>
      <div className='container mt-0'>
        <div className='row mb-4'>
          <div className='col-12 col-md-6 col-lg-4 p-2'>
          <div className='card border-0 shadow h-100'>
            <div className='container h-100'>
              <h4>Info</h4>
              <hr className='mt-0'></hr>
              <p className='mb-0'>
                <BsEnvelope className="icon me-2" /> {currentUser.email} <br />
                <BsPhone className="icon me-2" /> {currentUser.gsmnummer} <br />
                {currentUser.tak && <><BsPeople className="icon me-2" /> {currentUser.tak?.naam} <br /></> }
              </p>
              <hr></hr>
              {currentUser.roles?.length > 0 && <>
                <div className='d-flex justify-content-center align-items-center'>
                  <SlBadge className="icon me-2" />
                  <h5>Badges</h5>
                </div>
              </>}
              <div className='d-flex mt-2 ms-2 badges-container'>
                {currentUser.roles?.map(rol => {
                  const badgeImage = require(`./images/badge_${rol.toLowerCase()}.png`);
                  return <div className='p-2 d-flex justify-content-center' key={badgeImage}>
                          <img src={badgeImage} className='badge-image'/>
                        </div> 
                })}
              </div>
              <div className='d-md-none'><hr/></div>
              </div>
            </div>
          </div>
          {(currentUser?.roles.includes("Leiding")) && <>
            <div className={`col-12 col-md-6 col-lg-${columnWidths.leiding} p-2`}>
              <div className='card border-0 shadow h-100'>
                <div className='container h-100'>
                  <h4>Statistieken</h4>
                  <hr className='mt-0'></hr>
                  <Dashboard data={data} /> 
                  <hr className='mt-4'/>
                  <h5 className='text-center mb-0'>Aantal bezoekers per dag</h5>
                  <div className='d-flex justify-content-center'>
                    <BarChart width={500} height={200} data={sorteerDagen(vertaalDagen(data?.logsCountPerDay))}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="dayname" />
                      <Tooltip />
                      <Bar dataKey="count" fill="var(--primary-color)" />
                    </BarChart>
                  </div>
                </div>
              </div>
            </div>
          </>}
          {(currentUser?.roles.includes("Leiding")) && currentUser.tak && <>
          <div className={`col-12 col-md-6 col-lg-${columnWidths.leiding} p-2`}>
            <div className='card border-0 shadow h-100'>
              <div className=' container h-100'>
                <h4>Leiding</h4>
                <hr className='mt-0'></hr>
                <div className="row">
                  <div className="col-6">
                    <div className="card shadow p-2 text-center border-0">
                      <BsPeople className="dashboard-icon align-self-center" />
                      <h5>Mijn Tak</h5>
                      <Link to={`/takken/${currentUser?.tak?.naam}`} className="group-link">
                        {currentUser?.tak?.naam} <FaArrowRight />
                      </Link>
                    </div>
                  </div>
                  <div className="col-6 mt-md-0">
                    <div className="card shadow p-2 text-center border-0">
                      <BsCamera className="dashboard-icon align-self-center" />
                      <h5>Foto's</h5>
                      <Link to={`/images/${currentUser?.tak?.naam}`} className="group-link">
                        {currentUser?.tak?.naam} <FaArrowRight />
                      </Link>
                    </div>
                  </div>
                  <div className='col-12 mt-4'>
                    <h6>Brieven {showUploadBrief ? 
                      <><BsDashCircle color='red' onClick={() => setShowUploadBrief(false)}></BsDashCircle></> 
                      : <BsPlusCircle color="green" onClick={() => setShowUploadBrief(true)}/>}
                    </h6>
                    {showUploadBrief && <div className='mb-4'><MaandbriefForm /></div>}
                    <MaandbrievenLijst tak={currentUser.tak.naam}/>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </>}
          {(currentUser?.roles.includes("VZW")) && <>
            <div className={`col-12 col-md-6 col-lg-${columnWidths.vzw} p-2`}>
              <div className='card border-0 shadow h-100'>
                <div className='container h-100'>
                  <h4>VZW</h4>
                  <hr className='mt-0'></hr>
                  <PeriodeLijst></PeriodeLijst>
                </div>
              </div>
            </div>
          </>}
          {(currentUser?.roles.includes("Redacteur")) && <>
          <div className={`col-12 col-md-6 col-lg-${columnWidths.redacteur} p-2`}>
            <div className='card border-0 shadow h-100'>
              <div className='container h-100'>
                <h4>Gazette</h4>
                <hr className='mt-0'></hr>
                <GazetteForm />
                <div className='d-flex justify-content-start gazette-container'>
                  {gazetten.map((gazette, index) => {
                    return <div key={`gazette_${index}`}>
                      <GazettePreview id={gazette} height={100} deletable={true} deleteGazette={deleteGazette}/>
                    </div>
                  })}
                </div>
              </div>
            </div>
          </div>
          </>}
          {(currentUser?.roles.includes("Admin")) &&  <>
            <div className={`col-12 col-md-6 col-lg-${columnWidths.admin} p-2`}>
              <div className='card border-0 shadow h-100'>
                <div className='container h-100'>
                  <h4>Evenementen</h4>
                  <hr className='mt-0'></hr>
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg">
                      <div className="card shadow p-2 text-center border-0">
                        <BsCalendar className="dashboard-icon align-self-center" />
                        <h5 className='mb-0'>Evenementen</h5>
                        <Link to={`/evenementen`} className="mt-4 group-link">
                          Bekijk de evenementen <FaArrowRight />
                        </Link>
                      </div>
                    </div>
                    
                    <div className="col-12 col-md-6 col-lg mt-4 mt-md-0 mt-lg-0">
                      <div className="card shadow p-2 text-center border-0 h-100">
                        <BsCalendarPlus className="dashboard-icon align-self-center" />
                        <h5 className='mb-0'>Nieuw evenement</h5>
                        <Link to={`/evenementen/create`} className="mt-4 group-link">
                          Maak een evenementen <FaArrowRight />
                        </Link>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
          </>}
        </div>
        <div className='row mt-4'>
          <div className='col-12 col-md-6'>
            <button className='btn btn-sm btn-outline-primary btn-pill d-flex align-items-center justify-content-center mb-2 w-100' onClick={handleClickWijzigWachtoord}>
              <BsLock className='icon me-2'/> Wijzig wachtwoord
            </button>
          </div>
          <div className='col-12 col-md-6'>
            <Logout></Logout>
          </div>
        </div>
      </div> 
    </div>
  </>
}
