export default function Lied1(){
  return <>
    <div>
      <h5>15. Viva la vida - Coldplay</h5>
      <div className="mt-3 mb-4"></div>
      <p>I used to rule the world<br/>
Seas would rise when I gave the word<br/>
Now in the morning, I sleep alone<br/>
Sweep the streets I used to own<br/><br/>
I used to roll the dice<br/>
Feel the fear in my enemy's eyes<br/>
Listen as the crowd would sing<br/>
Now the old king is dead, long live the king<br/>
One minute, I held the key<br/>
Next the walls were closed on me<br/>
And I discovered that my castles stand<br/>
Upon pillars of salt and pillars of sand<br/><br/>
<strong>I hear Jerusalem bells a-ringin'<br/>
Roman Cavalry choirs are singin'<br/>
Be my mirror, my sword and shield<br/>
My missionaries in a foreign field<br/>
For some reason, I can't explain<br/>
Once you'd gone, there was never, never an honest word<br/>
And that was when I ruled the world</strong><br/><br/>
It was a wicked and wild wind<br/>
Blew down the doors to let me in<br/>
Shattered windows and the sound of drums<br/>
People couldn't believe what I'd become<br/>
Revolutionaries wait<br/>
For my head on a silver plate<br/>
Just a puppet on a lonely string<br/>
Aw, who would ever wanna be king?<br/><br/>
<strong>I hear Jerusalem bells a-ringin'<br/>
Roman Cavalry choirs are singing<br/>
Be my mirror, my sword and shield<br/>
My missionaries in a foreign field<br/>
For some reason, I can't explain<br/>
I know Saint Peter won't call my name<br/>
Never an honest word<br/>
But that was when I ruled the world</strong><br/><br/>
Oh-oh-oh, oh-oh, oh<br/>
Oh-oh-oh, oh-oh, oh<br/>
Oh-oh-oh, oh-oh, oh<br/>
Oh-oh-oh, oh-oh, oh<br/>
Oh-oh-oh, oh-oh, oh<br/><br/>
<strong>I hear Jerusalem bells a-ringin'<br/>
Roman Cavalry choirs are singin'<br/>
Be my mirror, my sword and shield<br/>
My missionaries in a foreign field<br/>
For some reason I can't explain<br/>
I know Saint Peter won't call my name<br/>
Never an honest word<br/>
But that was when I ruled the world</strong></p>
    </div>
  </>
}