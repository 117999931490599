export default function Lied1(){
  return <>
    <div>
      <h5>5. Brood voor Morgenvroeg - Bart Peeters</h5>
      <div className="mt-3 mb-4"></div>
      <p>Soms als ik naar huis rij<br/>
In het midden van de nacht<br/>
Kom ik langs een broodmachien en rem op volle kracht<br/>
Ik wil een ramp voorkomen<br/>
Want er is al stress genoeg<br/>
Oh wonder der techniek<br/>
Ik koop een brood<br/>
Brood voor morgenvroeg<br/><br/>
Dan hoor ik in het nachtnieuws<br/>
Op de autoradio<br/>
Kurkdroog zeggen met de wereld gaat het maar zozo<br/>
En er ligt een oceaan van woelig water voor de boeg<br/>
Dan denk ik maar gelukkig heb ik brood<br/>
Brood voor morgenvroeg<br/>
<strong>Waarom de waanzin het verstand versloeg<br/>
Ik heb geen idee maar wel een brood voor morgen<br/><br/></strong>
Het is spijtig maar planeten redden kan ik niet zo goed<br/>
Ik moet eens aan Bono vragen hoe je zoiets doet<br/>
En waarom de waanzin het gezond verstand versloeg<br/>
Maar das een zorg voor later<br/>
We hebben brood<br/>
Brood voor morgenvroeg<br/><br/>
<strong>
Waarom de waanzin het verstand versloeg<br/>
Ik heb geen idee maar wel een brood voor morgen<br/>
Waarom de waanzin het verstand versloeg<br/>
Ik heb geen idee maar wel een brood<br/>
Brood voor morgenvroeg<br/><br/></strong>
Stel de planeet verpulvert<br/>
Tot een zielig hoopje gruis<br/>
Dan zeg ik dat is spijtig<br/>
Maar ik heb wel een brood in huis<br/>
En stel dat ik zou weten wat de schadeclaim bedroeg<br/>
Dan zeg ik dat kan zijn<br/>
Maar we hebben brood<br/>
Brood voor morgenvroeg<br/><br/>
<strong>
Waarom de waanzin het verstand versloeg<br/>
Ik heb geen idee maar wel een brood voor morgen<br/>
Waarom de waanzin het verstand versloeg<br/>
Ik heb geen idee maar wel een brood<br/>
Brood voor morgenvroeg</strong></p>
    </div>
  </>
}