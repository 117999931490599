import React from 'react';
import { Link } from 'react-router-dom';
import './InfoPage.css';

import inschrijvingenImage from './images/inschrijvingen.jpeg';
import scoutingOpMaatImage from './images/scouting_op_maat.jpg';
import verzekeringImage from './images/verzekering.jpg';
import privacybeleidImage from './images/privacy.jpeg';
import kasactiesImage from './images/kasacties.png';
import scoutsEnGidsenImage from './images/senv.jpg';

const InfoPage = () => {
  const infoItems = [
    {
      title: 'Inschrijvingen en algemene informatie',
      description: 'Wil je extra informatie over onze werking? Wil je lid worden van de scouts? Ontdek hier het antwoord op al jouw vragen.',
      image: inschrijvingenImage,
      link: 'inschrijvingen'
    },
    {
      title: 'Scouting op maat',
      description: 'Scouting op maat is er voor iedereen die aangeeft het financieel moeilijk te hebben door eender welke omstandigheden. Lees hier hoe wij en Scouts en Gidsen Vlaanderen jou kunnen helpen.',
      image: scoutingOpMaatImage,
      link: 'scouting-op-maat'
    },
    {
      title: 'Verzekering',
      description: 'Bij al dat ravotten, kan een ongeluk al eens voorvallen. Uiteraard proberen we dit te voorkomen, maar indien dit toch gebeurt, geen paniek. Je bent verzekerd.',
      image: verzekeringImage,
      link: 'verzekering'
    },
    {
      title: 'Privacybeleid VZW',
      description: 'Lees hier het privacybeleid van de VZW.',
      image: privacybeleidImage,
      link: 'privacybeleid'
    },
    {
      title: 'Kasacties',
      description: 'Doorheen het jaar houden we een aantal acties om onze kas te spijzen. Van wafels verkopen tot onze eigen scoutsfuif...',
      image: kasactiesImage,
      link: 'kasacties'
    },
    {
      title: 'Scouts en Gidsen Vlaanderen',
      description: 'Scouts en Gidsen Vlaanderen is de overkoepelende organisatie boven alle scoutsen van Vlaanderen. Ook onze scouts is aangesloten bij Scouts en Gidsen Vlaanderen.',
      image: scoutsEnGidsenImage,
      link: 'scouts-en-gidsen-vlaanderen'
    }
  ];

  return (
    <div className="container mb-5 py-4">
      <h1>Informatie</h1>
      <div className="row">
        {infoItems.map((item, index) => (
          <div className='col-12 col-lg-6 p-2 fade-in' key={index}>
            <div className="info-item h-100" >
              <div className="h-100">
                <img className='info-image h-100 shadow' src={item.image} alt={item.title} />
              </div>
              <div className="info-content">
                <h2 className="info-heading">{item.title}</h2>
                <p className="info-description">{item.description}</p>
                <Link to={item.link} replace={true} className="info-link">Meer informatie</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoPage;