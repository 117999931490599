import { useEffect, useState } from "react"
import useUser from "../../api/user";
import { useParams } from "react-router";

export default function User(){

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const {userid} = useParams()

  const userAPI = useUser();

  useEffect(() => {
    console.log(userid)
    const fetchUser = async () => {
      const response = await userAPI.getById(userid)
      console.log(response)
      setUser(response);
    }
    fetchUser();
  }, [])
  return <>
    <div className="container">
      <h2>{user.voornaam} {user.familienaam}</h2>
    </div>
  </>
}