import Dropzone from "react-dropzone";
import { useCallback, useState } from "react";
import { BsFiletypePdf } from "react-icons/bs";
import { useAuthProvider } from "../../contexts/AuthProvider";
import useTak from "../../api/tak";
import ConfirmationModal from "../modal/ConfirmModal";
import "./Maandbrief.css"


export default function MaandbriefForm(){

  const { currentUser } = useAuthProvider();

  const takkenAPI = useTak();


  const [ file, setFile ] = useState(undefined)


  const handleDrop = useCallback(async (file) => {
    setFile(file);
     
  })

  const submitForm = useCallback(async () => {
    try {
       
      let formData = new FormData();
      file.forEach((file, index) => {
         
        formData.append(`maandbrief`, file, file.name);
      });
       
      
      const response = await takkenAPI.uploadMaandbrief({taknaam: currentUser.tak.naam, formData})

       
      setShowMaandbriefModal(true)
    } catch (error) {
      
    }
  },[file])

  const [showMaandbriefModal, setShowMaandbriefModal] = useState(false);

  const handleCloseMaandbriefModal = () => {
    setShowMaandbriefModal(false);
  };

  return <>

<ConfirmationModal
        show={showMaandbriefModal}
        onClose={handleCloseMaandbriefModal}
        title="Maandbrief geüpload!"
        body="Jouw maandbrief is met success geüpload."
      />
      <div className='drop-zone-container text-center h-100 d-flex flex-column align-items-center justify-content-center'>
        <BsFiletypePdf />
        <Dropzone onDrop={handleDrop} 
          multiple={false}
          accept={{
              'application/*': ['.pdf'],
              }}>
                {({ getRootProps, getInputProps }) => (
                  <div className='' {...getRootProps()}>
                  <input {...getInputProps({ name: 'maandbrief' })} name="maandbrief"/>
                    <p>Drop een nieuwe maandbrief of <span className='text-primary clickable'>zoek</span></p>
                    <p className='text-muted'><small>Ondersteunt .pdf</small></p>
                    {file && <p>[{file[0].path}]</p>}
                  </div>
                )}
        </Dropzone>

        <button onClick={async () => await submitForm()} disabled={!file} className="btn btn-primary bg-primary">Uploaden!</button>
      </div>
  </>
}