import ForbiddenComponent from "../Errors/Forbidden";

export default function Error({ error }) {
 
  if (error) {
    return (
      <div className="alert alert-danger" data-cy="transactions_error">
        <h4 className="alert-heading">Oeps! Zelfs de scouts verdwaalt soms!</h4>
        {error.message || JSON.stringify(error)}
      </div>
    );
  }

  return null;
}