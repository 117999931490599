import axios from "axios";
import { useCallback, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";
import useAuth from "../../api/auth";
import Loader from "../loader/Loader";

export default function PasswordForgotten(){

  const [queryParameters] = useSearchParams()
  const token = queryParameters.get("token")

  const [email, setEmail] = useState('');
  const [errors, setErrors ] = useState([]);
  const [mailSent, setMailSent] = useState(false)
  const [loading, setLoading] = useState(false)

  const authAPI = useAuth();

  const handleSendPassword = useCallback(async () => {
    try{
      setLoading(true)
      await authAPI.requestPasswordMail({email})
      setMailSent(true)
      setLoading(false)
    }catch(error){
      setErrors([...errors, error]);
    }
  });

  return <>

    <div className='login-body'>
      <div class="container d-flex justify-content-center align-items-center mt-0">
      {mailSent ? <>
        <div class="login-container card shadow border-0 w-md-50 p-4">
          <div className='w-100 d-flex justify-content-center mb-4'>
            <div className='login-logo align-self-center' />
          </div>
          <h4 className="mb-4">Email gestuurd!</h4>
        </div>
      </> : 
      <div class="login-container card shadow border-0 w-md-50 p-4">
        <div className='login-form'>
          <div className='w-100 d-flex justify-content-center mb-4'>
            <div className='login-logo align-self-center' />
          </div>
          <h4 className="mb-4">Geef jouw email in!</h4>
          <div class="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {loading && <Loader></Loader>}
          {errors?.length > 0 && <><p className='text-danger'>Inloggegevens zijn niet correct.</p></> }
          <button class="btn btn-primary btn-block mt-4" onClick={handleSendPassword}>Bevestig</button> 
          </div>
        </div>}
      </div>
    </div>
  </>

}