import banner from "./images/scouting_op_maat.jpg"

export default function ScoutingOpMaat(){

  return (
    <div className="container mt-0 mb-5">
      <img src={banner} className="info-banner shadow" />
      <h2>Scouting op Maat</h2>
      <p>
        De kosten van scouting zijn niet voor elk gezin even makkelijk te dragen. Daarom bestaat Scouting op Maat: een fonds dat tegemoetkomt aan de kosten van scouting. Zo zorgt Scouts en Gidsen Vlaanderen dat iedereen erbij hoort, ook als je financieel minder ademruimte hebt.
      </p>
      <h3>Scouting op Maat in jouw groep</h3>
      <p>
        Scouting op Maat bestaat voor gezinnen die het financieel niet makkelijk hebben. Dit kan voor iedereen anders zijn, dus iedereen is welkom. Denk aan mensen in schuldbemiddeling of verbonden aan het OCMW, personen met recht op verhoogde tegemoetkoming, alleenstaande ouders, alleenwonende leden, leden verbonden aan een instelling, enzovoort. Aangezien armoede niet zwart-wit is, hoef je je niet te verantwoorden hiervoor en is enig document of bewijs absoluut overbodig.
      </p>

      <h3>Lidgeld op Maat</h3>
      <p>
        Wie het financieel moeilijk heeft, betaalt €11,60 euro lidgeld aan Scouts en Gidsen Vlaanderen. Na 1 maart wordt dit 5,80 euro. Verminderd lidgeld aanvragen voor één van je leden, doe je in de Groepsadministratie. Zorg dat de persoonsgegevens in de Groepsadministratie kloppen en vink de optie ‘verminderd lidgeld’ aan.
      </p>
      <h3>Uniform op Maat</h3>
      <p>
        Leden die recht hebben op lidgeld op maat (en waarvan dit aangevinkt is in de groepsadministratie), krijgen korting in Hopper Winkel als ze hun lidnummer doorgeven. Ze krijgen 60 procent korting voor een hemd en 50 procent voor een broek, rok of trui.
      </p>
      <h3>Fonds op Maat</h3>
      <p>
        Voor weekends, kampen en andere activiteiten komt Scouts en Gidsen Vlaanderen ook tussenbeide. Het idee is dat ouders of leden een derde betalen, Scouts en Gidsen Vlaanderen een derde en de scoutsgroep ook een derde. Vraag fonds op maat aan via dit formulier.
      </p>
    </div>
  );
}