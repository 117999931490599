import { useCallback, useEffect, useState } from "react";
import Footer from "../Footer";
import Infobox from "../infobox/Infobox";
import useEvent from "../../api/event";
import Loader from "../loader/Loader";
import EventPreview from "../event/EventPreview";
import Polaroids from "./Polaroids";
import useLogs from "../../api/logs";

export default function Home(){

  const [events, setEvents ] = useState([]);
  const [loading, setLoading ] = useState(true)
  const [loadingImages, setLoadingImages ] = useState(true)
  const [eventafbeeldingen, setEventafbeeldingen ] = useState([])

  const eventAPI = useEvent()

  useEffect(() => {
    const fetchEvents = async () =>{
      try{
        const result = await eventAPI.getAll({limit: 3, upComing: true});
        setEvents(result)
        setLoading(false)
      }catch(error){
         
      }
    };

    fetchEvents()
  }, [loading]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {

        const afbeeldingen = {};
        for (let i = 0; i < events.length ; i++){
          const result = await eventAPI.getBannerById({id: events[i].id})
          const id = events[i].id;
           
          const fileBlob = new Blob([result], {type: "image/*"});
          let url = URL.createObjectURL(fileBlob);
          afbeeldingen[id] = url
        }
        setEventafbeeldingen(afbeeldingen)
        setLoadingImages(false)
      } catch (error) {
         
      }
    };
    fetchFiles()
  }, [loading, loadingImages]);

  const logsAPI = useLogs()
  const [viewed, setViewed] = useState(false);

  useEffect(() => {
    const setView = async () => {
      if(!viewed){
        await logsAPI.createLog({tag: "view"})
      }
      setViewed(true)
    }
    setView();
  },[])

  return <>
    <div className="home-hero">
      <div className="hero-bg"></div>
      <div className="hero-logo"></div>
      <div className="hero-text">
        <div className="hero-title text-white ps-4">
          <h1>WELKOM BIJ SCOUTS WONDELGEM</h1>
        </div>
        <div className="hero-details">
          <p>Scouts Wondelgem - Brigands de Kleine Prins</p>
        </div>
      </div>
    </div>

    <div className="bg-primary ">
      <div className="divider"></div>

      <div className="container mt-0 py-4">
        <h2 className="text-center text-white">Evenementen</h2>
        {loadingImages ? <Loader></Loader> : 
        <div className="row justify-content-center">
          {events && events.length > 0 ? <>{events.map(event => {
            return <EventPreview key={event.id} event={event} afbeelding={eventafbeeldingen[event.id]} />
          })}</>
          : 
          <><p className="text-center text-white">Er zijn momenteel geen aankomende evenementen.</p></>}
        </div>
        }
      </div>

      <div className="divider mt-4"></div>
    </div>

    <Polaroids />

    <div className="bg-primary ">
      <div className="divider"></div>

      <div className="container mt-0 py-4 text-center text-white">
        <h2 className="text-center">Inschrijven</h2>
        <p>Klaar voor nieuwe vrienden en avontuur? Bij onze scouts beleef je het allemaal. Word lid en kijk wat de scouts voor jou kunnen betekenen!*</p>
        <a href="inschrijven"  className="no-link btn btn-lg btn-yellow text-center text-white my-4">Meer info</a>
        <p><small>*Gratis vriendschap inbegrepen! Avonturen gegarandeerd, muggenbeten optioneel!</small></p>
      </div>
      <div className="divider mt-4"></div>
    </div>
    
    <Infobox />

    <Footer></Footer>
  </>
}