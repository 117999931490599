export default function Lied1(){
  return <>
    <div>
      <h5>12. Het is een nacht - G. Meeuwis</h5>
      <div className="mt-3 mb-4"></div>
      <p>Je vraagt of ik zin heb in een sigaret<br/>
't is 2 uur 's nachts we liggen op bed<br/>
in een hotel in een stad<br/>
waar niemand ons hoort<br/>
waar niemand ons kent<br/>
en niemand ons stoort<br/>
Op de vloer ligt een lege fles wijn<br/>
en kledingstukken die van jou of mij kunnen zijn<br/><br/>

Een schemering<br/>
de radio zacht<br/>
en deze nacht heeft alles<br/>
wat ik van een nacht verwacht<br/><br/>
<strong>
Het is een nacht<br/>
die je normaal alleen in films ziet<br/>
het is een nacht<br/>
die wordt bezongen in het mooiste lied<br/>
het is een nacht<br/>
waarvan ik dacht dat ik 'm nooit beleven zou<br/>
maar vannacht beleef ik 'm<br/>
met jou, oh<br/><br/></strong>

ik ben nog wakker en ik staar naar het plafond<br/>
en ik denk aan hoe de dag lang geleden begon<br/>
en zomaar er vandoor gaan met jou<br/>
niet wetend waar de reis eindigen zou nu lig ik hier in een wild vreemde stad<br/>
en heb net de nacht van mijn leven gehad<br/>
maar helaas er kom weer licht door de ramen<br/>
hoewel voor ons de wereld vannacht heeft stil gestaan<br/><br/>

<strong>
Het is een nacht<br/>
die je normaal alleen in films ziet<br/>
het is een nacht<br/>
die wordt bezongen in het mooiste lied<br/>
het is een nacht<br/>
waarvan ik dacht dat ik 'm nooit beleven zou<br/>
maar vannacht beleef ik 'm<br/>
met jou, oh<br/><br/></strong>

maar lied blijft slechts bij woorden<br/>
een film is in scene gezet<br/>
maar deze nacht met jou is levensecht<br/><br/>

<strong>
Het is een nacht<br/>
die je normaal alleen in films ziet<br/>
het is een nacht<br/>
die wordt bezongen in het mooiste lied<br/>
het is een nacht<br/>
waarvan ik dacht dat ik 'm nooit beleven zou<br/>
maar vannacht beleef ik 'm<br/>
met jou, oh<br/><br/></strong>

en vannacht beleef ik 'm met jou<br/>
en ik hou alleen nog maar van jou ohoho<br/>
en ik hou alleen nog maar van jou</p>
    </div>
  </>
}