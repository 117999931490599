import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { BsEye, BsPerson, BsTrash } from "react-icons/bs";
import { Link } from "react-router-dom";
import { usersUrl } from "../../helpers/apiUrls";
import DeleteModal from "../modal/DeleteModal";
import "./table.css"

function UserRow({
  user,
  verwijderGebruiker
}){
  
  const [photo, setPhoto] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowModal = () => {
    setShowDeleteModal(true)
  }

  const handleVerwijderGebruiker = useCallback(() => {
    verwijderGebruiker({id: user.id})
  }, [verwijderGebruiker]);

  useEffect(() => {
    console.log(user)
    const fetchImage = async () => {
      try{
        const response = await axios.get(`${usersUrl}/${user.id}/images/`, { responseType: 'blob' });
        const fileBlob = new Blob([response.data], { type: 'image/png' });
        const url = URL.createObjectURL(fileBlob);
        setPhoto(url)
      }catch(error){
        setPhoto(null)
      }
    }
    fetchImage()
  }, [user.id])

  return <>
    <tr key={user.id}>
      <td className="d-flex w-100 justify-content-center">
        {photo?
          <img className="rounded-circle profile-image-sm" src={photo}/>
          :
          <div className='profile-image-sm rounded-circle d-flex justify-content-center align-items-center bg-primary'>
            <BsPerson size={20} color="white"></BsPerson>
          </div>
        }
      </td>
      <td>{user.voornaam}</td>
      <td>{user.familienaam}</td>
      <td className='d-none d-lg-table-cell'>{user.email}</td>
      <td><Link to={`${user.id}/update`} replace={false}><BsEye className="clickable"></BsEye></Link></td>
      <td><BsTrash color="red" className="clickable"  onClick={handleShowModal}/></td>
    </tr>
    <DeleteModal 
      naam={user.voornaam}
      type="gebruiker"
      show={showDeleteModal}
      onClose={handleCloseDeleteModal}
      verwijderFunctie={handleVerwijderGebruiker}
    />
  </>
}

export default function UserTable({
  users,
  verwijderGebruiker
}) {

  return <>
  <div className="container user-table">
    <table className="table table-striped">
      <thead>
        <tr>
          <th></th>
          <th>Voornaam</th>
          <th>Familienaam</th>
          <th className='d-none d-lg-table-cell'>Email</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return <>
            <UserRow user={user} verwijderGebruiker={verwijderGebruiker}></UserRow>
          </>
        })}
      </tbody>
    </table>
    </div>
  </>
}