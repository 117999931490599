export default function Lied1(){
  return <>
    <div>
      <h5>9. 't Vliegerke - W. De Buck</h5>
      <div className="mt-3 mb-4"></div>
      <p>
      Ik ben nie al te zot van 't spel<br/>
Maar 'k vange gere musschen<br/>
Marblen en toppen kan ik wel<br/>
Maar daarin ben ik nie fel<br/>
'K zie tegenwoordig overal<br/>
En ook al in mijn straatje<br/>
Jongens schuppen op nen bal<br/>
Maar 'k spele 't liefst van al<br/>
Mee mijne vlieger<br/>
En zijne steert<br/>
Hij goot omhoge<br/>
'T es 't ziene weert<br/>
'K geve maar klauwe<br/>
Op mijn gemak<br/>
'K heb nog drei bollekens<br/>
In mijne zak<br/><br/>
Mietje van de koolmarchant<br/>
Een meiske uit mijn straatje<br/>
Keurde mijne cervolant<br/>
En z' had er 't handje van<br/>
Want zo rap alsof de wind<br/>
Was z' aan 't spele mee mijn klauwe<br/>
En ze riep 't es 't spele weert<br/>
Want hij hee ne goeie steert<br/>
Ja mijne vlieger<br/>
En zijne steert<br/>
Hij goot omhoge<br/>
'T es 't ziene weert<br/>
'K geve maar klauwe<br/>
Op mijn gemak<br/>
'K heb nog twee bollekens<br/>
In mijne zak<br/><br/>
'T seef liet zijne vlieger op<br/>
Van 't soepe 't soepe 't soepe<br/>
Maar hij stuikt op zijne kop<br/>
En muile dat hij trok<br/>
Zijn spankoorde was veel te kort<br/>
En met zijn 't sietse klauwe<br/>
En daarbij was zijne steert<br/>
Geen chique toebak weert<br/>
Maar mijne vlieger<br/>
Me zijne steert<br/>
Hij goot omhoge<br/>
'T es 't ziene weert<br/>
'K geve maar klauwe<br/>
Op mijn gemak<br/>
'K heb nog een bolleke<br/>
In mijne zak<br/><br/>
Laatst op het sint-denijsplein<br/>
Mijne vlieger was aan 't zweve<br/>
D' er kwam een wijf een groot venijn<br/>
En ze zei 'dat mag niet zijn'<br/>
Hij hangt te veel in mijne weg<br/>
Ze begost er an te sleure<br/>
En op een twee drei pardaf<br/>
De koorde schoot er af<br/>
Hij was goon vliegen<br/>
Al mee de wind<br/>
'K stonde te schrieme<br/>
'K was maar een kind<br/>
Mijne bol klauwe<br/>
Die ging ne gang<br/>
Dat zal 'k omtauwe<br/>
Mijn leven lank
      </p>
    </div>
  </>
}