export default function Lied1(){
  return <>
    <div>
      <h6>20. Laat de zon in je hart - W.Sommers</h6>
      <div className="mt-3 mb-4"></div>
      <p>Een lach een groet een blij gezicht<br/>
Een vogel zwevend naar het licht<br/>
Oh het lijkt zo gewoon maar het is toch een wonder<br/>
Een kind dat lacht en naar je zwaait<br/>
Een fietser die de hoek omdraait<br/>
Oh het lijkt zo gewoon maar het is toch een wonder<br/>
Het leven gaat zo snel voorbij<br/>
Dat geldt voor jou maar ook voor mij<br/><br/>
<strong>
Oh laat de zon in je hart<br/>
Ze schijnt toch voor iedereen<br/>
Geniet van het leven<br/>
Want het duurt toch maar even<br/><br/>
Oh laat de zon in je hart<br/>
Ze schijnt toch voor iedereen<br/>
Geniet van het leven<br/>
Want het duurt toch maar even<br/><br/></strong>
Het strand de zee de volle maan<br/>
De hemel waar de sterren staan<br/>
Oh het lijkt zo gewoon maar het is toch een wonder<br/>
En ben je soms niet goed gezind<br/>
Denk aan de glimlach van een kind<br/>
Ja dat maakt je weer blij ja dat maakt je weer vrolijk<br/>
Het leven gaat zo snel voorbij<br/>
Dat geldt voor jou maar ook voor mij<br/><br/>
<strong>
Oh laat de zon in je hart<br/>
Ze schijnt toch voor iedereen<br/>
Geniet van het leven<br/>
Want het duurt toch maar even<br/>
Oh laat de zon in je hart<br/>
Ze schijnt toch voor iedereen<br/>
Geniet van het leven<br/>
Want het duurt toch maar even<br/><br/>
</strong>
Ben je niet gelukkig heb je soms verdriet<br/>
Denk dan aan de zon en zing dit lied<br/><br/>
<strong>Oh laat de zon in je hart<br/>
Ze schijnt toch voor iedereen<br/>
Geniet van het leven<br/>
Want het duurt toch maar even<br/>
Oh laat de zon in je hart<br/>
Ze schijnt toch voor iedereen<br/>
Geniet van het leven<br/>
Want het duurt toch maar even</strong></p>
    </div>
  </>
}