import { useEffect, useState } from "react";
import TakPreview from "./TakPreview";
import axios from "axios";
import Loader from "../loader/Loader";
import useTak from "../../api/tak";
import Footer from "../Footer";
import { takkenUrl } from "../../helpers/apiUrls";
import "./TakkenList.css";

export default function TakkenList() {
  const [takken, setTakken] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingImages, setLoadingImages] = useState(true);
  const takkenAPI = useTak();

  useEffect(() => {
    const fetchTakken = async () => {
      const data = await takkenAPI.getAll();
      setTakken(data);
      setLoading(false);
    };
    fetchTakken();
  }, [loading]);

  return (
      <div className="container  py-4">
        {loading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : (
          <div className="row">
            <h3>Onze takken</h3>
            {takken?.map((tak) => {
              return <TakPreview key={tak.id} tak={tak} className="tak-preview" />
            })}
          </div>
        )}
      </div>
  );
}
