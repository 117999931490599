export default function Lied1(){
  return <>
    <div>
      <h5>18. Mia - Gorki</h5>
      <div className="mt-3 mb-4"></div>
      <p>Toen ik honger had<br/>
Kwam ik naar je toe<br/>
Je zei: Eten kan als je de afwas doet<br/>
Mensen als jij moeten niet moeilijk doen<br/>
Geef ze een kans voor ze stom gaan doen<br/><br/>
De middenstand regeert het land<br/>
Beter dan ooit tevoren<br/>
Mia heeft het licht gezien<br/>
Ze zegt: Niemand gaat verloren<br/><br/>
Voorlopig gaan we nog even door<br/>
Op het lichtend pad, het verkeerde spoor<br/>
Mensen als ik vind je overal<br/>
Op de arbeidsmarkt, in dit tranendal<br/><br/>
Sterren komen, sterren gaan<br/>
Alleen Elvis blijft bestaan<br/>
Mia heeft nooit afgezien<br/>
Ze vraagt: Kun jij nog dromen?<br/><br/>
De middenstand regeert het land<br/>
Beter dan ooit tevoren<br/>
Mia heeft het licht gezien<br/>
Ze zegt: Niemand gaat verloren<br/><br/>
Sterren komen, sterren gaan<br/>
Alleen Elvis blijft bestaan<br/>
Mia heeft nooit afgezien<br/>
Ze vraagt: Kun jij nog dromen?</p>
    </div>
  </>
}