import axios from "axios";
import { useCallback } from "react";
import { imageUrl } from "../helpers/apiUrls";
import { useAuthProvider } from "../contexts/AuthProvider";


const useImages = () => {

	const {
		token
	} = useAuthProvider();

	const getFolders = useCallback(async ({
		takid,
	}) => {
		const url = takid ? `${imageUrl}/${takid}` : `${imageUrl}`;
		const {
			data,
		} = await axios.get(url);
		return data.folders;
	});

	const getImages = useCallback(async ({
		takid,
		mapid
	}) => {
		const url = `${imageUrl}/${takid}/${mapid}`;
		const {
			data,
		} = await axios.get(url);
		return data.images;
	});

	const uploadImages = useCallback(async ({takid, mapid, formData}) => {
		return await axios.post(
			`${imageUrl}/${takid}/${mapid}`,
			formData, {
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "multipart/form-data" 
				},
			});
	});

	const getImage = useCallback(async ({
		takid,
		mapid,
		fileName
	}) => {
		const url = `${imageUrl}/${takid}/${mapid}/${fileName}`;
		const {
			data,
		} = await axios.get(url, { responseType: "blob" });
		return data;
	});

	const uploadFolder = useCallback(async ({
		takid,
		nieuweMap
	}) => {
		const url = takid ? `${imageUrl}/${takid}` : `${imageUrl}`;
		const result = await axios({
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			url,
			data: {
				takid,
				nieuweMap
			}
		});
		return result.data.folders;
	}, [token]);

	return {
		getFolders,
		getImages,
		uploadImages,
		getImage,
		uploadFolder
	};
};

export default useImages;