export default function Lied11(){
  return <>
    <div>
      <h5>11. Opzij opzij opzij - H. Van Veen</h5>
      <div className="mt-3 mb-4"></div>
      <p>Opzij, opzij, opzij,<br/>
maak plaats, maak plaats, maak plaats,<br/>
wij hebben ongelofelijke haast.<br/>
Opzij, opzij, opzij,<br/>
want wij zijn haast te laat,<br/>
wij hebben maar een paar minuten tijd.<br/>
<strong>We moeten rennen, springen, vliegen, duiken, vallen, opstaan en weer doorgaan.<br/>
We kunnen nu niet langer, we kunnen nu niet langer blijven staan.</strong><br/><br/>
Een andere keer misschien<br/>
dan blijven we wel slapen<br/>
en kunnen dan misschien als het echt moet,<br/>
wat over koetjes, voetbal en de lotto praten,<br/>
nou dag tot ziens, adieu het gaat je goed.<br/><br/>
<strong>We moeten rennen, springen, vliegen, duiken, vallen, opstaan en weer doorgaan.<br/>
We kunnen nu niet langer, we kunnen nu niet langer blijven staan</strong>.<br/>

Opzij, opzij, opzij,<br/>
maak plaats, maak plaats, maak plaats,<br/>
wij hebben ongelofelijke haast.<br/>
Opzij, opzij, opzij,<br/>
want wij zijn haast te laat,<br/>
wij hebben maar een paar minuten tijd.<br/>
<strong>We moeten rennen, springen, vliegen, duiken, vallen, opstaan en weer doorgaan.<br/>
We kunnen nu niet langer, we kunnen nu niet langer blijven staan.</strong><br/><br/>
Een andere keer misschien<br/>
dan blijven we wel slapen<br/>
en kunnen dan misschien als het echt moet,<br/>
wat over koetjes, voetbal en de lotto praten,<br/>
nou dag tot ziens, adieu het gaat je goed.<br/><br/>
<strong>We moeten rennen, springen, vliegen, duiken, vallen, opstaan en weer doorgaan.<br/>
We kunnen nu niet langer, we kunnen nu niet langer blijven staan</strong>.<br/>
EEN ANDERE KEER MISSCHIEN!!!</p>
    </div>
  </>
}