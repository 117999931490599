import { useCallback, useState } from "react";
import UploadComponentMultiple from "../upload/FileUploadMultiple";
import useImages from "../../api/images";
import Loader from "../loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthProvider } from "../../contexts/AuthProvider";
import { imageUrl } from "../../helpers/apiUrls";
import ProgressBar from "../loader/ProgressBar";

export default function UploadImages() {

  const [ loading, setLoading ] = useState(false);
  const [ files, setFiles ] = useState([])
  const { takid, mapid } = useParams();

  const imageAPI = useImages();
  const navigate = useNavigate();
  const {
		token
	} = useAuthProvider();

  const handleChangeFiles = useCallback((files) => {
    setFiles(files);
    setLoading(setLoading(true))
  });

  const [progress, setProgress] = useState(0);

  const handleSaveImages = useCallback(async () => {

    if(files){
      setLoading(true)
      let formData = new FormData();
      files.forEach((file, index) => {
         
        formData.append(`${index}`, file, file.name);
      });
      try{
      const result = await axios.post(
        `${imageUrl}/${takid}/${mapid}`,
        formData, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "multipart/form-data" 
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // Update de UI met deze voortgangsinformatie
            setProgress(percentCompleted)
          }
        });
      }catch(error){
        console.log(error)
      }
      setLoading(false)
      navigate(`/images/${takid}/${mapid}`, {replace: true})
    }
  },[files]);

  return <>
    <div className="container">
      <UploadComponentMultiple onSaveAfbeelding={handleChangeFiles}></UploadComponentMultiple>
      <button className="btn btn-primary mt-4 w-100" disabled={loading} onClick={handleSaveImages}>Uploaden</button>
      {loading && <>
        {progress < 100 ? 
          <>
            <div className="text-center">
              <h4>Bezig met uploaden...</h4>
              <ProgressBar percentage={progress} />
            </div>
          </> 
          : 
          <>
            <div className="text-center">
              <h4>Bezig met verwerken...</h4>
              <Loader></Loader>
            </div>
          </>
        }</>}
      
    </div>
  </>
}