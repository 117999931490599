import axios from "axios";
import { useCallback, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAuth from "../../api/auth";

export default function RequestWachtwoord(){

  const baseUrl = `${process.env.REACT_APP_API_URL}/auth`;

  const [queryParameters] = useSearchParams()
  const token = queryParameters.get("token")

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [ errors, setErrors ] = useState([])

  const authAPI = useAuth();
  const navigate = useNavigate();

  const handleSendPassword = useCallback(async () => {
    try{
      setErrors([]);
      await authAPI.createNewPassword({token, password, passwordRepeat})
      navigate("/auth", {replace: true})
    }catch(error){
      setErrors([...errors, error])
    }
  });

  return <>

    <div className='login-body'>
    <div class="container d-flex justify-content-center align-items-center mt-0">
    <div class="login-container card shadow border-0 w-md-50 p-4">
      <div className='login-form'>
        <div className='w-100 d-flex justify-content-center mb-4'>
          <div className='login-logo align-self-center' />
        </div>
        <h4 className="mb-4">Kies een wachtwoord</h4>
        <div class="form-group">
          <label htmlFor="email">Wachtwoord</label>
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="Wachtwoord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div class="form-group mt-4">
          <label htmlFor="password">Herhaal wachtwoord</label>
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder="Herhaal wachtwoord"
            value={passwordRepeat}
            onChange={(e) => setPasswordRepeat(e.target.value)}
          />
        </div>
        {errors?.length > 0 && <><p className='text-danger'>Wachtwoorden komen niet overeen.</p></> }
        <button class="btn btn-primary btn-block mt-4" onClick={handleSendPassword}>Bevestig</button> 
      </div>
    </div>
  </div>
  </div>
  </>

}