import { useCallback } from "react";
import { verhuurUrl } from "../helpers/apiUrls";
import axios from "axios";

const useVerhuur = () => {

	const getPeriodes = useCallback(async () => {
		const response = await axios({
			method: "GET",
			url: `${verhuurUrl}/aanvragen/periodes`
		});
		return response.data;
	}, []);

	const aanvraagIndienen = useCallback(async ({
		voornaam,
		familienaam,
		email,
		gsmnummer,
		groep,
		straat,
		huisnummer,
		postcode,
		gemeente,
		periode,
		akkoordContracten,
		akkoordLeeftijd
	}) => {

		await axios({
			method: "POST",
			url: `${verhuurUrl}/aanvragen`,
			data: {
				voornaam,
				familienaam,
				email,
				gsmnummer,
				groep,
				straat,
				huisnummer,
				postcode,
				gemeente,
				periode,
				akkoordLeeftijd,
				akkoordContracten
			}
		});
    
	}, []);

	const getBeschikbaarheden = useCallback(async () => {
		const result = await axios({
			method: "GET",
			url: `${verhuurUrl}/beschikbaarheden`
		});
		return result.data;
	}, []);

	const updatePeriode = useCallback(async (id, {
		start, eind, status
	}) => {
		await axios({
			method: "PUT",
			url: `${verhuurUrl}/aanvragen/periodes/${id}`,
			data:{
				start,
				eind,
				status
			}
		});
	}, []);

	const verwijderPeriode = useCallback(async (id) => {
		await axios({
			method: "DELETE",
			url: `${verhuurUrl}/aanvragen/periodes/${id}`
		});
	}, []);

	const createPeriode = useCallback(async ({start, eind})=> {
		await axios({
			method: "POST",
			url: `${verhuurUrl}/aanvragen/periodes`,
			data: {
				start,
				eind
			}
		});
	}, []);

	const getDocument = useCallback(async (bestand) => {
		const result = await axios({
			method: "GET",
			url: `${verhuurUrl}/documenten/${bestand}`,
			responseType: "blob"
		});
		return result.data;
	}, []);


	return {
		getPeriodes,
		aanvraagIndienen,
		getBeschikbaarheden,
		updatePeriode,
		verwijderPeriode,
		createPeriode,
		getDocument
	};
};

export default useVerhuur;