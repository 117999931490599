import React, { useState } from "react";
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isWeekend,
  isWithinInterval,
  parseISO,
  addMonths,
  subMonths,
  startOfWeek,
  format,
  addDays,
} from "date-fns";
import { capitalizeFirstLetter } from "../../helpers/capitalize";
import './Kalender.css'

const Kalender = ({ availablePeriods }) => {

  const [currentDate, setCurrentDate] = useState(new Date());

  const firstDayOfMonth = startOfMonth(currentDate);
  const lastDayOfMonth = endOfMonth(currentDate);
  const startOfWeekDate = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 }); // Start op een maandag (1)
  const daysInMonth = eachDayOfInterval({ start: startOfWeekDate, end: lastDayOfMonth });

  const isDayAvailable = (date) => {
    return availablePeriods.some((period) =>
      isWithinInterval(date, {
        start: parseISO(period.start),
        end: parseISO(period.eind),
      }, { inclusivity: 'both' }) && period.status === "Beschikbaar"
    );
  };
  
  const isDayRequested = (date) => {
    return availablePeriods.some((period) =>
      isWithinInterval(date, {
        start: parseISO(period.start),
        end: parseISO(period.eind),
      }, { inclusivity: 'both' }) && period.status === "Aangevraagd"
    );
  };


  const isDayRented = (date) => {
    return availablePeriods.some((period) =>
      isWithinInterval(date, {
        start: parseISO(period.start),
        end: parseISO(period.eind),
      }, { inclusivity: 'both' }) && period.status === "Verhuurd"
    );
  };

  const isUnusedWeekend = (date) => {
    return isWeekend(date) && !isDayRequested(date) && !isDayAvailable(date) && !isDayRented(date);
  };

  const goToNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const goToPreviousMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const chunkArray = (arr, chunkSize) => {
    const chunked = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunked.push(arr.slice(i, i + chunkSize));
    }
    return chunked;
  };

  const weeks = chunkArray(daysInMonth, 7);

  // Vul de laatste rij aan met dagen van de volgende maand, zodat de kalender zichtbaar is tot en met zondag
  if (weeks.length > 0) {
    let lastWeek = weeks[weeks.length - 1];
    let lastDate = lastWeek[lastWeek.length - 1];

    while (lastWeek.length < 7) {
      const nextDay = addDays(lastDate, 1);
      lastWeek.push(nextDay);
      lastDate = nextDay;
    }
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-between">
        <button className="btn btn-primary" onClick={goToPreviousMonth}>
          &lt;
        </button>
        <h4>{capitalizeFirstLetter(currentDate.toLocaleString("default", { month: "long", year: "numeric" }))}</h4>
        <button className="btn btn-primary" onClick={goToNextMonth}>
          &gt;
        </button>
      </div>
      <div className="row monthly-calendar mt-2 fw-bold">
        <div className="col border calendar-day text-center">Ma</div>
        <div className="col border calendar-day text-center">Di</div>
        <div className="col border calendar-day text-center">Wo</div>
        <div className="col border calendar-day text-center">Do</div>
        <div className="col border calendar-day text-center">Vr</div>
        <div className="col border calendar-day text-center bg-light">Za</div>
        <div className="col border calendar-day text-center bg-light">Zo</div>
        </div>

        {weeks.map((week, weekIndex) => (
          <div className="row" key={weekIndex}>
            {week.map((date) => (
              <div
              key={date.toISOString()}
              className={`col calendar-day text-end border ${
                isDayRented(date) ? "bg-danger" : isDayAvailable(date) ? "bg-success" : isUnusedWeekend(date) ? "bg-light" : isDayRequested(date) ? "bg-warning" : "bg-white"
              }`}
            >
              {format(date, "dd")}
            </div>
            ))}
          </div>
        ))}

        <div className="legend mt-2 row text-center">
        <div className="col-6 d-flex align-items-center">
          <div className="bg-light me-2 border" style={{width: "10px", height: "10px"}}></div><small>Weekend</small>
        </div>
        <div className="col-6 d-flex align-items-center">
          <div className="bg-success me-2" style={{width: "10px", height: "10px"}}></div><small>Beschikbaar</small>
        </div>
        <div className="col-6 d-flex align-items-center">
          <div className="bg-warning me-2" style={{width: "10px", height: "10px"}}></div><small>Reeds aangevraagd</small>
        </div>
        <div className="col-6 d-flex align-items-center">
          <div className="bg-danger me-2" style={{width: "10px", height: "10px"}}></div><small>Verhuurd</small>
        </div>
      </div>
    </div>
  );
};

export default Kalender;