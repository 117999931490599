import { useCallback, useEffect, useState } from "react"
import useTak from "../../api/tak"
import { BsClipboard, BsTrash } from "react-icons/bs";
import DeleteModal from "../modal/DeleteModal";
import axios from "axios";
import { takkenUrl } from "../../helpers/apiUrls";

function MaandbriefRij({taknaam, brief, handleDelete}) {
  const [ toonDeleteModal, setToonDeleteModal ] = useState(false);
  const [ showAlert, setShowAlert ] = useState(false)

  const deleteMaandbrief = useCallback(() => {
    handleDelete(brief)
  })

  const handleHideModal = () => {
    setToonDeleteModal(false)
  }

  const handleShowModal = () => {
    setToonDeleteModal(true)
  }

  const handleCopyLink = useCallback(async()=> {
    const result = await axios.get(`${takkenUrl}/${taknaam}/maandbrieven/${brief}`, {
      responseType: "blob"
    })
    const fileBlob = new Blob([result.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(fileBlob);
    navigator.clipboard.writeText(fileURL)
    setShowAlert(true)
  })

  return <>

{ showAlert && 
    <div 
      className="alert m-4 alert-warning alert-dismissible fixed-bottom d-flex justify-content-between fade show "
      role="alert">
        <p className="m-0">Link gekopieërd!</p>
        <button className="btn m-0" onClick={() => setShowAlert(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
}

    <DeleteModal
      naam={brief}
      type="maandbrief"
      show={toonDeleteModal}
      onClose={handleHideModal}
      verwijderFunctie={deleteMaandbrief}
    />
    <tr className="overflow-hidden">
      <td className="overflow-hidden">{brief}</td>
      {/* <td><BsClipboard color="green" className="clickable" onClick={handleCopyLink}></BsClipboard></td> */ }
      <td><BsTrash color="red" className="clickable" onClick={handleShowModal}></BsTrash></td>
    </tr>
    
  </>
}

export default function MaandbrievenLijst({
  tak
}) {

  const [maandbrieven, setMaandbrieven] = useState([])
  const takkenAPI = useTak();

  useEffect(() => {
    const fetchBrieven = async () => {
      const result = await takkenAPI.getMaandbrieven({naam: tak})
      setMaandbrieven(result)
    }
    fetchBrieven()
  }, [takkenAPI])

  const deleteMaandbrief = useCallback(async (naam) => {
    await takkenAPI.deleteMaandbrief(tak, naam)
  }, [])

  return <>
  {maandbrieven?.length > 0  ? <>
    <table className="table table-striped">
      <thead className="thead-light">
        <tr>
          <th>Naam</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {maandbrieven.map(brief => {
          return  <>
            <MaandbriefRij taknaam={tak} brief={brief} handleDelete={deleteMaandbrief}></MaandbriefRij>
         
          </>
        }) }
      </tbody>
    </table>
    </>
    : <div className="alert alert-info">Er zijn nog geen brieven voor jouw tak.</div>}
  </>
}