import axios from "axios";
import { useCallback } from "react";
import { gazetteUrl } from "../helpers/apiUrls";
import { useAuthProvider } from "../contexts/AuthProvider";


const useGazette = () => {

	const {token} = useAuthProvider();


	const getAll = useCallback(async () => {
    
		const result = await axios.get(`${gazetteUrl}`);

		return result.data;
	}, []);

	const getById = useCallback(async (id) => {
		const result = await axios.get(`${gazetteUrl}/${id}`, {
			responseType: "blob"
		});
		return result;
	}, []);

	const getPreviewById = useCallback(async (id) =>{
		const result = await axios({
			method: "GET",
			url: `${gazetteUrl}/${id}/preview`,
			responseType: "blob"
		});
		return result.data;
	}, []);

	const uploadGazette= useCallback(async ({formData}) => {
		const result = await axios.post(
			`${gazetteUrl}`,
			formData, {
				headers: { 
					Authorization: `Bearer ${token}`,
					"Content-Type": "multipart/form-data" 
				},
			});
		return result;
	}, [token]);

	const deleteGazette= useCallback(async ({id}) => {
		const result = await axios.delete(
			`${gazetteUrl}/${id}`,
			{
				headers: { 
					Authorization: `Bearer ${token}`,
				},
			});
		return result;
	}, [token]);


	return {
		getAll,
		getById,
		getPreviewById,
		uploadGazette,
		deleteGazette
	};
};

export default useGazette;
