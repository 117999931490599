import { Link } from "react-router-dom"
import "./Polaroids.css"
import polaroid1 from "./polaroid-1.jpg"
import polaroid2 from "./polaroid-2.jpg"
import polaroid3 from "./polaroid-3.jpg"
import polaroid4 from "./polaroid-4.jpg"
import { BsArrowRight } from "react-icons/bs"


export default function Polaroids(){
  return (
    <div className="container">
      <h2 className="text-center">Polaroids</h2>
      <div className="polaroid-container">
        <ul className="polaroid-ul">
          <li className="polaroid-item rotate-10 shadow">
            <img src={polaroid1} className="polaroid-picture" alt="Polaroid 1" />
            <p className="polaroid-text">Vuurspuwennnnn!</p>
          </li>
          <li className="polaroid-item shadow">
            <img src={polaroid2} className="polaroid-picture" alt="Polaroid 2" />
            <p className="polaroid-text">Vuil worden... #snoepje</p>
          </li>
          <li className="polaroid-item rotate-minus-10 shadow">
            <img src={polaroid3} className="polaroid-picture" alt="Polaroid 3" />
            <p className="polaroid-text">De vlaggenmast staat!</p>
          </li>
          <li className="polaroid-item rotate-20 shadow">
            <img src={polaroid4} className="polaroid-picture" alt="Polaroid 4" />
            <p className="polaroid-text">Leiding? Is't nog ver?</p>
          </li>
        </ul>
      </div>
      <div className="d-flex w-100 justify-content-center">
        <button className="btn btn-lg btn-yellow text-center text-white my-4">
          <Link to="/images" className="no-link">
            Bekijk al onze foto's <BsArrowRight />
          </Link>
        </button>
      </div>
      
    </div>
  );
};