export default function Lied1(){
  return <>
    <div>
      <h5>19. Spring - Spring</h5>
      <div className="mt-3 mb-4"></div>
      <p>niemand die me zegt wat ik doen of laten moet<br/>
niemand wordt de baas in mijn bestaan<br/>
ik zal zelf wel kiezen tussen kwaad en tussen goed<br/>
ik weet welke weg ik uit moet gaan<br/>
dus laat ze nu maar praten ik doe wat ik doe<br/>
ik ben hun slap gelul al meer dan jaren moe<br/><br/>
<strong>
ik wil leven, ik wil vrij zijn<br/>
ik wil op m'n eigen benen staan<br/>
ja, m'n leven moet van mij zijn<br/>
ik wil kiezen waar ik heen moet gaan<br/>
en ik spring, spring, spring de wereld in<br/><br/>
</strong>
iedereen weet beter geeft me goede raad<br/>
iedereen bemoeit zich met mijn zaak<br/>
tegen beterweters zeg ik nu kordaat<br/>
dat ik wel m'n eigen toekomst maak<br/>
want, ik sta in het leven zelfbewust en vrij<br/>
de tegenslag en pijn die neem ik er wel bij<br/><br/>
<strong>
ik wil leven, ik wil vrij zijn<br/>
ik wil op m'n eigen benen staan<br/>
ja, m'n leven moet van mij zijn<br/>
ik wil kiezen waar ik heen moet gaan<br/>
en ik spring, spring, spring<br/><br/>
ik wil leven, ik wil vrij zin<br/>
ik wil op m'n eigen benen staan<br/>
ja, m'n leven moet van mij zijn<br/>
ik wil kiezen waar ik heen moet gaan<br/>
en ik spring, spring, spring de wereld in<br/><br/></strong>
leef dus maar je leven<br/>
en geloof in wat je doet
jong zijn duurt maar even<br/>
alles mag niets moet<br/>
<strong>
ik wil leven, ik wil vrij zijn<br/>
ik wil op m'n eigen benen staan<br/>
ja, m'n leven moet van mij zijn<br/>
ik wil kiezen waar ik heen moet gaan<br/>
en ik spring, spring, spring<br/><br/>
ik wil leven, ik wil vrij zijn<br/>
ik wil op m'n eigen benen staan<br/>
ja, m'n leven moet van mij zijn<br/>
ik wil kiezen waar ik heen moet gaan<br/>
en ik spring, spring, spring de wereld in</strong></p>
    </div>
  </>
}