import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useTak from "../api/tak";
import { capitalizeFirstLetter } from "../helpers/capitalize";
import { useAuthProvider } from "../contexts/AuthProvider";
import { BsList } from "react-icons/bs";
import { wait } from "@testing-library/user-event/dist/utils";

const NavItem = ({ liItem, label, to, dropdownItem, onClick }) => {
  if(!liItem){
    return <Link
              to={to}
              replace={false}
              className={`nav-link text-white me-4`}
              onClick={() => {
                onClick(); // Voer de onClick-functie uit
              }}
            >
              {label}
            </Link>
  }
  return (
    <li className={`${dropdownItem ? "dropdown-item" : "nav-item"} text-white`} onClick={() => {
      onClick(); // Voer de onClick-functie uit
    }} // Sluit de navbar wanneer erop wordt geklikt
    >
      <Link
        to={to}
        className={`nav-link text-white`}
      >
        {label}
      </Link>
    </li>
  );
};

export default function Navbar(){

  const [takken, setTakken ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavClick = () => {
    wait(10000)
    setIsNavOpen(false); // Sluit de navbar wanneer er op een link wordt geklikt
  };
  const { currentUser } = useAuthProvider();

  const takkenAPI = useTak();

  useEffect(() => {
    const fetchTakken = async () => {
      try {
        const result = await takkenAPI.getAll();
        setTakken(result);
        setLoading(false)
      } catch (error) {
        
      }
    }
    fetchTakken()
  }, [loading, takkenAPI])

  return <>
    <nav className="navbar navbar-expand-lg bg-primary fixed-top text-white">
      <div className="container-fluid p-0">
        <button onClick={() => setIsNavOpen(!isNavOpen)} className="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01 " aria-expanded="false" aria-label="Toggle navigation">
          <BsList color="white" />
        </button>
        <div className={`collapse navbar-collapse p-2 mt-1 above bg-primary text-white ${
          isNavOpen ? "show" : "" // Voeg 'show' klasse toe als de navbar open is
          }`} id="navbarTogglerDemo01">
          <Link className="navbar-brand ms-0 ms-lg-4 text-white" to="/">Scouts Wondelgem</Link>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                
                  <li className="nav-item dropdown bg-primary text-white">
                    <Link className="nav-link dropdown-toggle text-white"  to={"takken"} id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Takken
                    </Link>
                    <ul className="dropdown-menu bg-primary text-white" aria-labelledby="navbarScrollingDropdown">
                      <NavItem liItem={true} dropdownItem={true} label="Overzicht" to={`takken`} onClick={handleNavClick} />
                      {loading ? null : <>
                      <li key="divider">
                        <hr className="dropdown-divider"/>
                      </li>
                      {takken?.map(tak => {
                        return (                          
                          <NavItem liItem={true} dropdownItem={true} label={capitalizeFirstLetter(tak.naam)} to={`takken/${tak.naam}`} onClick={handleNavClick} />)
                      })}
                      </>}
                    </ul>
                  </li>
                

            <NavItem liItem={true} label="Info" to="/info" onClick={handleNavClick} />
            <NavItem liItem={true} label="Gazette" to="/gazette" onClick={handleNavClick} />
            <NavItem liItem={true} label="Foto's" to="/images"  onClick={handleNavClick}/>
            <NavItem liItem={true} label="Verhuur" to="/verhuur"  onClick={handleNavClick}/>
            <NavItem liItem={true} label="Contact" to="/contact"  onClick={handleNavClick}/>
            <NavItem liItem={true} label="Inschrijven" to="/inschrijven"  onClick={handleNavClick}/>

          </ul>
          <div className="me-4 d-flex">
          {currentUser ? <>

              <NavItem liItem={false} label="Profiel" to="/profiel" onClick={handleNavClick} />
            
            {(currentUser?.roles?.includes("Admin") || currentUser?.roles?.includes("Groepsleiding")) &&
              <NavItem liItem={false} label="Gebruikers" to="/gebruikers" onClick={handleNavClick} />}
          </>
          :
          <>
            <NavItem liItem={false} label="Leiding" to="/auth" onClick={handleNavClick} />
          </>}
          </div>
        </div>
      </div>
    </nav>
  </>
}