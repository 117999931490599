import { useCallback } from "react"

export default function Limit({
  onChangeLimit
}){

  const handleChangeLimit = useCallback(async (event) => {
    const selectedValue = event.target.value;
    onChangeLimit(selectedValue)
  }, [])

  return <>
    <label className="ms-2 mb-1">Aantal</label>
    <select className="form-select" onChange={handleChangeLimit}>
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  </>
}