import React from 'react';
import './Footer.css';
import { BsEnvelope, BsFacebook, BsInstagram, BsMapFill, BsPhone, BsTelephone } from 'react-icons/bs';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row mt-0">
          <div className="col-12 col-xl">
            <h4>Contact</h4>
            <div className='row'>
              <div className='col-12 text-center col-md-4 text-md-end'>
                <BsEnvelope className="icon" />
              </div>
              <div className='col text-center text-md-start'>
              <p>
              <a className='no-link' href='mailto:groepsleiding@scoutswondelgem.be'>groepsleiding@scoutswondelgem.be</a>
              </p>
              </div>
            </div>
            <div className='row'>
              <div className='col-4 text-end'>
                <BsTelephone className="icon" />
              </div>
              <div className='col text-start'>
                <p>0491/91.43.37</p>
              </div>
            </div>
            <div className='row justi'>
              <div className='col-4 text-end'>
                <BsMapFill className="icon" />
              </div>
              <div className='col text-start'>
                <p>Vroonstallestraat 8<br></br>9032 Wondelgem</p>
              </div>
            </div>
          </div>
          <div className="col">
            <h4>Volg ons</h4>
            <div className="social-icons">
              <a href="https://www.facebook.com/scoutswondelgem" target="_blank" rel="noopener noreferrer">
                <BsFacebook className="icon mt-2" />
              </a>
              <a href="https://www.instagram.com/scoutswondelgem" target="_blank" rel="noopener noreferrer">
                <BsInstagram className="icon mt-2" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-4">
          <p>&copy; 2023 Scouts Wondelgem. Alle rechten voorbehouden.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
