import { useCallback, useState } from "react";
import UserForm from "./UserForm";
import useUser from "../../api/user";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { usersUrl } from "../../helpers/apiUrls";

export default function UserCreate(){

  const [ selectedFile, setSelectedFile] = useState();

  const userAPI = useUser();

  const handleFileSelect = useCallback(async (file) => {
    console.log(file)
    setSelectedFile(file)
  }, []);


  const navigate = useNavigate()

  const save = useCallback(async ({voornaam, familienaam, email, gsmnummer, takid, rollen}) => {
    try{
      console.log(`takid: ${takid}`)
      const response = await userAPI.create({voornaam, familienaam, email, gsmnummer, takid})
      const userid = response.id;
      console.log(userid)
      if(rollen.length > 0){
        await userAPI.setRoles(userid, rollen)
      }

      if (selectedFile) {
        let formData = new FormData();
        formData.append('image', selectedFile);
        console.log("updating profile pic")
        await userAPI.uploadProfilePic({userid, formData})
        
        console.log(result)
      }
      navigate(`/gebruikers`, {replace: true})
    }catch(error){
       
    }
  }, [selectedFile, navigate])

  return <>
    <div className="container mt-4">
      <h1>Voeg gebruiker toe</h1>
      <UserForm
        save={save}
        handleFileSelect={handleFileSelect}
      />
    </div>
  </>

}