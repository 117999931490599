export default function Lied1(){
  return <>
    <div>
      <h5>4. Daylight - David Kushner</h5>
      <div className="mt-3 mb-4"></div>
      <p>Telling myself I won't go there<br/>
Oh, but I know that I won't care<br/>
Tryna wash away all the blood I've spilt<br/>
This lust is a burden that we both share<br/>
Two sinners can't atone from a lone prayer<br/>
Souls tied, intertwined by our pride and guilt<br/><br/>
There's darkness in the distance<br/>
From the way that I've been livin'<br/>
But I know I can't resist it<br/><br/>
<strong>
Oh, I love it and I hate it at the same time<br/>
You and I drink the poison from the same vine<br/>
Oh, I love it and I hate it at the same time<br/>
Hidin' all of our sins from the daylight<br/>
From the daylight, runnin' from the daylight<br/>
From the daylight, runnin' from the daylight<br/>
Oh, I love it and I hate it at the same time</strong><br/><br/>
Tellin' myself it's the last time<br/>
Can you spare any mercy that you might find<br/>
If I'm down on my knees again?<br/>
Deep down, way down, Lord, I try<br/>
Try to follow your light, but it's night time<br/>
Please, don't leave me in the end<br/><br/>
There's darkness in the distance<br/>
I'm beggin' for forgiveness (ooh)<br/>
But I know I might resist it, oh<br/><br/>
<strong>
Oh, I love it and I hate it at the same time<br/>
You and I drink the poison from the same vine<br/>
Oh, I love it and I hate it at the same time<br/>
Hidin' all of our sins from the daylight<br/>
From the daylight, runnin' from the daylight<br/>
From the daylight, runnin' from the daylight<br/>
Oh, I love it and I hate it at the same time<br/><br/>
Oh, I love it and I hate it at the same time<br/>
You and I drink the poison from the same vine<br/>
Oh, I love it and I hate it at the same time<br/>
Hidin' all of our sins from the daylight<br/>
From the daylight, runnin' from the daylight<br/>
From the daylight, runnin' from the daylight<br/>
Oh, I love it and I hate it at the same time</strong></p>
    </div>
  </>
}