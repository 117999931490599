import { Carousel } from 'react-bootstrap';
import tropical_beats from "./images/tropical_beats.jpg"
import tropical_beats_2 from "./images/tropical_beats2.jpg"
import s2d from './images/S2D.jpg'
import sint from './images/Sint.jpg'
import wafels from './images/wafels.png'
import jin from './images/jin.jpg'

const Kasacties = () => {
  return (
    <>
    <div className='mt-0 pt-4'>
      <div className='container'>
        <h1 className="text-center">Kasacties</h1>
        <p className='text-center'>
          Doorheen het jaar organiseren wij enkele kasacties om onze kas te spijzen. 
          Lees hier meer over alles wat je kan verwachten!
        </p>
        <div className='row mt-4'>
          <div className='col-md-4'>
            <img class="info-page-banner shadow-lg mb-4" src={s2d} alt="Card image cap"/>
          </div>
          <div className='col-md-8'>
            <h2 className='card-title'>Scouts2daagse</h2>
            <p>
              Elk derde weekend van september wordt ons terrein omgetoverd tot een waar openluchttheater. 
              Wekenlang repeteren leiding en leden om het weekend te openen met een spetterend toneelstuk. 
              Daarnaast ontdekken de leden ook wie hun nieuwe leiding wordt voor komend jaar; spannend!
              Na het toneel wordt iedereen hartelijk uitgenodigd om in de kampvuurkring deel te nemen
              aan onze gezellige zangstonde. Dit is altijd een heel gezellig en scoutesk moment, 
              met nummers voor iedereen. Op zaterdag kunnen de jongsten genieten van het toneel van Pierke. 
              Het slotstuk van het weekend is de memorabele barbecue waar iedereen zijn buikje vol kan eten.
            </p>
            <p>
              De scouts2daagse is een kasactie van onze VZW. Hierdoor kunnen zij onze lokalen onderhouden
              zodat leden en leiding zorgeloos kunnen spelen.
            </p>
          </div>
        </div>      
      </div>
    </div>

    <div className='divider'></div>
    
    <div className='bg-primary text-white'>
      <div className='container mt-0 pt-4'>
        <div className="row">
          <div className="col-md-8">
            <h2>Sinterklaaspakketjes</h2>
            <p>
              Zie ginds komt de stoomboot uit Wondelgem weer aan. 
              Hij brengt ons Sinterklaaspakketjes, ik zie de bestellink al staan. 
            </p>
            <p>
              In november start de verkoop van onze overheerlijke Sinterklaaspakketjes. 
              Zoals elk jaar vult de Sint onze pakketjes met nicnacjes, snoepgoed, chocolade, en meer. 
              Deze pakjes vol lekkers kunnen begin december opgehaald worden op onze scouts.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <img class="info-page-banner shadow-lg" src={sint} alt="Card image cap"/>
          </div>
        </div>
      </div>
    </div>

    <div className='divider'></div>
      
    <div className=''>
      <div className="container mt-0 pt-4">
        <div className='row'>
          <div className='col-md-4 mb-4'>
            <img class="info-page-banner shadow-lg" src={wafels} alt="Card image cap"/>
          </div>
          <div className="col-md-8">
            <h2>Wafelbak</h2>
            <p>
              Het tweede weekend van februari is het zover, dan staat Wondelgem op met geur van versgebakken wafels.
              Leden en leiding gaan de zaterdag van huis tot huis om bestellingen op te nemen. 
              De zondag worden ze vers gemaakt, gebakken en persoonlijk aan huis gebracht. 
              Op onze lokalen is er van 14 uur tot 17 uur een wafelsalon, hier kan u gezellig samenkomen om van onze
              heerlijke wafels te proeven. De laatste jaren bakten we meer dan 10.000 wafels!
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className='divider'></div>

    <div className='bg-primary text-white'>
      <div className='container mt-0 pt-4'>
        <div className="row">
          <div className="col-md-8">
            <h2>Tropical Beats</h2>
            <p>
              Haal je innerlijk fuifbeest boven! Op vrijdag avond nodigen we graag alle ouders uit voor onze afterwork party. 
              Dé ideale manier om eens te ontspannen na een zware werkweek. Ook voor onze jongsten is er een gelegenheid om de 
              dansschoenen nog eens boven te halen: op zaterdag organiseren we naar goede gewoonte onze kidsfuif. 
              De ouders kunnen dan nagenieten van de afterwork party in onze Bodega. Nog geen genoeg gekregen van al dat dansen? 
              Kom dan naar Tropical Beats, de plek waar de jeugd uit Wondelgem en omstreken kan samenkomen. 
              Er zijn verschillende zalen met elk hun eigen muziekstijl, van populaire radiomuziek in de grote zaal 
              tot techno, hardcore, drum and bass in onze kleinste zaal.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <img className="info-page-img rounded shadow-lg " src={tropical_beats_2} alt="Afbeelding 10" />
          </div>
        </div>
      </div>
    </div>

    <div className='divider' />

    <div className=''>
      <div className='container mt-0 py-4'>
        <div className="row">
        <div className='col-md-4 mb-4'>
          <img class="info-page-banner shadow-lg" src={jin} alt="Card image cap"/>
        </div>
          
          <div className="col-md-8">
            <h2>Kasacties Jin</h2>
            <p>De oudste tak van de scouts gaat elk jaar op een fantastisch buitenlands kamp. 
            Om dat kamp te financieren doen zij elk jaar verscheidene kasacties. Daarvoor maken zij zelf reclame, 
            hou dit goed in de gaten!</p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Kasacties;