import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useImages from "../../api/images";
import Loader from "../loader/Loader";
import ImagePreview from "./ImagePreview";
import Lightbox from "./Lightbox";
import { Link } from "react-router-dom";
import { useAuthProvider } from "../../contexts/AuthProvider";

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function ImageList(){
  const [ images, setImages ] = useState([])
  const [ loading, setLoading ] = useState(true);

  const { takid, mapid } = useParams();

  const {currentUser} = useAuthProvider();

  const imageAPI = useImages();
  
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const result = await imageAPI.getImages({takid, mapid})
         
        let urls = [];
        for (let i = 0; i < result.length; i++) {
          const bufferData = result[i].image.data;
          const uint8Array = new Uint8Array(bufferData);
          const fileBlob = new Blob([uint8Array], { type: 'application/octet-stream' });

          const url = await window.URL.createObjectURL(fileBlob)
           
          urls.push({ name: result[i].name, url});
          
        }
         
        setImages(urls);
        setLoading(false)
      } catch (error) {
        
      }
    }
    fetchImages()
  }, [loading]);

  return <>
    <div className="container mb-5 py-4">
      <h1>Foto's</h1>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><Link to="/images">Foto's</Link></li>
          <li class="breadcrumb-item"><Link to={`/images/${takid}`} className="no-link">{capitalizeFirstLetter(takid)}</Link></li>
          <li class="breadcrumb-item active"><a>{capitalizeFirstLetter(mapid)}</a></li>
        </ol>
      </nav>
      {currentUser?.roles.includes("Leiding") && 
      <><Link to={"upload"}><button className="btn btn-primary bg-primary my-4">Foto's uploaden!</button></Link></>
      }
      {loading ? <Loader></Loader> : 
      <>{
        images.length <= 0 ? 
        <div className="alert alert-info" data-cy="transactions_error">
          <p className="alert-heading">Er zijn nog geen afbeeldingen voor deze map.</p>
        </div>
        : 
        <div className="row">
          <Lightbox takid={takid} mapid={mapid} images={images} />
        </div>  
      }     
      </>}
    </div>
  </>
}