import { useEffect, useState } from "react"
import useUser from "../../api/user"
import UserPreview from "../user/UserPreview";

export default function Contact(){

  const userAPI = useUser();
  const [groepsleiding, setGroepsleiding] = useState([]);

  useEffect(() => {
    const fetchGroepsleiding = async () => {
      const response = await userAPI.getUsersWithRole("Groepsleiding");
      setGroepsleiding(response.users);
    }
    fetchGroepsleiding()
  }, [])

  return <>
    <div className="container">
      <h2>Contact</h2>
      <p>U kan ons steeds bereiken op het algemeen e-mailadres 
          <a href="mailto:info@scoutswondelgem.be">info@scoutswondelgem.be</a>.
          Indien u onze groepsleiding individueel wilt bereiken, kan dit via onderstaande gegevens.
          Voor informatie over de leiding van een specifieke tak kan u steeds terecth in het tablad Takken.
      </p>
      <h4 className="text-center">Groepsleiding</h4>
      <div className="row">
        { groepsleiding.map(leider => {
            return <>
            <div className={`col-12 col-md-4 p-2 fade-in`} key={leider.id}>
              <UserPreview
                id={leider.id}
                voornaam={leider.voornaam}
                familienaam={leider.familienaam}
                email={leider.email}
                phone={leider.gsmnummer}
              />
            </div>
            </>
        })}
      </div>
      <h4 className="mt-4">Social Media</h4>
      <p>U kan ons ook vinden op Instagram en Facebook voor leuke foto's en updates.</p>
    </div>
  </>
}