import axios from "axios";
import { useCallback } from "react";
import { authUrl } from "../helpers/apiUrls";


const useAuth = () => {

	const getToken = useCallback(async ({email, password}) =>{
		const response = await axios({
			method: "POST",
			url: `${authUrl}/get-token`,
			data: {email, password}
		});
		return response.data;
	}, []);

	const requestPasswordMail = useCallback(async ({email}) => {
		await axios({
			method: "POST",
			url: `${authUrl}/request-new-password-mail`,
			data: {email}
		});
	}, []);

	const createNewPassword = useCallback(async ({token, password, passwordRepeat}) => {
		await axios({
			method: "POST",
			url: `${authUrl}/request-new-password`,
			headers: {
				Authorization: `Bearer ${token}`,
			},
			data: {password, passwordRepeat}
		});
	}, []); 

	return {
		requestPasswordMail,
		createNewPassword,
		getToken
	};
};

export default useAuth;
