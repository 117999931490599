export default function Inschrijvingen(){
  return <>
    <div className="container">
      <h2 className="text-center">Inschrijvingen & algemene informatie</h2>
      <h3>Lid worden en inschrijvingsgeld</h3>
      <p>
      Het inschrijvingsgeld bedraagt €62,5 voor het oudste lid en €60,5 voor alle jongere broers en zussen. 
      €15 euro van dit bedrag wordt door de tak zelf gebruikt om vieruurtjes voor tijdens de vergaderingen
      aan te kopen. Het overige bedrag gaat naar Scouts & Gidsen Vlaanderen en wordt gebruikt voor de verzekeringen. 
      </p>
      <p>
        Als u graag meer informatie wil over deze verzekeringen, 
        neem dan zeker eens een kijkje bij het tabblad ‘verzekeringen’.
      </p>
    </div>
    <div className="container">
      <h3>Wanneer is het scouts?</h3>
      <p>
        Elke maand zal de leiding van de tak (groep) van uw kind de maandbrief (planning) 
        doormailen en op de website plaatsen. De vergadering (activiteit) gaat telkens door 
        op zondag. Het is belangrijk om steeds op tijd te komen zodat we op tijd kunnen beginnen. 
        Wanneer je te laat bent, is het mogelijk dat de tak al weg is omdat ze bv. op verplaatsing zijn. 
        Indien u al op voorhand weet dat uw kind te laat zal zijn, of niet kan komen naar de vergadering, 
        gelieve dan de leiding te verwittigen zodat zij hier rekening mee kunnen houden.
      </p>
      <p>
        De vergaderingen variëren licht bij de kleine en grote takken.
      </p>
      <p>
        Kleine takken: vergadering van 14u-17u behalve de laatste zondag van de maand.
        <ul>
          <li>Kapoenen</li>
          <li>Wouters</li>
          <li>Woudlopers</li>
        </ul>
        Grote takken: vergadering van 14u-18u.
        <ul>
          <li>Jonggidsen & jongverkenners</li>
          <li>Givers (ook halfuurtje)</li>
          <li>Jin (ook halfuurtje)</li>
        </ul>
        Elke wijziging door bv. weekends zullen steeds tijdig worden aangekondigd via de maandbrief.
      </p>
    </div>

    <div className="container">
      <h3>Contact met de leiding</h3>
      <p>
        U kan de leiding van uw kind steeds contacteren via mail of telefonisch. 
        Op de pagina van de tak kunt u meer contactinformatie vinden.
      </p>
      <p>
        Twee keer per jaar, in december en in mei, organiseren wij een oudervergadering. 
        Dit is een moment dat meestal samengaat met afstempelen van attesten, paskoffer, tweedehandsbeurs, ... 
        waarbij onze groepsleiding jullie op de hoogte brengt van het reilen en zeilen op de scouts. 
      </p>
    </div>

    <div className="container">
      <h3>Hoe ziet een vergadering er uit?</h3>
      <p>
        Doorgaans beginnen we elke zondag om 14u met het aantreden (formatie). 
        Na het aantreden begint het spel/ de activiteit. Deze duurt afhankelijk van de tak tot 17u of 18u. 
        Om 16u krijgen de leden een vieruurtje. De vergadering sluiten we steeds af met het aftreden.
      </p>
      <p>
        Het is belangrijk om steeds op tijd te komen. Als u te vroeg bent, 
        gelieve dan samen met uw kind te wachten zodat de leiding zich kan focussen op de laatste 
        voorbereiding voor de vergadering. Verder willen wij ook graag vragen aan onze ouders om zich op de 
        achtergrond te houden tot de vergadering gedaan is. Voor onze jongste leden is het erg afleidend wanneer
        hun ouders hen al staan op te wachten.
      </p>
    </div>

    <div className="container mb-5">
      <h3>Uniform</h3>
      <p>
        Wij vinden het belangrijk dat onze leden steeds in perfect uniform naar de scouts komen. 
        Dit houdt in: groene broek of rok, scoutshemd en onze blauwe das. Hierop gelden twee uitzonderingen: 
        de kapoenen moet geen volledig uniform dragen, enkel de T-shirt van onze scouts en wanneer duidelijk 
        aangegeven staat op de maandbrief dat het uniform niet nodig is. 
      </p>
      <p>
        De T-shirt en de blauwe das zijn bij ons te koop met cash geld. Meer info bij alle leiding op de scouts.
      </p>
      <p>
        Tip: draag niet je mooiste kleren op de scouts; het kan altijd zijn dat ze vuil worden. 
        Kies dus je mooiste speelkleren en -schoenen die tegen een stootje en wat modder kunnen 😊.
      </p>
    </div>
  </>
}