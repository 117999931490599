const baseUrl =  process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_API_DEV_URL}` : `${process.env.REACT_APP_API_URL}`;

const takkenUrl = `${baseUrl}/takken`;
const usersUrl = `${baseUrl}/users`;
const gazetteUrl = `${baseUrl}/gazetten`;
const eventsUrl = `${baseUrl}/events`;
const imageUrl = `${baseUrl}/images`;
const authUrl = `${baseUrl}/auth`;
const verhuurUrl = `${baseUrl}/verhuur`;
const logsUrl = `${baseUrl}/logs`;
const analyticsUrl = `${baseUrl}/analytics`;

module.exports = {
	takkenUrl,
	usersUrl,
	gazetteUrl,
	eventsUrl,
	imageUrl,
	authUrl,
	verhuurUrl,
	logsUrl,
	analyticsUrl
};