import { useCallback, useEffect, useState } from "react"
import "./Image.css"

export default function ImagePreview({image, index, handleSelecteerImage}){

  const [isVertical, setIsVertical] = useState(false);


  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setIsVertical(img.height > img.width);
    };
    img.src = image.url;
  }, [image]);

  const imageClass = isVertical ? 'image-vertical' : 'image-horizontal';

  const handleClick = useCallback(() => {
    handleSelecteerImage(image)
  })
  return <>
  <div className={imageClass}>
    <img
      key={index}
      src={image.url}
      alt={image.name}
      onClick={handleClick}
      className="lightbox-preview card border-0 shadow"
        />
    </div>
  </>
}