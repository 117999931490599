import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useTak, * as takkenAPI from '../../api/tak';
import UploadComponentSingle from "../upload/FileUploadSingle";
import { capitalizeFirstLetter } from "../../helpers/capitalize";
import useUser from "../../api/user";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumbers";

const validationRules = {
  voornaam: {
    required: "Gelieve de gebruiker een voornaam te geven.",
    minLength: { value: 3, message: "Minimum lengte is 3"}
  },
  familienaam: {
    required: "Gelieve de gebruiker een familienaam te geven.",
    minLength: { value: 3, message: "Minimum lengte is 3"}
  },
  email:{
    required: "Gelieve een email op te geven.",
  },
  gsmnummer: {
    required: "Gelieve een gsm nummer op te geven.",
    minLength: {value: 9, message: "Ongeldig nummer"}
  }
}

export default function UserForm({save, handleFileSelect, user}){

  const [ loadedLogo, setLoadedLogo] = useState(false);
  const [ takken, setTakken] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [ rollen, setRollen ] = useState([]);

  const takkenAPI = useTak();
  const usersAPI = useUser();

  const { setValue, register, handleSubmit, reset, formState: { errors }} = useForm();

  const chooseFile = useCallback((file) => {
    handleFileSelect(file);
    setLoadedLogo(true);
  }, []);

  const onSubmit = (data) => {
    setLoading(true)
    const { voornaam, familienaam, email, gsmnummer, tak} = data;

    const selectedRoles = rollen
        .filter(rol => data[`rol_${rol.naam}`]) // Filter alleen aangevinkte rollen
        .map(rol => rol.naam);

    save({
      voornaam,
      familienaam,
      email,
      gsmnummer: formatPhoneNumber(gsmnummer),
      takid: tak,
      rollen: selectedRoles
    });
    reset();
    handleFileSelect(null) 
    
  }

  useEffect (() => {
    const fetchRoles = async () => {
      const result = await usersAPI.getRoles();
      setRollen(result);
    }
    fetchRoles();
  })

  

  useEffect(() => {
    const fetchTakken = async () => {
      const data = await takkenAPI.getAll();
      setTakken(data);
      setLoading(false)
    } 
    fetchTakken();

    if(user){
      setValue("voornaam", user.voornaam);
      setValue("familienaam", user.familienaam);
      setValue("email", user.email);
      setValue("gsmnummer", user.gsmnummer);
      if(user.tak){
        setValue("tak", user.tak.id);
      }

       // Vink de selectievakjes aan op basis van de rollen van de gebruiker
      user.roles.forEach(rol => {
        const hasRole = user.roles.includes(rol);
        setValue(`rol_${rol}`, hasRole ? rol : null);
      });
      
    }
  }, [loading])
  return <>
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="row mb-4">
          <div className="col-12 col-md-6 p-2 h-100">
            <h3>Info</h3>
            <div className="card card-rounded h-100 shadow border border-0" >

            <div className="row">
              <div className="col-12 col-md">
                <div className="form-group mb-3">
                  <label htmlFor="voornaam">Vooraam</label>
                  <input
                    {...register("voornaam", validationRules.voornaam)}
                    id="voornaam"
                    type="text"
                    className="form-control"
                    placeholder="Voornaam"
                  />
                  {errors.voornaam && <p className="form-text text-danger">{errors.voornaam.message}</p> }
                </div>
              </div>
              <div className="col-12 col-md">
                <div className="form-group mb-3">
                  <label htmlFor="familienaam">Familienaam</label>
                  <input
                    {...register("familienaam", validationRules.familienaam)}
                    id="familienaam"
                    type="text"
                    className="form-control"
                    placeholder="Familienaam"
                  />
                  {errors.familienaam && <p className="form-text text-danger">{errors.familienaam.message}</p> }
                </div>
              </div>
            </div>
              
              
              <div className="w-100 mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  {...register("email", validationRules.email)}
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Email"
                />
                {errors.email && <p className="form-text text-danger">{errors.email.message}</p> }
              </div> 
              <div className="w-100 mb-3">
                <label htmlFor="gsmnummer" className="form-label">GSM</label>
                <input
                  {...register("gsmnummer", validationRules.gsmnummer)}
                  id="gsmnummer"
                  type="text"
                  className="form-control"
                  placeholder="GSM"
                />
                {errors.gsmnummer && <p className="form-text text-danger">{errors.gsmnummer.message}</p> }
              </div>
              <div className="w-100 mb-3">
                <label htmlFor="tak" className="form-label">Tak</label>
                <select
                  {...register("tak", validationRules.tak)}
                  id="tak"
                  type="text"
                  className="form-control"
                  placeholder="tak"
                > 
                  <option value="">-Kies een tak-</option>
                  {takken.map(tak => {
                    return (
                      <option
                        selected={user && user.tak ? user.tak.id === tak.id : false}
                        key={tak.id}
                        value={tak.id}
                      >
                        {capitalizeFirstLetter(tak.naam)}
                      </option>
                    );
                  })}
                </select>
                {errors.gsmnummer && <p className="form-text text-danger">{errors.gsmnummer.message}</p> }
              </div>
              <div className="w-100 mb-3">
                <label htmlFor="rol" className="form-label">Rollen</label>
                  {rollen.map(rol => (
                    <div key={rol.naam} className="form-check">
                      <input
                        type="checkbox"
                        id={`rol-${rol.naam}`}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setValue(`rol_${rol.naam}`, isChecked ? rol.naam : null);
                        }}
                        {...register(`rol_${rol.naam}`)}
                      />
                      <label htmlFor={`rol-${rol.naam}`} className="ms-2">{capitalizeFirstLetter(rol.naam)}</label>
                    </div>
                  ))}
                {errors.gsmnummer && <p className="form-text text-danger">{errors.gsmnummer.message}</p> }
              </div>
            </div>
          </div>
          <div className="col">
            <h3>Profielfoto</h3>
            <UploadComponentSingle onSaveAfbeelding={chooseFile} loadedLogo={loadedLogo}></UploadComponentSingle>
          </div>
        </div>
        <div className="clearfix">
              <button
                type="submit"
                className="btn btn-primary w-100"
                disabled={loading}
              >
                Bevestig!
              </button>
            </div>
      </form>
  </>
}