import { Link } from "react-router-dom"

export default function EventPreview({event, afbeelding}) {
  return <>
    <div class="col-12 col-lg-4 mt-4">
      <div className="rounded border border-0">
        <div class="event-preview-banner rounded-top"
          style={{
            "background-image": afbeelding ? `url(${afbeelding})` : "",
            "background-size": "100px",
            "background-repeat": "repeat"
          }}>
        </div>
        <div class="card border border-0 card-content event-content container p-2 mt-0 h-100">
          <h3>{event.naam}</h3>
          <p>{event.teaser}</p>

          <Link to={`evenementen/${event.id}`} class="btn btn-yellow text-white">
            Meer info
          </Link>
        </div>
      </div>
    </div>
  </>
}