import Dropzone from "react-dropzone";
import { useCallback, useState } from "react";
import { BsFiletypePdf } from "react-icons/bs";
import { useAuthProvider } from "../../contexts/AuthProvider";
import ConfirmationModal from "../modal/ConfirmModal";
import Loader from '../loader/Loader'
import "./Gazette.css"
import useGazette from "../../api/gazette";


export default function GazetteForm(){

  const { currentUser } = useAuthProvider();

  const gazetteAPI = useGazette();


  const [ file, setFile ] = useState(undefined);
  const [ loadingUpload, setLoadingUpload] = useState(false);


  const handleDrop = useCallback(async (file) => {
    setFile(file);
     
  })

  const uploadGazette = useCallback(async () => {
    setLoadingUpload(true)
    try {
       
      let formData = new FormData();
      file.forEach((file, index) => {
         
        formData.append(`gazette`, file, file.name);
      });
       
      
      const response = await gazetteAPI.uploadGazette({formData})
      console.log(response)
       
      setShowMaandbriefModal(true)
    } catch (error) {
      
    }finally{
      setLoadingUpload(false)
    }
  },[file])

  const [showMaandbriefModal, setShowMaandbriefModal] = useState(false);

  const handleCloseMaandbriefModal = () => {
    setShowMaandbriefModal(false);
  };

  return <>

<ConfirmationModal
        show={showMaandbriefModal}
        onClose={handleCloseMaandbriefModal}
        title="Gazette geüpload!"
        body="Jouw gazette is met success geüpload."
      />

      <div className='drop-zone-container text-center d-flex flex-column align-items-center justify-content-center'>
        <BsFiletypePdf />
        <Dropzone onDrop={handleDrop} 
          multiple={false}
          accept="application/pdf">
                {({ getRootProps, getInputProps }) => (
                  <div className='' {...getRootProps()}>
                  <input {...getInputProps({ name: 'gazette' })} accept="application/pdf" name="gazette"/>
                    <p>Drop een nieuwe gazette of <span className='text-primary clickable'>zoek</span></p>
                    <p className='text-muted'><small>Ondersteunt .pdf</small></p>
                    {file && <p>[{file[0].path}]</p>}
                  </div>
                )}
        </Dropzone>

        {loadingUpload  && <Loader></Loader>}

        <button onClick={async () => uploadGazette()} disabled={!file} className="btn btn-primary bg-primary">Uploaden!</button>
      </div>
  </>
}