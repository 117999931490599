import { Carousel } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './TakDetail.css';
import { BsCalendarFill, BsCreditCard, BsDownload, BsEnvelopeFill, BsFileEarmarkText } from 'react-icons/bs';
import Loader from '../loader/Loader';
import UserPreview from '../user/UserPreview';
import useTak from '../../api/tak';
import { capitalizeFirstLetter } from '../../helpers/capitalize';
import MaandbriefPreview from './MaandbriefPreview';
import { takkenUrl, usersUrl } from '../../helpers/apiUrls';



export default function TakDetail() {
  const [tak, setTak] = useState();
  const [logo, setLogo] = useState();
  const [leidingImages, setLeidingImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingImages, setLoadingImages] = useState(true);
  const [loadingProfilePics, setLoadingProfilePics] = useState(true);
  const { naam } = useParams();
  const [fileUrls, setFileUrls] = useState([]);
  const [ pdfUrl, setPdfUrl ] = useState();
  const [ loadingPdf, setLoadingPdf ] = useState(true)

  const takkenAPI = useTak();
  const navigate = useNavigate();

  const fetchTak = async () => {
    try {
      const data = await takkenAPI.getById(naam);
      console.log(data)
       
      setTak(data);
      const response = await axios.get(`${takkenUrl}/${naam}/images/`, { responseType: 'blob' });
      const fileBlob = new Blob([response.data], { type: 'image/png' });
      const url = URL.createObjectURL(fileBlob);
      setLogo(url);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    
    fetchTak();
  }, [loading, naam]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(`${takkenUrl}/${naam}/images/carousel`);
        let urls = [];
        for (let i = 0; i < response.data.carousel.length; i++) {
          const bufferData = response.data.carousel[i].data;
          const uint8Array = new Uint8Array(bufferData);
          const fileBlob = new Blob([uint8Array], { type: 'application/octet-stream' });
          const url = await window.URL.createObjectURL(fileBlob);
          urls.push(url);
        }
        setFileUrls(urls);
        setLoadingImages(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchFiles();
  }, [loadingImages, loading, naam, tak]);

  const handleDownloadPdf = useCallback(async (pdf) => {
    const result = await axios.get(`${takkenUrl}/${tak.naam}/maandbrieven/${pdf}`, {
      responseType: "blob"
    })
     

    const file = new Blob([result.data], { type: "application/pdf" });
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file, {name: `${pdf}.pdf`});
    const newWindow = window.open(fileURL, "_blank");
    if (newWindow) {
      newWindow.addEventListener("beforeunload", () => {
        URL.revokeObjectURL(fileURL);
      });
    }
  })

  const deleteMaandbrief = useCallback(async (pdf) => {
    const result = await takkenAPI.deleteMaandbrief(tak.naam, pdf)
    fetchTak()
  })


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="container py-4 fade-in">
            <div className='row h-100 mx-2'>

              <div className='col-12 col-lg-6'>
                <div className="card h-100 shadow border-0">
                  <div className="card-body pb-0">
                    <h1 className="text-center text-md-start tak-name">
                      <img src={logo} className="tak-logo" alt={`logo ${tak.naam}`} />
                      {capitalizeFirstLetter(tak.naam)}
                    </h1>
                    <hr />
                    <p className="mb-2">{tak.beschrijving}</p>
                  </div>
                </div>
              </div>

              <div className='col-12 col-lg-6 mt-4 mt-lg-0'>
                {loadingImages ? (
                  <Loader />
                ) : (
                  <div className='card shadow border border-0'>
                    <Carousel>
                      {fileUrls.map((url, index) => {
                        return (
                          <Carousel.Item key={index} className=''>
                            <img className='rounded w-100 carousel-image' src={url} alt={`Slide ${index + 1}`} />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                )}
              </div>
            </div>
            
            <div className='row'>
              <div className='col text-center'>
                <h2 className="leader-title mt-4 mb-0">Leiding</h2>
                <div className="row mx-0 justify-content-center">
                  { tak.leiding.map(leider => {
                    return <>
                    <div className={`col-12 col-md-6 ${tak.leiding.length  === 5 ? "col-xl-4" : "col-xl-3"} p-2 fade-in`} key={leider.id}>
                      <UserPreview
                        id={leider.id}
                        voornaam={leider.voornaam}
                        familienaam={leider.familienaam}
                        email={leider.email}
                        phone={leider.gsmnummer}
                      />
                    </div>
                    </>
                  })}
                </div>
              </div>
            </div>

            <div className='row mx-2 my-4'>

            <div className='col-12 col-lg-6'>
                <h2>Extra info</h2>

                <ul className="list-group border border-1">
                  <li className="list-group-item border border-1 bg-white text-sec">
                    <div className='d-flex align-items-center'>
                      <BsEnvelopeFill size={12} />
                      <p className='mb-0 ms-2 text-black'>{tak.email}</p>
                    </div>
                  </li>
                  <li className="list-group-item border border-1 bg-white text-sec">
                    <div className='d-flex align-items-center'>
                      <BsCalendarFill size={12} />
                      <p className='mb-0 ms-2 text-black'>{tak.startLeeftijd}-{tak.eindLeeftijd} jaar</p>
                    </div>
                  </li>
                  <li className="list-group-item border border-1 bg-white text-sec">
                    <div className='d-flex align-items-center'>
                    <BsCreditCard size={12} />
                      <p className='mb-0 ms-2 text-black'>{tak.rekeningnummer}</p>
                    </div>
                  </li>
                </ul>
                
              </div>

              <div className='col-12 col-lg-6'>
                <h2 className="letter-title">Brieven</h2>
                <ul className="list-group">
                {tak.maandbrieven.length > 0 ? tak.maandbrieven.map(pdf => {
                    return <>
                    <MaandbriefPreview taknaam={tak.naam} pdf={pdf} handleDelete={deleteMaandbrief} handleDownload={handleDownloadPdf}/>
                    </>
                }) : <>
                  <p>Er zijn momenteel geen brieven beschikbaar.</p>
                </>}
                </ul>
              </div>

              
            </div>
          </div>
        </>
      )}
    </>
  );
}