export default function Lied14(){
  return <>
    <div>
      <h5>14. Vonken en Vuur - Clouseau</h5>
      <div className="mt-3 mb-4"></div>
      <p>Even leek ik niet te leven<br/>
Zonder reden radeloos en uitgeteld<br/>
Weken hing het in mijn kleren<br/>
Tot mijn wereld heel opeens werd bijgesteld<br/><br/>
Mijn droom wordt meer dan ooit bewoond door jou<br/><br/>
<strong>
Jij geeft me vonken en vuur<br/>
Gevaarlijk maar van lange duur<br/>
Jij geeft me verlangen en trouw<br/>
bij jou zijn is de kick die ik echt wou<br/><br/>
</strong>
Heerlijk jou te zien bewegen<br/>
Ik ga zweven als ik in je ogen kijk<br/>
Racen over gladde wegen<br/>
Alles geven zonder dat ik een keer wijk<br/><br/>
Want jij maakt elke baan toch vrij voor mij<br/><br/>

<strong>
Jij geeft me vonken en vuur<br/>
Gevaarlijk maar van lange duur<br/>
Jij geeft me verlangen en trouw<br/>
bij jou zijn is de kick die ik echt wou<br/><br/>
</strong>
Dit is hemels, zo onwerelds, overheerlijk, en telkens weer magisch<br/>
en wat raar is: het gebeurt automatisch<br/>
en zeven op zeven!<br/><br/>
Even leek ik niet te leven<br/>
maar nu leef ik beter dan ooit...<br/><br/>

<strong>
Jij geeft me vonken en vuur<br/>
Gevaarlijk maar van lange duur<br/>
Jij geeft me verlangen en trouw<br/>
bij jou zijn is de kick die ik echt wou<br/><br/>
</strong>

Als een duik in het duistere ondiepe water<br/>
De kick die ik echt wou<br/>

Als een hard drug, maar zonder verslaving en kater<br/>
De kick die ik echt wou<br/>
De kick die ik echt wou</p>
    </div>
  </>
}