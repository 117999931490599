import { useCallback, useState } from "react";
import { BsDownload, BsFileEarmarkText, BsTrash } from "react-icons/bs";
import { useAuthProvider } from "../../contexts/AuthProvider";
import DeleteModal from "../modal/DeleteModal";
import useTak from "../../api/tak";
import axios from "axios";
import { takkenUrl } from "../../helpers/apiUrls";

export default function MaandbriefPreview({taknaam, pdf, handleDownload, handleDelete}){

  const { currentUser } = useAuthProvider()
  const strippedName = pdf.replace(" ", "_").replace('.pdf', '')

  const takkenAPI = useTak()

  const handleClick = useCallback(async () => {
    console.log(pdf)
      const result = await axios.get(`${takkenUrl}/${taknaam}/maandbrieven/${pdf}`, {
        responseType: "blob"
      })
      const fileBlob = new Blob([result.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(fileBlob);
      console.log(fileURL)

      const a = document.createElement("a");
      a.href = fileURL;
      a.download = `${pdf}`;
      a.click();
  })

  const deleteMaandbrief = useCallback(() => {
    handleDelete(pdf)
  })

  const [ toonDeleteModal, setToonDeleteModal ] = useState(false);

  const handleHideModal = () => {
    setToonDeleteModal(false)
  }

  const handleShowModal = () => {
    setToonDeleteModal(true)
  }

  return <>
    <DeleteModal 
      naam={pdf}
      type="maandbrief"
      show={toonDeleteModal}
      onClose={handleHideModal}
      verwijderFunctie={deleteMaandbrief}
    />
    <li className="list-group-item">
      <div className='d-flex align-items-center justify-content-between'>
      <div className='d-flex align-items-center justify-content-center'>
            <BsFileEarmarkText />
            <p className='mb-0 ms-2'>{pdf}</p>
          </div>
          <div>
            { currentUser?.roles.includes("Leiding") && <BsTrash onClick={handleShowModal} className="me-2" color="red"/> }
          <BsDownload onClick={handleClick}/>
          </div>
          

      </div>
    </li>
  </>
}