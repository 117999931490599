export default function Lied10(){
  return <>
    <div>
      <h6>10. De meeste dromen zijn bedrog - Borsato</h6>
      <div className="mt-3 mb-4"></div>
      <p>Steeds als ik je zie lopen<br/>
dan gaat de hemel een klein beetje open.<br/>
Sterren, je laat ze verbleken<br/>
met je ogen die altijd stralen.<br/><br/>
Jij kan de zon laten schijnen<br/>
want je loopt langs en de wolken verdwijnen<br/>
en als je lacht, lacht heel de wereld mee.<br/><br/>
De meeste dromen zijn bedrog<br/>
maar als ik wakker word naast jou dan droom ik nog.<br/>
Ik voel je adem en zie je gezicht<br/>
je bent een droom die naast me ligt.<br/>
Je kijkt me aan en rekt je uit<br/>
een keer in de zoveel tijd komen dromen uit!<br/><br/>
Jij moet me een ding beloven<br/>
laat me nog lang in mijn dromen geloven.<br/>
Zelfs als je even niet hier bent<br/>
blijf in mijn slaap dan bij me.<br/><br/>
En als de zon weer gaat schijnen<br/>
laat dan dat beeld wat ik heb niet verdwijnen.<br/>
Als je zou gaan neem je mijn dromen mee.<br/><br/>
De meeste dromen zijn bedrog<br/>
maar als ik wakker wordt naast jou dan droom ik nog.<br/>
Ik voel je adem en zie je gezicht<br/>
je bent een droom die naast me ligt.<br/>
Je kijkt me aan en rekt je uit<br/>
een keer in de zoveel tijd komen dromen uit!<br/>
Ooh jij kan de zon laten schijnen<br/>
want je loopt langs en de wolken verdwijnen.<br/>
En als je lacht, lacht heel de wereld mee.<br/>
De meeste dromen zijn bedrog<br/>
maar als ik wakker word naast jou dan droom ik nog.<br/>
Ik voel je adem en zie je gezicht<br/>
je bent een droom die naast me ligt.<br/>
Je kijkt me aan en rekt je uit<br/>
zo een keer in de zoveel tijd komen dromen uit!<br/>
De meeste dromen zijn bedrog<br/>
maar als ik wakker word naast jou dan droom ik nog.<br/>
Ik voel je adem en zie je gezicht<br/>
je bent een droom die naast me ligt.<br/>
Je kijkt me aan en rekt je uit<br/>
zo een keer in de zoveel tijd komen dromen uit!</p>
    </div>
  </>
}