import { useCallback } from "react"

export default function Search({
  placeholder,
  search
}){

  const handleChange = useCallback((event) => {
    const searchTerm = event.target.value;
    search(searchTerm)
  })

  return <>
    <label className="ms-2 mb-1">Zoeken</label>
    <input 
      type="text" 
      placeholder={placeholder} 
      className="form-control" 
      onChange={handleChange}
    />
  </>
}