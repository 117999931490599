import { useCallback } from "react"
import { BsFolder } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function Folder({naam}){

  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(`${naam}`)
  });

  return <>
  <div className="col-12 col-md-3" onClick={handleClick}>
    <div className="card shadow bg-primary text-white border border-0 clickable folder-item">
      <div className="card-body d-flex justify-content-center align-content-center">
        <BsFolder color="white" className="me-2"/>
        <h5 className="text-center">{capitalizeFirstLetter(naam)}</h5>
      </div>
    </div>
  </div>
  </>
}