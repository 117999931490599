export default function Lied1(){
  return <>
    <div>
      <h6>21. Avondlied</h6>
      <div className="mt-3 mb-4"></div>
      <p>O Heer, d'avond is neergekomen,<br/>
de zonne zonk, het duister klom.<br/>
De winden doorruisen de bomen<br/>
en verre sterren staan alom...<br/>
Wij knielen neer om u te zingen<br/>
in 't slapend woud ons avondlied.<br/>
Wij danken u voor wat we ontvingen,<br/>
en vragen, Heer, verlaat ons niet!<br/>
Knielen, knielen, knielen wij neder,<br/>
door de stilte weerklinkt onze beê<br/>
Luist'rend fluist'ren kruinen mee<br/>
en sterren staren teder.<br/>
Geef ons Heer, zegen en rust en vreê.</p>
    </div>
  </>
}