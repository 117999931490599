import { useCallback, useEffect, useState } from "react";
import UserForm from "./UserForm";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../loader/Loader";
import useUser from "../../api/user";

export default function UserUpdate(){

  const [ selectedFile, setSelectedFile] = useState();
  const {userid} = useParams();
  const [ user, setUser ] = useState();
  const [ loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const userAPI = useUser();

  const handleFileSelect = useCallback(async (file) => {
    setSelectedFile(file)
  }, []);

  const save = useCallback(async ({voornaam, familienaam, email, gsmnummer, takid, rollen}) => {
    try{
      setLoading(true)
      console.log(takid)
      await userAPI.update(user.id, {
        voornaam,
        familienaam,
        email,
        gsmnummer,
        takid
      });
       
      if(rollen.length > 0){
        await userAPI.setRoles(user.id, rollen)
      }
      
      if (selectedFile) {
        let formData = new FormData();
        formData.append('image', selectedFile);
        await userAPI.uploadProfilePic({userid, formData})
      }
      setLoading(fals)
      navigate(`/gebruikers/` + userid, {replace: true})

    }catch(error){
       
    }
  }, [selectedFile, navigate, userid, setLoading])

  useEffect(()=> {
    const fetchUser = async () => {
      try {
        const response = await userAPI.getById(userid)
        console.log(response)        
        setUser(response);
        setLoading(false)
      } catch (error) {
         
      }
    }
    fetchUser()
  }, [loading, userid, setUser])

  return <>
    <div className="container mt-4">
      {loading ? <Loader />: <>
        <h1>Update info van {user.voornaam}</h1>
        <UserForm
        save={save}
        handleFileSelect={handleFileSelect}
        user={user}
      />
      </>
      }
    </div>
  </>

}