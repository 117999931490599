export default function Lied1(){
  return <>
    <div>
      <h5>7. Vlaanderen Boven - Raymond</h5>
      <div className="mt-3 mb-4"></div>
      <p>Waar er mossel met frit is<br/>
Waar er kip aan het spit is<br/>
Waar de kerk in 't midden staat<br/>
Waar de purperen hei bloeit<br/>
En het geld in het zwart vloeit<br/>
Waar men nauwelijks Nederlands praat<br/>
Waar een diploma geen zin heeft<br/>
En de koning geen kind heeft<br/>
Waar de schuimwijnkoningin defileert<br/>
Waar het volk goedlachs is<br/>
En een vuist zonder kracht is<br/>
Waar men faalt en aan de toog explikeert<br/><br/>
<strong>Vlaanderen boven<br/>
Waar men de Heer nog kan loven<br/>
Waar de mensen belangrijk zijn<br/>
En de buiken omvangrijk zijn<br/>
Vlaanderen buiten<br/>
Waar de vogeltjes fluiten<br/>
Vlaanderen mijn land<br/>
Bij het Noordzeestrand<br/><br/></strong>
Waar de kleurenbuis grijs is<br/>
En een schroef nog een vijs is<br/>
Waar de rijkswacht goed functioneert<br/>
Waar een pik een houweel is<br/>
En geen pintje te veel is<br/>
Francis Bay 't orkest dirigeert<br/>
Waar de g soms een h is<br/>
AVV VVK is<br/>
Waar men ernst zodanig prijst<br/>
Waar de chiro paraat staat<br/>
De vrouw aan de vaat staat<br/>
De camera traag gaat<br/>
De premier nogal vaag praat<br/>
De vakantie naar Spanje wijst<br/><br/>
<strong>Vlaanderen boven<br/>
Waar men de Heer nog kan loven<br/>
Waar de mensen belangrijk zijn<br/>
En de buiken omvangrijk zijn<br/>
Vlaanderen buiten<br/>
Waar de vogeltjes fluiten<br/>
Vlaanderen mijn land<br/>
Bij het Noordzeestrand<br/><br/></strong>
De Kemmelberg het Gravensteen<br/>
De Koekelbergbasiliek (ja die van Koekelberg)<br/>
De waterzooi<br/>
Het meisje in 't hooi<br/>
De Vlaamse romantiek<br/><br/>
<strong>Vlaanderen boven<br/>
Waar men een peer nog kan stoven<br/>
Waar de mensen belangrijk zijn<br/>
En de pensen omvangrijk zijn<br/>
Vlaanderen buiten<br/>
Waar ik de Heer nog zag spuiten<br/>
Vlaanderen mijn land<br/>
Bij het Noordzeestrand<br/></strong></p>
    </div>
  </>
}