import banner from "./images/verzekering.jpg";

export default function Verzekering(){

  return (
    <div className="container mt-0 mb-4">
      <img src={banner} className="info-banner shadow" />

      <h1>Verzekering</h1>
      <h2>Wie is verzekerd?</h2>
      <p>
        Elke persoon die het lidgeld van Scouts & Gidsen Vlaanderen betaalde voor het werkjaar en op de ledenlijst van zijn/haar groep staat, is verzekerd. De minimumleeftijd: 6 jaar (kapoenen) worden voor 1 januari van het lopende werkjaar of in het 1e leerjaar zitten. Foeriers en medewerkers die voor een specifieke periode verzekeringsgeld betaalden.
      </p>

      <h2>Wanneer ben je verzekerd?</h2>
      <ul className="icon-list">
        <li>Tijdens alle toegelaten scouts- en gidsenactiviteiten die gebeuren in aanwezigheid of in opdracht van de leiding.</li>
        <li>Op weg van en naar het lokaal of de plaats van bijeenkomst (normale reisroute).</li>
        <li>Zowel bij een verblijf in België als in het buitenland.</li>
      </ul>

      <h2>Wat is verzekerd?</h2>
        <p><u>Lichamelijke letsels:</u><br></br>Alle lichamelijke letsels aan leden en leiding als gevolg van een ongeval, tijdens en op weg van en naar een VVKSM-activiteit in binnen- of buitenland.</p>
        <p><u>Burgerlijke aansprakelijkheid (schade aan derden) en rechtsbijstand:</u></p>
        <ul className="nested-icon-list">
          <li>Van Scouts & Gidsen tegenover derden</li>
          <li>Van Scouts & Gidsen en leiding tegenover derden</li>
          <li>Van Scouts & Gidsen en leden tegenover derden</li>
          <li>Van leden tegenover andere leden</li>
        </ul>
        <p><u>Schade aan lokalen en groepsmateriaal:</u></p>
        <ul className="nested-icon-list">
          <li>Lokalen (zowel in eigendom als in huur) en groepsmateriaal (ook bij transporten en verplaatsing), voor onderstaande risico’s brand (dit omvat o.m. brand, bliksem, ontploffingen, elektriciteitsschade, arbeidsconflicten en aanslagen, rook- en roetschade,...)</li>
          <li>Storm –en hagelschade (eveneens voor de opgestelde tenten), waterschade, glasverzekering, schade t.g.v. diefstal na inbraak (vanaf € 2.478,93)</li>
        </ul>
        <p><u>Rechtsbijstand:</u><br></br>Waarborg voor kosten gemaakt om schadevergoeding te bekomen voor schade veroorzaakt door derden, niet-leden van Scouts & Gidsen Vlaanderen. Waarborg voor kosten gemaakt voor strafrechtelijke verdediging (advocaat), enkel indien reeds een verzekeringsdossier werd geopend.</p>
      
      <p>Meer info kan u altijd vinden op de site van Scouts & Gidsen Vlaanderen (<a href="https://www.scoutsengidsenvlaanderen.be/publicaties/goed-verzekerd">link</a>).</p>

    </div>
  );
};
