import "./Gazette.css";
import voorpagina from "./voorpagina.png";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Loader from "../loader/Loader";
import { gazetteUrl } from "../../helpers/apiUrls";
import useGazette from "../../api/gazette";
import GazettePreview from "./GazettePreview";

export default function Gazette(){

  const [ pdfUrl, setPdfUrl ] = useState();
  const [ loading, setLoading ] = useState(false)
  const [ gazetten, setGazetten ] = useState([])

  const gazetteAPI = useGazette();

  useEffect(()=> {
    const fetchGazetten = async () => {
      let result = await gazetteAPI.getAll();
      
      setGazetten(result.map(gazette => { return gazette.split(".")[0]}).sort().reverse());
    }

    fetchGazetten();
  }, [])

  return <>
    <div className="container py-4">
      <h2 className="title">Gazette</h2>
      <p className="description">Met onze Scouts Gazette willen we jullie meer meenemen in ons scoutsverhaal. Je vindt er leuke verhalen van zowel leiding als leden, tussendoortjes, nieuwe weetjes en alle andere info die je maar kan bedenken. De gazetten zullen op regelmatige basis op de website verschijnen. We bieden hem ook aan op papier en dan betaal je €2 voor een gedrukte versie. Voor meer info stuur naar <a href="mailto:fleur@scoutswondelgem.be">fleur@scoutswondelgem.be</a>.</p>
      <hr></hr>
      <h4>2023 - 2024</h4>
      <div className="row px-4">
        {gazetten.map(gazette => {
          return <>
            <div className="col-12 col-md-6 col-lg-2 p-2">
              <GazettePreview id={gazette} height={250}/>
            </div>
          </>
        })}
      </div>
      {loading && <Loader />}
    </div>
  </>
}